@use '../../abstracts/colors' as colors;
@use '../../abstracts/fonts' as fonts;
@use '@angular/material' as mat;

@mixin button-theme($theme) {
    $primary: mat.get-color-from-palette($theme, primary);
    $a40: mat.get-color-from-palette($primary, a40);
    $a100: mat.get-color-from-palette($primary, a100);
    $a200: mat.get-color-from-palette($primary, a200);
    $a400: mat.get-color-from-palette($primary, a400);
    $a700: mat.get-color-from-palette($primary, a700);
    $buttonDefault: mat.get-color-from-palette($primary, buttonDefault);
    $buttonDefaultText: mat.get-color-from-palette($primary, buttonDefaultText);
    $buttonHover: mat.get-color-from-palette($primary, buttonHover);
    $buttonBorder: mat.get-color-from-palette($primary, buttonBorder);
    $buttonAccentText: mat.get-color-from-palette($primary, buttonAccentText);
    $buttonAccentHover: mat.get-color-from-palette($primary, buttonAccentHover);
    $buttonDisabled: mat.get-color-from-palette($primary, buttonDisabled);
    $buttonDisabledText: mat.get-color-from-palette($primary, buttonDisabledText);
    $buttonIcon: mat.get-color-from-palette($primary, buttonIcon);

    button.mat-mdc-raised-button,
    button.mat-mdc-button {
        font-family: fonts.$font-medium;
        border-radius: 98px;
        padding: 0 20px;
        letter-spacing: normal;
    }

    button.mat-mdc-raised-button,
    .mat-toolbar-row .mat-mdc-button {
        box-shadow: none;

        &:hover {
            box-shadow: none;
        }

        &:not(:disabled, .mat-accent, .mat-warn, .delete-btn) {
            color: $buttonDefaultText;
        }

        &:not(:disabled, .mat-accent, .mat-warn) {
            background: $buttonDefault;
            border: 1px solid $buttonBorder;

            &:hover {
                background: $buttonHover;
            }

            &:focus {
                box-shadow: 0 0 0 2px colors.$white_1, 0 0 0 4px $a40;
            }
        }

        &.mat-accent {
            background: radial-gradient(100% 100% at 50.31% 0%, $a100 0%, $a200 100%); //This is only a gradient because of Unzer and Lumi-pay. The color values are identical on other themes, resulting in a solid color
            color: $buttonAccentText;

            &:hover {
                background: radial-gradient(100% 100% at 50.31% 0%, $buttonAccentHover 0%, $a400 100%);
            }
        }

        &:disabled {
            background: $buttonDisabled;
            color: $buttonDisabledText;
            cursor: auto;
        }

        &.delete-btn {
            color: colors.$red_2;
        }

        &.margin-top {
            margin-top: 8px;
        }
    }

    button.mat-mdc-icon-button {
        color: $buttonIcon;
        border-radius: 6px;

        &:hover {
            background: $buttonHover;
        }

        &:focus {
            box-shadow: 0 0 0 4px colors.$white_1;
        }

        &.small-icon-btn {
            width: 24px;
            height: 24px;
            padding: 0;
            display: inline-flex;
            align-items: center;
            justify-content: center;

            &>*[role="img"] {
                width: 20px;
                height: 20px;
                font-size: 20px;
            }
        }
    }
}