@use '../abstracts/fonts' as fonts;
@use '../abstracts/colors' as colors;

// Global container that should just fill the parent container.
html,
body {
    height: 100%;
}

// Remove default global margins
body {
    margin: 0;
}

.ng-hide.ng-hide-animate {
    display: none !important;
}

p {
    font-family: fonts.$font-regular;
    font-size: 16px;
    line-height: 24px;
    margin-block-start: 0;

    &.info-text {
        color: colors.$grey_2;
        font-size: 14px;
    }
}

.cursor {
    cursor: pointer;
}

.header-login-layout {
    padding-top: 50px !important;
}

.text-wrap {
    white-space: pre-wrap;
}