//- fxFlex -//
.flex-1 {
    flex: 1; // Same as flex: 1 1 0 (grow, shrink, basis 0)
}

@for $i from 4 through 99 {
    .flex-#{$i} {
        box-sizing: border-box;
        flex: 1 1 100%;
        max-width: #{$i}#{"%"};
    }
}

.flex-100 {
    box-sizing: border-box;
    flex: 1 1 100%;
}

.flex-h2 {
    box-sizing: border-box;
    flex: 1 1 100%;
    max-height: 2%;
}

// Fix for using fxFlex="100" fxFlex.gt.sm="35" etc.
@media screen and (width <= 959px) {
    @for $i from 25 through 80 {
        .flex-#{$i}-mobile-100 {
            box-sizing: border-box;
            flex: 1 1 100%;
        }
    }
}

@media screen and (width >= 960px) {
    @for $i from 25 through 80 {
        .flex-#{$i}-mobile-100 {
            box-sizing: border-box;
            flex: 1 1 100%;
            max-width: #{$i}#{"%"};
        }
    }
}

// Fix for only using Flex classes on certain screen sizes
@media screen and (width >= 600px) {
    .flex-desktop-30 {
        box-sizing: border-box;
        flex: 1 1 100%;
        max-width: 30%;
    }
}

// Fix for fxFlex="60" fxFlex.xs="90" etc.
@media screen and (width <= 959px) {
    .flex-60-mobile-90,
    .flex-95-mobile-90 {
        box-sizing: border-box;
        flex: 1 1 100%;
        max-width: 90%;
    }

    .flex-30-mobile-50 {
        box-sizing: border-box;
        flex: 1 1 100%;
        max-width: 50%;
    }

    .flex-100-mobile-70 {
        box-sizing: border-box;
        flex: 1 1 100%;
        max-width: 70%;
    }
}

@media screen and (width >= 960px) {
    .flex-60-mobile-90 {
        box-sizing: border-box;
        flex: 1 1 100%;
        max-width: 60%;
    }

    .flex-95-mobile-90 {
        box-sizing: border-box;
        flex: 1 1 100%;
        max-width: 95%;
    }

    .flex-30-mobile-50 {
        box-sizing: border-box;
        flex: 1 1 100%;
        max-width: 30%;
    }

    .flex-100-mobile-70 {
        box-sizing: border-box;
        flex: 1 1 100%;
        max-width: 100%;
    }
}

// Fix for responsive layout on 3+ screen sizes (Only used on sign-up flow)
//
@media screen and (width <= 959px) {
    .flex-90-60-50 {
        box-sizing: border-box;
        flex: 1 1 100%;
        max-width: 90%;
    }

    .flex-70-60-60-50 {
        box-sizing: border-box;
        flex: 1 1 100%;
        max-width: 70%;
    }

    .flex-90-70 {
        box-sizing: border-box;
        flex: 1 1 100%;
        max-width: 90%;
    }
}

@media screen and (width >= 960px) and (width <= 1279px) {
    .flex-90-60-50 {
        box-sizing: border-box;
        flex: 1 1 100%;
        max-width: 60%;
    }

    .flex-90-70 {
        box-sizing: border-box;
        flex: 1 1 100%;
        max-width: 70%;
    }
}

@media screen and (width >= 960px) and (width <= 1919px) {
    .flex-70-60-60-50 {
        box-sizing: border-box;
        flex: 1 1 100%;
        max-width: 60%;
    }
}

@media screen and (width >= 1280px) {
    .flex-90-60-50 {
        box-sizing: border-box;
        flex: 1 1 100%;
        max-width: 50%;
    }
}

@media screen and (width >= 1919px) {
    .flex-70-60-60-50 {
        box-sizing: border-box;
        flex: 1 1 100%;
        max-width: 50%;
    }
}

// Fix for fxFlex.lt-md fxFlex.gt-sm="632px"
@media screen and (width <= 959px) {
    .flex-632px-mobile-100 {
        flex: 1 1 100%;
    }
}

@media screen and (width >= 960px) {
    .flex-632px-mobile-100 {
        flex: 1 1 100%;
        max-width: 632px;
    }
}