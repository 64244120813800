@use '../../abstracts/colors' as colors;
@use '../../abstracts/fonts' as fonts;
@use '@angular/material' as mat;

@mixin layout-theme($theme) {
    $primary: mat.get-color-from-palette($theme, primary);
    $a200: mat.get-color-from-palette($primary, a200);
    $appBackground: mat.get-color-from-palette($primary, appBackground);
    $toolbarHeader: mat.get-color-from-palette($primary, toolbarHeader);
    $toolbarTable: mat.get-color-from-palette($primary, toolbarTable);
    $acquirerActivated: mat.get-color-from-palette($primary, acquirerActivated);
    $linkText: mat.get-color-from-palette($primary, linkText);
    $searchField: mat.get-color-from-palette($primary, searchField);
    $inputBorderFocus: mat.get-color-from-palette($primary, inputBorderFocus);

    .mat-app-background {
        background-color: $appBackground;
    }

    /* --- Header --- */
    .header.mat-toolbar,
    .header-logo.mat-toolbar {
        background-color: $toolbarHeader;
    }

    /* --- Transaction Tables --- */

    /* --- Payments, Payouts, Saved Cards, Subscriptions --- */
    .mat-toolbar-row,
    .mat-toolbar.top-toolbar {
        background-color: $toolbarTable;
    }

    /* --- Acquirer List --- */
    .mdc-list-item.acquirer-success {
        background-color: $acquirerActivated;
    }

    /* --- Links --- */
    a,
    .link,
    .no-results-link {
        color: $linkText;
    }

    /* --- Input --- */
    .search-field-mat,
    .search-field-large {
        .search-widget-mat,
        .search-widget {
            input {
                background: $searchField;

                &:focus,
                &:focus-visible {
                    border-color: $inputBorderFocus !important;
                    outline: none;
                }
            }

            .search-options {
                border-width: 1px 0 1px 1px;
                border-style: solid;
                border-color: $inputBorderFocus;

                .search-option {
                    border-right: 1px solid $inputBorderFocus;
                    background-color: $searchField;

                    &.active {
                        color: $inputBorderFocus !important;
                    }
                }
            }
        }
    }


    /* ---Statistics--- */
    // For some reason, tabs on the Statistics page are styled differently to tabs in transaction table pages. Look into making this consistent when time permits

    .mat-mdc-tab-group {
        //There's a weird effect where Material applies a different color on the tab until you click away. Rewrite the below if a cleaner way of overwriting is found
        --mdc-tab-indicator-active-indicator-color: #{$a200};
        --mat-tab-header-active-label-text-color: #{$a200};
        --mat-tab-header-active-ripple-color: #{$a200};
        --mat-tab-header-active-focus-label-text-color: #{$a200};
        --mat-tab-header-active-hover-label-text-color: #{$a200};
        --mat-tab-header-active-focus-indicator-color: #{$a200};
        --mat-tab-header-active-hover-indicator-color: #{$a200};
    }
}