@use '../abstracts/fonts' as fonts;
@use '../abstracts/colors' as colors;
@use '../abstracts/text-mixins' as text;

a {
    text-decoration: none;

    &:hover {
        text-decoration: underline;
    }
}

.link {
    text-decoration: underline;
    cursor: pointer;
}

.no-results-link {
    cursor: pointer;
}