@use '../abstracts/colors' as colors;

@media print {
    * {
        overflow: visible !important;
    }

    @page {
        margin: 0;
        margin-top: 25px;
        margin-bottom: 25px;
    }

    body {
        width: 100% !important;
        margin: 0 !important;
        padding: 0 !important;
        line-height: 1.45;
        color: colors.$black_1;
        background: none;
        font-size: 12pt;
        padding-bottom: 50px;
    }

    mat-toolbar,
    .mat-fab,
    #main-topmenu,
    mat-sidenav,
    loading-spinner {
        display: none !important;
    }

    .page-container[_ngcontent-c0] {
        top: 0 !important;
    }

    mat-sidenav-content {
        margin-left: 0 !important;
    }

    mat-card-content,
    .mat-drawer-content,
    .mat-table {
        overflow: visible !important;
    }

    .main-content-layout {
        width: 100%;
        margin: 0 !important;
    }

    .no-print {
        display: none !important;
    }

    .print {
        display: block !important;
    }

    mat-card[class^='card02'],
    mat-card[class*=' card02'] {
        box-shadow: none !important;
    }

    mat-card {
        margin: 0 !important;
    }

    // --- Headings ---
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        page-break-after: avoid;
    }

    h1 {
        font-size: 19pt;
    }

    h2 {
        font-size: 17pt;
    }

    h3 {
        font-size: 15pt;
    }

    h4,
    h5,
    h6 {
        font-size: 14pt;
    }

    p,
    h2,
    h3 {
        orphans: 3;
        widows: 3;
    }

    code {
        font: 12pt Courier, monospace;
    }

    blockquote {
        margin: 1.2em;
        padding: 1em;
        font-size: 12pt;
    }

    hr {
        background-color: colors.$grey_5;
    }

    // --- Images ---
    img {
        float: left;
        margin: 1em 1.5em 1.5em 0;
        max-width: 100% !important;
        page-break-inside: avoid;
    }

    a img {
        border: none;
    }

    // --- Links ---
    a:link,
    a:visited {
        background: transparent;
        font-weight: 700;
        text-decoration: underline;
        color: colors.$grey_6;
    }

    a:link[href^="http://"]::after,
    a[href^="http://"]:visited::after {
        content: ' (' attr(href) ') ';
        font-size: 90%;
    }

    abbr[title]::after {
        content: ' (' attr(title) ')';
    }

    // Don't show linked images
    a[href^="http://"] {
        color: colors.$black_1;
    }

    a[href$='.jpg']::after,
    a[href$='.jpeg']::after,
    a[href$='.gif']::after,
    a[href$='.png']::after {
        content: ' (' attr(href) ') ';
        display: none;
    }

    // Don't show links that are fragment identifiers, or use the `javascript:` pseudo protocol .. taken from html5boilerplate
    a[href^='#']::after,
    a[href^='javascript:']::after {
        content: '';
    }

    .avoid-page-break {
        page-break-inside: avoid;
    }

    // --- Table ---
    table {
        //page-break-inside: avoid; //Causes cut-off row bug
        margin: 1px;
        margin-bottom: 15px;
        margin-top: 10px;
        text-align: left;

        th {
            border-bottom: 1px solid colors.$grey_6;
            ;
            font-weight: bold;
            padding: 4px 10px 4px 0 !important;

            &.md-checkbox-column {
                display: none !important;
            }
        }

        td {
            border-bottom: 1px solid colors.$grey_6;
            ;
            padding: 4px 10px 4px 0 !important;

            &.md-checkbox-cell {
                display: none !important;
            }
        }

        tr {
            page-break-before: auto;
        }

        caption {
            background: colors.$white_1;
            margin-bottom: 2em;
            text-align: left;
        }

        thead {
            display: table-row-group;
        }

        tfoot {
            font-style: italic;
            display: table-footer-group;
        }
    }
}