@use '../abstracts/fonts' as fonts;
@use '../abstracts/text-mixins' as text;

h1 {
    @include text.text(60px, 72px);
}

h2 {
    @include text.text(48px, 60px);
}

h3 {
    @include text.text(34px, 40px);
}

h4 {
    @include text.text(24px, 28px);
}

h5 {
    @include text.text(20px, 24px);
}

.subtitle1 {
    @include text.text(16px, 24px, fonts.$font-semibold);

    font-weight: normal !important;
}

.subtitle2 {
    @include text.text(14px, 20px, fonts.$font-semibold);
}

.subtitle3 {
    @include text.text(16px, 24px, fonts.$font-semibold);
}