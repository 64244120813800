@use '../abstracts/colors' as colors;

/* Scrollbar */
::-webkit-scrollbar {
    width: 10px;
}

::-webkit-scrollbar-thumb {
    border-radius: 2px;
    background-color: colors.$grey_5;
    box-shadow: inset 0 0 1px rgb(0 0 0 / 50%);
}