@use '@angular/material' as mat;

@mixin setting-billing-plans-theme($theme) {
    $accent: mat.get-color-from-palette($theme, accent);

    plan-button[disabled] {
        background-color: mat.get-color-from-palette($accent, darker) !important;
        color: rgb(0 0 0 / 38%) !important;

        span {
            color: rgb(0 0 0 / 38%) !important;
        }
    }
}