@use '../../abstracts/fonts' as fonts;
@use '../../abstracts/colors' as colors;

.mat-accordion.accordion {
    mat-expansion-panel {
        display: unset;
    }

    .mat-expansion-panel-header {
        padding: 0;
        flex-direction: row-reverse;
        font-size: 16px;

        .mat-content {
            padding-left: 36px;
        }

        &.mat-expanded {
            height: 48px;
        }
    }

    .mat-expansion-panel-content .mat-expansion-panel-body {
        padding: 0 0 24px;
    }
}

.mat-expansion-panel-header-title {
    font-family: fonts.$font-regular;
}

.mat-expansion-panel:not([class*="mat-elevation-z"]) {
    box-shadow: none !important;
}