@use '../../abstracts/colors' as colors;

.mat-mdc-dialog-container {
    //modal
    box-shadow: 0 1.6px 3.6px rgb(0 0 0 / 13.2%), 0 0.3px 0.9px rgb(0 0 0 / 10.8%) !important;

    .mdc-dialog__content {
        --mdc-dialog-supporting-text-color: $black_1;
    }
}

.cdk-overlay-backdrop {
    //modal-overlay
    background: rgb(20 20 20 / 50%) !important;
}

.no-padding .mat-mdc-dialog-container {
    padding: 0 !important;
}