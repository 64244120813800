@use '@angular/material' as mat;

@mixin onboarding-theme($theme) {
    $accent: mat.get-color-from-palette($theme, accent);

    .steps-qp {
        height: 2px;

        &.current {
            fill: mat.get-color-from-palette($accent);
            stroke: mat.get-color-from-palette($accent);
        }

        &.inactive {
            fill: #fff;
        }

        &.finished {
            fill: #fff;
            stroke: mat.get-color-from-palette($accent);
        }
    }

    .progress-check {
        position: relative;
        top: -78px;
        left: 48px;
        font-family: 'Material Icons';

        &::before {
            display: block;
            content: 'check';
            color: mat.get-color-from-palette($accent);
        }
    }
}