@use '@angular/material' as mat;
@use '../../abstracts/fonts' as fonts;
@use '../../abstracts/text-mixins' as text;
@use '../../theme/palettes/default' as palette;

.mat-slide-toggle-content {
    @include text.text(16px, 24px, fonts.$font-regular);
}

.mat-mdc-slide-toggle.mat-mdc-slide-toggle-checked:not(.mat-disabled) .mdc-switch__shadow {
    background-color: mat.get-color-from-palette(palette.$manager-palette, a100);

    /* replace with your color */
}

.mat-mdc-slide-toggle.mat-mdc-slide-toggle-checked:not(.mat-disabled) .mdc-switch__track::after {
    background-color: rgba(mat.get-color-from-palette(palette.$manager-palette, a100), 0.54);

    /* replace with your color */
}