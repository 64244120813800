.size-150 {
    max-width: 150px;
    max-height: 150px;
}

.size-90 {
    max-width: 90px;
}

.size-50 {
    max-height: 50px;
    max-width: 150px;
}

.acquirer-logo {
    max-width: 150px;
    max-height: 30px;
}

.acquirer-logo-small {
    max-height: 30px;
    max-width: 50px;
}

.header-logo img {
    height: 47px;
}
