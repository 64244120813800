// - fxLayoutAlign - //

.space-between {
    justify-content: space-between;
}

.space-around {
    justify-content: space-around;
}

.justify-center {
    justify-content: center;
}

.justify-start {
    justify-content: flex-start;
}

.justify-end {
    justify-content: flex-end;
}

.stretch {
    align-content: stretch;
}

.items-start {
    align-items: start;
}

.items-center {
    align-items: center;
}

.items-end {
    align-items: end;
}

.self-center {
    align-self: center;
}

@for $i from -10 through 10 {
    .order-#{$i} {
        order: $i;
    }
}

@media screen and (width <= 959px) {
    .align-responsive-1 {
        justify-content: center;
        align-items: center;
    }

    .align-responsive-2 {
        justify-content: center;
    }
}

@media screen and (width >= 960px) {
    .align-responsive-1 {
        justify-content: flex-start;
        align-items: center;
    }
}
