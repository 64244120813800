.width-600px mat-card {
    width: 100%;
}

.dashboard-card {
    @media screen and (width <= 1279px) {
        width: 95vw !important;
    }

    @media screen and (width >= 1280px) {
        width: calc(95vw - 220px);
    }
}

.dashboard-card-image {
    max-height: 300px;
    margin: -16px -16px -16px 0;
    border-radius: 0 6px 6px 0;
}

.dashboard-card-logo {
    max-height: 35px;
}

.card02 {
    box-shadow: 0 1.6px 3.6px rgb(0 0 0 / 13.2%), 0 0.3px 0.9px rgb(0 0 0 / 10.8%) !important;
}