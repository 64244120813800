@use '../../abstracts/fonts' as fonts;

.mat-mdc-checkbox {
    font-family: fonts.$font-regular;

    .mdc-checkbox {
        padding-top: 0;
        padding-bottom: 0;

        .mdc-checkbox__background {
            top: 0;
        }

        .mat-mdc-checkbox-touch-target {
            height: 24px;
            width: 24px;
        }

        .mdc-checkbox__native-control {
            height: 19px;
        }

        .mdc-checkbox__ripple,
        .mat-mdc-checkbox-ripple {
            top: -4px;
            height: 28px;
            width: 28px;
            left: 6px;
        }
    }
}

.qp-small .mat-mdc-checkbox-layout div {
    transform: scale(0.8);
}

.multiple-line-align .mat-mdc-checkbox-inner-container {
    margin: 4px 8px auto 0;
}