$spacer: 8px;
$values-array: 0, 1, 2, 3, 4, 5, 6, 7, 8;

@mixin make-space($prefix) {
    @each $value in $values-array {
        .#{$prefix}p-#{$value} {
            padding: $spacer * $value !important;
        }

        .#{$prefix}pt-#{$value} {
            padding-top: $spacer * $value !important;
        }

        .#{$prefix}pl-#{$value} {
            padding-left: $spacer * $value !important;
        }

        .#{$prefix}pr-#{$value} {
            padding-right: $spacer * $value !important;
        }

        .#{$prefix}pb-#{$value} {
            padding-bottom: $spacer * $value !important;
        }

        .#{$prefix}px-#{$value} {
            padding-left: $spacer * $value !important;
            padding-right: $spacer * $value !important;
        }

        .#{$prefix}py-#{$value} {
            padding-top: $spacer * $value !important;
            padding-bottom: $spacer * $value !important;
        }

        .#{$prefix}m-#{$value} {
            margin: $spacer * $value !important;
        }

        .#{$prefix}mt-#{$value} {
            margin-top: $spacer * $value !important;
        }

        .#{$prefix}ml-#{$value} {
            margin-left: $spacer * $value !important;
        }

        .#{$prefix}mr-#{$value} {
            margin-right: $spacer * $value !important;
        }

        .#{$prefix}mb-#{$value} {
            margin-bottom: $spacer * $value !important;
        }

        .#{$prefix}mx-#{$value} {
            margin-left: $spacer * $value !important;
            margin-right: $spacer * $value !important;
        }

        .#{$prefix}my-#{$value} {
            margin-top: $spacer * $value !important;
            margin-bottom: $spacer * $value !important;
        }
    }
}