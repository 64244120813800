@use '@angular/material' as mat;
@use './app-components-theme' as app-components;
@use './mat-components-theme' as components;
@use './typography/default' as typography;
@use './typography/components' as component-typography;
@use '../abstracts/colors' as colors;
@use './palettes/default' as default-palettes;
@use './palettes/dark-theme' as dark-theme-palettes;

// Must only be loaded once!
// TODO(v15): As of v15 mat.legacy-core no longer includes default typography styles.
//  The following line adds:
//    1. Default typography styles for all components
//    2. Styles for typography hierarchy classes (e.g. .mat-headline-1)
//  If you specify typography styles for the components you use elsewhere, you should delete this line.
//  If you don't need the default component typographies but still want the hierarchy styles,
//  you can delete this line and instead use:
//    `@include mat.legacy-typography-hierarchy($custom-typography);`
// @include mat.all-legacy-component-typographies($custom-typography);
@include mat.core;

$manager-light-theme: mat.define-light-theme((color: (primary: default-palettes.$manager-primary,
                accent: default-palettes.$manager-accent,
                warn: default-palettes.$manager-warn ),
            typography: typography.$custom-typography,
            density: 0));
$manager-dark-theme: mat.define-dark-theme((color: (primary: dark-theme-palettes.$manager-primary,
                accent: dark-theme-palettes.$manager-accent,
                warn: dark-theme-palettes.$manager-warn ),
            typography: typography.$custom-typography,
            density: 0));

@include components.mat-components($manager-light-theme);
@include component-typography.mat-components-typography;
@include mat.typography-hierarchy(typography.$custom-typography);
@include app-components.apply-theme($manager-light-theme);

.dark-theme {
    @include components.mat-components-color($manager-dark-theme);
    @include app-components.apply-theme($manager-dark-theme);
}