// - fxHide, fxShow - //
.hide-lt-sm {
    @media screen and (width <= 599px) {
        display: none !important;
    }
}

.hide-lt-md {
    @media screen and (width <= 959px) {
        display: none !important;
    }
}

.hide-lt-lg {
    @media screen and (width <= 1279px) {
        display: none !important;
    }
}

.hide-lt-xl {
    @media screen and (width <= 1919px) {
        display: none !important;
    }
}

.hide-gt-xs {
    @media screen and (width >= 600px) {
        display: none !important;
    }
}

.hide-gt-sm {
    @media screen and (width >= 960px) {
        display: none !important;
    }
}

.hide-gt-md {
    @media screen and (width >= 1280px) {
        display: none !important;
    }
}

.hide-gt-lg {
    @media screen and (width >= 1920px) {
        display: none !important;
    }
}
