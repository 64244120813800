// --- Replacements for FlexLayout --- //
// - fxLayout="row", fxLayout="column" - //
.flex-row {
    display: flex !important;
    flex-direction: row !important;
    box-sizing: border-box;
}

.flex-col {
    display: flex !important;
    flex-direction: column !important;
    box-sizing: border-box;
}

// flex wrap
.flex-wrap {
    flex-wrap: wrap !important;
}

// fix for using fxLayout="column" fxLayout.gt.sm, fxLayout fxLayout.xs="column"
@media screen and (width <= 959px) {
    .flex-row-mobile-col {
        flex-direction: column !important;
        display: flex !important;
        box-sizing: border-box;
    }
}

@media screen and (width >= 960px) {
    .flex-row-mobile-col {
        flex-direction: row !important;
        display: flex !important;
        box-sizing: border-box;
    }
}
