@use '../../abstracts/fonts' as fonts;
@use '../../abstracts/colors' as colors;

table {
    .mat-cell,
    .mat-footer-cell {
        font-size: 14px;
    }
}

.mat-mdc-table {
    font-family: fonts.$font-sf-mono-regular;

    .mat-mdc-row {
        &:hover {
            background-color: rgba(colors.$grey_7, 0.2) !important;
            cursor: pointer;
        }

        &[qpsref]:hover:not([disabled]),
        &qpsref:hover:not([disabled]) {
            background-color: colors.$white_2 !important;
            cursor: pointer;
        }

        &.no-pointer {
            &:hover {
                cursor: default;
            }
        }
    }
}

.mat-mdc-sort-header-content,
.mat-mdc-paginator {
    font-family: fonts.$font-regular;
    font-size: 12px;
    line-height: 16px;
}

.mat-mdc-paginator-container:last-child {
    margin-bottom: -16px !important;
}

// This removes the Material Design range label in all table paginators
.mat-mdc-paginator-range-label {
    display: none;
}

// This removes the Material Design range label last button in all table paginators with removeLastButton class set
.removeLastButton .mat-mdc-paginator-navigation-last {
    display: none;
}

.mat-mdc-card-image {
    display: table !important;
}

.align-right .mat-sort-header-container {
    justify-content: flex-end;
    text-align: right;
}

.mdc-data-table__header-cell {
    color: colors.$grey_11;
}