@use '../abstracts/colors' as colors;

.text-grey {
    color: colors.$grey_2;
}

.text-grey-2 {
    color: colors.$grey_1;
}

.text-white {
    color: colors.$white_1;
}

.text-grey-icon {
    color: colors.$grey_1;
}

.text-blue {
    color: colors.$blue_1;
}

.text-light-blue {
    color: colors.$blue_2;
}

.text-darkred {
    color: colors.$red_2;
}

.text-green,
.success {
    color: colors.$green_1;
}

.text-yellow {
    color: colors.$yellow_1;
}

.text-black {
    color: colors.$black_1;
}

.text-red {
    color: colors.$red_1;
}

.bg-white {
    background-color: colors.$white_1;
}