@use '../../abstracts/fonts' as fonts;

mat-mdc-list {
    &.no-p {
        .mat-mdc-list-item-content {
            padding: 0 !important;
        }
    }

    &.list1 {
        .mat-mdc-list-item-content {
            padding-top: 8px !important;
        }
    }
}

.mat-mdc-list-item.list1 {
    padding-top: 8px !important;
}

.mat-mdc-nav-list .mat-mdc-list-item {
    font-family: fonts.$font-regular;
}

.mat-mdc-nav-list .mat-mdc-list-item {
    font-size: 16px;
}

.icon-list mat-mdc-list-item img {
    max-width: 50px;
}

.mat-mdc-list-item-content {
    width: 100%;
}

.no-padding .mat-mdc-list-item-content {
    padding: 0 !important;
}

.no-left-padding .mat-mdc-list-item-content {
    padding-left: 0 !important;
}