@use '../../abstracts/colors' as colors;
@use '../../abstracts/fonts' as fonts;
@use '../../abstracts/toolbar' as toolbar;

mat-toolbar {
    height: toolbar.$toolbar-height;
}

.header.mat-toolbar {
    z-index: 2;
    position: absolute;
}

.mat-mdc-toolbar {
    font-family: fonts.$font-regular;

    .mat-mdc-card-title {
        position: absolute;
        left: 26px;
    }
}

.alignment-fix {
    margin-top: -10px; // Fixes alignment issue on icons caused by inserted components adding extra margin. Remove if a better solution is found
}

@media screen and (width <= 600px) {
    mat-toolbar {
        height: toolbar.$toolbar-height-s;
    }
}
