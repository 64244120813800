@use '../../abstracts/fonts' as fonts;
@use '../../abstracts/text-mixins' as text;

.mat-mdc-select-arrow {
    color: #0000008a !important;
}

.mat-mdc-select-trigger {
    padding-right: 8px !important;
}

// Class that can be applied on mat-select using the [panelClass] input.
.wide-select {
    .mat-mdc-option .mdc-list-item__primary-text {
        white-space: nowrap;
        overflow: visible;
        display: flex;
        width: 100%;
    }
}