//- fxLayoutGap -//
// As standard, we use 8px, 16px, 24px and 32px for gaps //

// Column-gap - Replaces fxLayoutGap used with fxLayout="row"
@for $i from 1 through 36 {
    .cg#{$i} {
        column-gap: #{$i}#{"px"};
    }
}

.cg4p {
    column-gap: 4%;
}

.cg6p {
    column-gap: 6%;
}

.cg15p {
    column-gap: 15%;
}

.cg20p {
    column-gap: 20%;
}

// Row-gap - Replaces fxLayoutGap used with fxLayout="column"
@for $i from 1 through 36 {
    .rg#{$i} {
        row-gap: #{$i}#{"px"};
    }
}

// - fxFlexOffset: Use margin-left classes