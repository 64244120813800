.s18 {
    font-size: 18px;
    line-height: 24px;
    vertical-align: middle;
    text-align: center;
}

.large-checkmark {
    font-size: 72px;
    line-height: 24px;
    margin-right: 50px;
    margin-top: 25px;
}

.search-close-button {
    //Fixes alignment of X icon in search bar
    top: auto !important;
}

button.mat-mdc-icon-button svg {
    // Fix for disappearing paginator icons
    width: 26px;
}

.mat-toolbar button.mat-mdc-icon-button {
    // Fix for icon buttons in toolbars being taller than their container - remove if cause of bug is found and corrected
    line-height: 24px;
}

.mat-mdc-icon-button .mat-icon {
    vertical-align: middle;
}