@use '../abstracts/fonts' as fonts;

@font-face {
    font-family: Visuelt-Bold;
    src: url('../../assets/fonts/UnzerVisuelt/UnzerVisuelt-Bold.otf') format("opentype");
}

@font-face {
    font-family: Inter-Bold;
    src: url('../../assets/fonts/Inter/Inter-Bold.ttf') format("opentype");
}

@font-face {
    font-family: Inter-Medium;
    src: url('../../assets/fonts/Inter/Inter-Medium.ttf') format("opentype");
}

@font-face {
    font-family: Inter-SemiBold;
    src: url('../../assets/fonts/Inter/Inter-SemiBold.ttf') format("opentype");
}

@font-face {
    font-family: Inter-Regular;
    src: url('../../assets/fonts/Inter/Inter-Regular.ttf') format("opentype");
}

@font-face {
    font-family: SF-Mono-Regular;
    src: url('../../assets/fonts/SFMono/SF-Mono-Regular.otf') format("opentype");
}

@for $i from 1 through 20 {
    .fs#{$i * 2} {
        font-size: #{$i * 2}#{"px"};
    }
}

.ff-mono-regular {
    font-family: fonts.$font-sf-mono-regular;
}