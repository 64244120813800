@use '../../abstracts/fonts' as fonts;

.mat-mdc-tab-label {
    padding: 12px 18px !important;
    min-width: 0 !important;
    text-transform: uppercase;

    .mat-mdc-tab-label-content {
        font-family: fonts.$font-regular;
        font-size: 14px;
        line-height: 20px;
    }
}

.mdc-tab {
    padding: 0 !important;
}

.mdc-tab__text-label {
    padding: 12px 18px !important;
    min-width: 0 !important;
    text-transform: uppercase;
    font-weight: normal;
}

.mat-mdc-tab-nav-bar,
.mat-mdc-tab-header {
    border-bottom: none;
}

.mat-mdc-paginator .mat-mdc-text-field-wrapper.mdc-text-field--outlined .mat-mdc-form-field-infix {
    padding: 8px 14px;
}