@use '@angular/material' as mat;

@mixin account-layout-theme($theme) {
    $primary: mat.get-color-from-palette($theme, primary);
    $accent: mat.get-color-from-palette($theme, accent);

    .account-layout-active-row {
        mat-icon,
        .nav-item-text {
            color: mat.get-color-from-palette($accent) !important;
        }
    }

    .no-hover-effect.mat-mdc-nav-list .mat-mdc-list-item {
        &:hover,
        &:focus {
            background-color: transparent !important;
        }

        /* stylelint-disable-next-line selector-class-pattern */
        .mdc-list-item__content {
            padding-right: 0;

            .mat-mdc-list-item-ripple.mat-ripple {
                display: none;
            }

            .mat-mdc-button {
                height: 100%;
                border-radius: 4px !important;

                .mat-mdc-button-persistent-ripple::before {
                    background-color: transparent;
                }

                // &:hover {
                //     background: #e6e7ea;
                // }

                mat-icon {
                    color: #9295A3;
                }
            }
        }
    }

    mat-list-item {
        .mat-mdc-button {
            margin-left: -10px;
            padding-left: 10px !important;
        }
    }

    .sublist {
        .mat-mdc-expansion-panel {
            box-shadow: none;
        }

        .mat-expansion-panel-body {
            padding: 0 0 0 40px;

            .mat-mdc-nav-list {
                padding-top: 0;

                .mat-mdc-list-item .mat-mdc-button {
                    color: #6D7184;
                    width: 300px;
                    text-align: left;
                    font-size: 12px;
                }
            }
        }
    }
}
