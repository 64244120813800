.small-text {
    font-size: 14px;
}

.font-weight-normal {
    font-weight: normal !important;
}

.large-text {
    font-size: 120% !important;
}

.uppercase {
    text-transform: uppercase;
}