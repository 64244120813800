@use '../abstracts/toolbar' as toolbar;

ui-view {
    margin-bottom: 36px;
}

.ui-container {
    overflow: auto !important;
    height: calc(100% - #{toolbar.$toolbar-height}) !important;
    position: relative;
    top: toolbar.$toolbar-height;
}

@media screen and (width <= 600px) {
    .ui-container {
        height: calc(100% - #{toolbar.$toolbar-height-s}) !important;
        position: relative;
        top: toolbar.$toolbar-height-s;
    }
}
