@use '../abstracts/screens' as screens;
@use '../abstracts/spacer-mixin' as spacer;

// xs
@media (min-width: screens.$screen-xs) {
    @include spacer.make-space($prefix: '');
}

// mobile
@media (min-width: screens.$screen-sm) {
    @include spacer.make-space($prefix: 'sm-');
}

// tablet
@media (min-width: screens.$screen-md) {
    @include spacer.make-space($prefix: 'md-');
}

// desktop
@media (min-width: screens.$screen-lg) {
    @include spacer.make-space($prefix: 'lg-');
}

// large screens
@media (min-width: screens.$screen-xl) {
    @include spacer.make-space($prefix: 'xl-');
}

@media screen and (width >= 1470px) {
    .wide-content {
        min-width: 1200px;
    }
}
