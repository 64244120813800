@use '../../abstracts/colors' as colors;

.mat-divider {
    border-top-color: colors.$grey_4;
    border-top-width: 0 !important;
}

.divider.mat-divider {
    margin: 16px -16px;
    position: relative !important;
    width: auto !important;
}