@use '../../abstracts/fonts' as fonts;
@use '../../abstracts/text-mixins' as text;

.mat-mdc-step-label {
    @include text.text(16px, 24px, fonts.$font-regular);
}

.mat-mdc-step-label-selected {
    //subtitle1
    @include text.text(16px, 24px, fonts.$font-regular);
}