$truncate-array: 100, 130, 150, 200, 250, 300, 350, 400;

.truncate {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

@each $value in $truncate-array {
    .truncate-#{$value} {
        max-width: calc(#{$value} * 1px) !important;
        max-width: calc(#{$value} * 1px) !important;
        max-width: calc(#{$value} * 1px) !important;
    }
}