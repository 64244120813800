@use '../../abstracts/fonts' as fonts;
@use '../../abstracts/text-mixins' as text;

mat-radio-group {
    &[fxlayout='column'] {
        mat-radio-button {
            &:not(:last-child) {
                margin-bottom: 12px;
            }
        }
    }
}

.mat-mdc-radio-label-content {
    @include text.text(16px, 24px, fonts.$font-regular);
}