@use '../abstracts/text-mixins' as text;
@use '../abstracts/fonts' as fonts;

ol,
ul {
    &.no-indent {
        padding-left: 15px;
    }
}

.multiline-list-item {
    height: auto !important;
}

.nav-item-text {
    @include text.text(14px, 20px, fonts.$font-semibold);
}