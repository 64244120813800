.mat-ripple {
  overflow: hidden;
  position: relative;
}
.mat-ripple:not(:empty) {
  transform: translateZ(0);
}

.mat-ripple.mat-ripple-unbounded {
  overflow: visible;
}

.mat-ripple-element {
  position: absolute;
  border-radius: 50%;
  pointer-events: none;
  transition: opacity, transform 0ms cubic-bezier(0, 0, 0.2, 1);
  transform: scale3d(0, 0, 0);
  background-color: var(--mat-ripple-color, rgba(0, 0, 0, 0.1));
}
.cdk-high-contrast-active .mat-ripple-element {
  display: none;
}

.cdk-visually-hidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
  white-space: nowrap;
  outline: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  left: 0;
}
[dir=rtl] .cdk-visually-hidden {
  left: auto;
  right: 0;
}

.cdk-overlay-container, .cdk-global-overlay-wrapper {
  pointer-events: none;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}

.cdk-overlay-container {
  position: fixed;
  z-index: 1000;
}
.cdk-overlay-container:empty {
  display: none;
}

.cdk-global-overlay-wrapper {
  display: flex;
  position: absolute;
  z-index: 1000;
}

.cdk-overlay-pane {
  position: absolute;
  pointer-events: auto;
  box-sizing: border-box;
  z-index: 1000;
  display: flex;
  max-width: 100%;
  max-height: 100%;
}

.cdk-overlay-backdrop {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  pointer-events: auto;
  -webkit-tap-highlight-color: transparent;
  transition: opacity 400ms cubic-bezier(0.25, 0.8, 0.25, 1);
  opacity: 0;
}
.cdk-overlay-backdrop.cdk-overlay-backdrop-showing {
  opacity: 1;
}
.cdk-high-contrast-active .cdk-overlay-backdrop.cdk-overlay-backdrop-showing {
  opacity: 0.6;
}

.cdk-overlay-dark-backdrop {
  background: rgba(0, 0, 0, 0.32);
}

.cdk-overlay-transparent-backdrop {
  transition: visibility 1ms linear, opacity 1ms linear;
  visibility: hidden;
  opacity: 1;
}
.cdk-overlay-transparent-backdrop.cdk-overlay-backdrop-showing {
  opacity: 0;
  visibility: visible;
}

.cdk-overlay-backdrop-noop-animation {
  transition: none;
}

.cdk-overlay-connected-position-bounding-box {
  position: absolute;
  z-index: 1000;
  display: flex;
  flex-direction: column;
  min-width: 1px;
  min-height: 1px;
}

.cdk-global-scrollblock {
  position: fixed;
  width: 100%;
  overflow-y: scroll;
}

textarea.cdk-textarea-autosize {
  resize: none;
}

textarea.cdk-textarea-autosize-measuring {
  padding: 2px 0 !important;
  box-sizing: content-box !important;
  height: auto !important;
  overflow: hidden !important;
}

textarea.cdk-textarea-autosize-measuring-firefox {
  padding: 2px 0 !important;
  box-sizing: content-box !important;
  height: 0 !important;
}

@keyframes cdk-text-field-autofill-start { /*!*/ }
@keyframes cdk-text-field-autofill-end { /*!*/ }
.cdk-text-field-autofill-monitored:-webkit-autofill {
  animation: cdk-text-field-autofill-start 0s 1ms;
}

.cdk-text-field-autofill-monitored:not(:-webkit-autofill) {
  animation: cdk-text-field-autofill-end 0s 1ms;
}

.mat-focus-indicator {
  position: relative;
}
.mat-focus-indicator::before {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: absolute;
  box-sizing: border-box;
  pointer-events: none;
  display: var(--mat-focus-indicator-display, none);
  border: var(--mat-focus-indicator-border-width, 3px) var(--mat-focus-indicator-border-style, solid) var(--mat-focus-indicator-border-color, transparent);
  border-radius: var(--mat-focus-indicator-border-radius, 4px);
}
.mat-focus-indicator:focus::before {
  content: "";
}

.cdk-high-contrast-active {
  --mat-focus-indicator-display: block;
}

.mat-mdc-focus-indicator {
  position: relative;
}
.mat-mdc-focus-indicator::before {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: absolute;
  box-sizing: border-box;
  pointer-events: none;
  display: var(--mat-mdc-focus-indicator-display, none);
  border: var(--mat-mdc-focus-indicator-border-width, 3px) var(--mat-mdc-focus-indicator-border-style, solid) var(--mat-mdc-focus-indicator-border-color, transparent);
  border-radius: var(--mat-mdc-focus-indicator-border-radius, 4px);
}
.mat-mdc-focus-indicator:focus::before {
  content: "";
}

.cdk-high-contrast-active {
  --mat-mdc-focus-indicator-display: block;
}

.mat-app-background {
  background-color: var(--mat-app-background-color, transparent);
  color: var(--mat-app-text-color, inherit);
}

html {
  --mdc-text-button-container-shape: 4px;
  --mdc-text-button-keep-touch-target: false;
  --mdc-filled-button-container-shape: 4px;
  --mdc-filled-button-keep-touch-target: false;
  --mdc-protected-button-container-shape: 4px;
  --mdc-protected-button-keep-touch-target: false;
  --mdc-outlined-button-keep-touch-target: false;
  --mdc-outlined-button-outline-width: 1px;
  --mdc-outlined-button-container-shape: 4px;
}

html {
  --mdc-text-button-label-text-color: #000;
  --mdc-text-button-disabled-label-text-color: rgba(0, 0, 0, 0.38);
  --mat-text-button-state-layer-color: #000;
  --mat-text-button-disabled-state-layer-color: #000;
  --mat-text-button-ripple-color: rgba(0, 0, 0, 0.1);
  --mat-text-button-hover-state-layer-opacity: 0.04;
  --mat-text-button-focus-state-layer-opacity: 0.12;
  --mat-text-button-pressed-state-layer-opacity: 0.12;
  --mdc-filled-button-container-color: white;
  --mdc-filled-button-label-text-color: #000;
  --mdc-filled-button-disabled-container-color: rgba(0, 0, 0, 0.12);
  --mdc-filled-button-disabled-label-text-color: rgba(0, 0, 0, 0.38);
  --mat-filled-button-state-layer-color: #000;
  --mat-filled-button-disabled-state-layer-color: #000;
  --mat-filled-button-ripple-color: rgba(0, 0, 0, 0.1);
  --mat-filled-button-hover-state-layer-opacity: 0.04;
  --mat-filled-button-focus-state-layer-opacity: 0.12;
  --mat-filled-button-pressed-state-layer-opacity: 0.12;
  --mdc-protected-button-container-color: white;
  --mdc-protected-button-label-text-color: #000;
  --mdc-protected-button-disabled-container-color: rgba(0, 0, 0, 0.12);
  --mdc-protected-button-disabled-label-text-color: rgba(0, 0, 0, 0.38);
  --mdc-protected-button-container-elevation-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
  --mdc-protected-button-disabled-container-elevation-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
  --mdc-protected-button-focus-container-elevation-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  --mdc-protected-button-hover-container-elevation-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  --mdc-protected-button-pressed-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mdc-protected-button-container-shadow-color: #000;
  --mat-protected-button-state-layer-color: #000;
  --mat-protected-button-disabled-state-layer-color: #000;
  --mat-protected-button-ripple-color: rgba(0, 0, 0, 0.1);
  --mat-protected-button-hover-state-layer-opacity: 0.04;
  --mat-protected-button-focus-state-layer-opacity: 0.12;
  --mat-protected-button-pressed-state-layer-opacity: 0.12;
  --mdc-outlined-button-disabled-outline-color: rgba(0, 0, 0, 0.12);
  --mdc-outlined-button-disabled-label-text-color: rgba(0, 0, 0, 0.38);
  --mdc-outlined-button-label-text-color: #000;
  --mdc-outlined-button-outline-color: rgba(0, 0, 0, 0.12);
  --mat-outlined-button-state-layer-color: #000;
  --mat-outlined-button-disabled-state-layer-color: #000;
  --mat-outlined-button-ripple-color: rgba(0, 0, 0, 0.1);
  --mat-outlined-button-hover-state-layer-opacity: 0.04;
  --mat-outlined-button-focus-state-layer-opacity: 0.12;
  --mat-outlined-button-pressed-state-layer-opacity: 0.12;
}

.mat-mdc-button.mat-primary {
  --mdc-text-button-label-text-color: #28303a;
  --mat-text-button-state-layer-color: #28303a;
  --mat-text-button-ripple-color: rgba(40, 48, 58, 0.1);
}
.mat-mdc-button.mat-accent {
  --mdc-text-button-label-text-color: #fc1154;
  --mat-text-button-state-layer-color: #fc1154;
  --mat-text-button-ripple-color: rgba(252, 17, 84, 0.1);
}
.mat-mdc-button.mat-warn {
  --mdc-text-button-label-text-color: #f44336;
  --mat-text-button-state-layer-color: #f44336;
  --mat-text-button-ripple-color: rgba(244, 67, 54, 0.1);
}

.mat-mdc-unelevated-button.mat-primary {
  --mdc-filled-button-container-color: #28303a;
  --mdc-filled-button-label-text-color: #fff;
  --mat-filled-button-state-layer-color: #fff;
  --mat-filled-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.mat-mdc-unelevated-button.mat-accent {
  --mdc-filled-button-container-color: #fc1154;
  --mdc-filled-button-label-text-color: #fff;
  --mat-filled-button-state-layer-color: #fff;
  --mat-filled-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.mat-mdc-unelevated-button.mat-warn {
  --mdc-filled-button-container-color: #f44336;
  --mdc-filled-button-label-text-color: #fff;
  --mat-filled-button-state-layer-color: #fff;
  --mat-filled-button-ripple-color: rgba(255, 255, 255, 0.1);
}

.mat-mdc-raised-button.mat-primary {
  --mdc-protected-button-container-color: #28303a;
  --mdc-protected-button-label-text-color: #fff;
  --mat-protected-button-state-layer-color: #fff;
  --mat-protected-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.mat-mdc-raised-button.mat-accent {
  --mdc-protected-button-container-color: #fc1154;
  --mdc-protected-button-label-text-color: #fff;
  --mat-protected-button-state-layer-color: #fff;
  --mat-protected-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.mat-mdc-raised-button.mat-warn {
  --mdc-protected-button-container-color: #f44336;
  --mdc-protected-button-label-text-color: #fff;
  --mat-protected-button-state-layer-color: #fff;
  --mat-protected-button-ripple-color: rgba(255, 255, 255, 0.1);
}

.mat-mdc-outlined-button.mat-primary {
  --mdc-outlined-button-label-text-color: #28303a;
  --mdc-outlined-button-outline-color: rgba(0, 0, 0, 0.12);
  --mat-outlined-button-state-layer-color: #28303a;
  --mat-outlined-button-ripple-color: rgba(40, 48, 58, 0.1);
}
.mat-mdc-outlined-button.mat-accent {
  --mdc-outlined-button-label-text-color: #fc1154;
  --mdc-outlined-button-outline-color: rgba(0, 0, 0, 0.12);
  --mat-outlined-button-state-layer-color: #fc1154;
  --mat-outlined-button-ripple-color: rgba(252, 17, 84, 0.1);
}
.mat-mdc-outlined-button.mat-warn {
  --mdc-outlined-button-label-text-color: #f44336;
  --mdc-outlined-button-outline-color: rgba(0, 0, 0, 0.12);
  --mat-outlined-button-state-layer-color: #f44336;
  --mat-outlined-button-ripple-color: rgba(244, 67, 54, 0.1);
}

html {
  --mdc-text-button-container-height: 36px;
  --mdc-filled-button-container-height: 36px;
  --mdc-outlined-button-container-height: 36px;
  --mdc-protected-button-container-height: 36px;
}

html {
  --mdc-text-button-label-text-font: Roboto, sans-serif;
  --mdc-text-button-label-text-size: 14px;
  --mdc-text-button-label-text-tracking: 0.0892857143em;
  --mdc-text-button-label-text-weight: 500;
  --mdc-text-button-label-text-transform: none;
  --mdc-filled-button-label-text-font: Roboto, sans-serif;
  --mdc-filled-button-label-text-size: 14px;
  --mdc-filled-button-label-text-tracking: 0.0892857143em;
  --mdc-filled-button-label-text-weight: 500;
  --mdc-filled-button-label-text-transform: none;
  --mdc-outlined-button-label-text-font: Roboto, sans-serif;
  --mdc-outlined-button-label-text-size: 14px;
  --mdc-outlined-button-label-text-tracking: 0.0892857143em;
  --mdc-outlined-button-label-text-weight: 500;
  --mdc-outlined-button-label-text-transform: none;
  --mdc-protected-button-label-text-font: Roboto, sans-serif;
  --mdc-protected-button-label-text-size: 14px;
  --mdc-protected-button-label-text-tracking: 0.0892857143em;
  --mdc-protected-button-label-text-weight: 500;
  --mdc-protected-button-label-text-transform: none;
}

html {
  --mdc-list-list-item-container-shape: 0;
  --mdc-list-list-item-leading-avatar-shape: 50%;
  --mdc-list-list-item-container-color: transparent;
  --mdc-list-list-item-selected-container-color: transparent;
  --mdc-list-list-item-leading-avatar-color: transparent;
  --mdc-list-list-item-leading-icon-size: 24px;
  --mdc-list-list-item-leading-avatar-size: 40px;
  --mdc-list-list-item-trailing-icon-size: 24px;
  --mdc-list-list-item-disabled-state-layer-color: transparent;
  --mdc-list-list-item-disabled-state-layer-opacity: 0;
  --mdc-list-list-item-disabled-label-text-opacity: 0.38;
  --mdc-list-list-item-disabled-leading-icon-opacity: 0.38;
  --mdc-list-list-item-disabled-trailing-icon-opacity: 0.38;
}

html {
  --mdc-list-list-item-label-text-color: rgba(0, 0, 0, 0.87);
  --mdc-list-list-item-supporting-text-color: rgba(0, 0, 0, 0.54);
  --mdc-list-list-item-leading-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-list-list-item-trailing-supporting-text-color: rgba(0, 0, 0, 0.38);
  --mdc-list-list-item-trailing-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-list-list-item-selected-trailing-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-list-list-item-disabled-label-text-color: black;
  --mdc-list-list-item-disabled-leading-icon-color: black;
  --mdc-list-list-item-disabled-trailing-icon-color: black;
  --mdc-list-list-item-hover-label-text-color: rgba(0, 0, 0, 0.87);
  --mdc-list-list-item-hover-leading-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-list-list-item-hover-trailing-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-list-list-item-focus-label-text-color: rgba(0, 0, 0, 0.87);
  --mdc-list-list-item-hover-state-layer-color: black;
  --mdc-list-list-item-hover-state-layer-opacity: 0.04;
  --mdc-list-list-item-focus-state-layer-color: black;
  --mdc-list-list-item-focus-state-layer-opacity: 0.12;
}

.mdc-list-item__start,
.mdc-list-item__end {
  --mdc-radio-disabled-selected-icon-color: #000;
  --mdc-radio-disabled-unselected-icon-color: #000;
  --mdc-radio-unselected-hover-icon-color: #212121;
  --mdc-radio-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-selected-focus-icon-color: #28303a;
  --mdc-radio-selected-hover-icon-color: #28303a;
  --mdc-radio-selected-icon-color: #28303a;
  --mdc-radio-selected-pressed-icon-color: #28303a;
}

.mat-accent .mdc-list-item__start,
.mat-accent .mdc-list-item__end {
  --mdc-radio-disabled-selected-icon-color: #000;
  --mdc-radio-disabled-unselected-icon-color: #000;
  --mdc-radio-unselected-hover-icon-color: #212121;
  --mdc-radio-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-selected-focus-icon-color: #fc1154;
  --mdc-radio-selected-hover-icon-color: #fc1154;
  --mdc-radio-selected-icon-color: #fc1154;
  --mdc-radio-selected-pressed-icon-color: #fc1154;
}

.mat-warn .mdc-list-item__start,
.mat-warn .mdc-list-item__end {
  --mdc-radio-disabled-selected-icon-color: #000;
  --mdc-radio-disabled-unselected-icon-color: #000;
  --mdc-radio-unselected-hover-icon-color: #212121;
  --mdc-radio-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-selected-focus-icon-color: #f44336;
  --mdc-radio-selected-hover-icon-color: #f44336;
  --mdc-radio-selected-icon-color: #f44336;
  --mdc-radio-selected-pressed-icon-color: #f44336;
}

.mat-mdc-list-option {
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-selected-checkmark-color: #fff;
  --mdc-checkbox-selected-focus-icon-color: #28303a;
  --mdc-checkbox-selected-hover-icon-color: #28303a;
  --mdc-checkbox-selected-icon-color: #28303a;
  --mdc-checkbox-selected-pressed-icon-color: #28303a;
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #28303a;
  --mdc-checkbox-selected-hover-state-layer-color: #28303a;
  --mdc-checkbox-selected-pressed-state-layer-color: #28303a;
  --mdc-checkbox-unselected-focus-state-layer-color: black;
  --mdc-checkbox-unselected-hover-state-layer-color: black;
  --mdc-checkbox-unselected-pressed-state-layer-color: black;
}

.mat-mdc-list-option.mat-accent {
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-selected-checkmark-color: #fff;
  --mdc-checkbox-selected-focus-icon-color: #fc1154;
  --mdc-checkbox-selected-hover-icon-color: #fc1154;
  --mdc-checkbox-selected-icon-color: #fc1154;
  --mdc-checkbox-selected-pressed-icon-color: #fc1154;
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #fc1154;
  --mdc-checkbox-selected-hover-state-layer-color: #fc1154;
  --mdc-checkbox-selected-pressed-state-layer-color: #fc1154;
  --mdc-checkbox-unselected-focus-state-layer-color: black;
  --mdc-checkbox-unselected-hover-state-layer-color: black;
  --mdc-checkbox-unselected-pressed-state-layer-color: black;
}

.mat-mdc-list-option.mat-warn {
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-selected-checkmark-color: #fff;
  --mdc-checkbox-selected-focus-icon-color: #f44336;
  --mdc-checkbox-selected-hover-icon-color: #f44336;
  --mdc-checkbox-selected-icon-color: #f44336;
  --mdc-checkbox-selected-pressed-icon-color: #f44336;
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #f44336;
  --mdc-checkbox-selected-hover-state-layer-color: #f44336;
  --mdc-checkbox-selected-pressed-state-layer-color: #f44336;
  --mdc-checkbox-unselected-focus-state-layer-color: black;
  --mdc-checkbox-unselected-hover-state-layer-color: black;
  --mdc-checkbox-unselected-pressed-state-layer-color: black;
}

.mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--selected .mdc-list-item__primary-text,
.mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--activated .mdc-list-item__primary-text {
  color: #28303a;
}
.mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--selected.mdc-list-item--with-leading-icon .mdc-list-item__start,
.mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--activated.mdc-list-item--with-leading-icon .mdc-list-item__start {
  color: #28303a;
}

.mat-mdc-list-base .mdc-list-item--disabled .mdc-list-item__start,
.mat-mdc-list-base .mdc-list-item--disabled .mdc-list-item__content,
.mat-mdc-list-base .mdc-list-item--disabled .mdc-list-item__end {
  opacity: 1;
}

html {
  --mdc-list-list-item-one-line-container-height: 48px;
  --mdc-list-list-item-two-line-container-height: 64px;
  --mdc-list-list-item-three-line-container-height: 88px;
}

.mdc-list-item__start,
.mdc-list-item__end {
  --mdc-radio-state-layer-size: 40px;
}

.mat-mdc-list-item.mdc-list-item--with-leading-avatar.mdc-list-item--with-one-line, .mat-mdc-list-item.mdc-list-item--with-leading-checkbox.mdc-list-item--with-one-line, .mat-mdc-list-item.mdc-list-item--with-leading-icon.mdc-list-item--with-one-line {
  height: 56px;
}
.mat-mdc-list-item.mdc-list-item--with-leading-avatar.mdc-list-item--with-two-lines, .mat-mdc-list-item.mdc-list-item--with-leading-checkbox.mdc-list-item--with-two-lines, .mat-mdc-list-item.mdc-list-item--with-leading-icon.mdc-list-item--with-two-lines {
  height: 72px;
}

html {
  --mdc-list-list-item-label-text-font: Inter-Regular, arial, sans-serif;
  --mdc-list-list-item-label-text-line-height: 20px;
  --mdc-list-list-item-label-text-size: 14px;
  --mdc-list-list-item-label-text-tracking: normal;
  --mdc-list-list-item-label-text-weight: 400;
  --mdc-list-list-item-supporting-text-font: Inter-Regular, arial, sans-serif;
  --mdc-list-list-item-supporting-text-line-height: 20px;
  --mdc-list-list-item-supporting-text-size: 14px;
  --mdc-list-list-item-supporting-text-tracking: normal;
  --mdc-list-list-item-supporting-text-weight: 500;
  --mdc-list-list-item-trailing-supporting-text-font: Roboto, sans-serif;
  --mdc-list-list-item-trailing-supporting-text-line-height: 20px;
  --mdc-list-list-item-trailing-supporting-text-size: 12px;
  --mdc-list-list-item-trailing-supporting-text-tracking: 0.0333333333em;
  --mdc-list-list-item-trailing-supporting-text-weight: 400;
}

.mdc-list-group__subheader {
  font: 500 20px / 24px "Visuelt-Bold", "arial", sans-serif;
  letter-spacing: normal;
}

html {
  --mdc-filled-text-field-active-indicator-height: 1px;
  --mdc-filled-text-field-focus-active-indicator-height: 2px;
  --mdc-filled-text-field-container-shape: 4px;
  --mdc-outlined-text-field-outline-width: 1px;
  --mdc-outlined-text-field-focus-outline-width: 2px;
  --mdc-outlined-text-field-container-shape: 4px;
}

html {
  --mdc-filled-text-field-caret-color: #28303a;
  --mdc-filled-text-field-focus-active-indicator-color: #28303a;
  --mdc-filled-text-field-focus-label-text-color: rgba(40, 48, 58, 0.87);
  --mdc-filled-text-field-container-color: whitesmoke;
  --mdc-filled-text-field-disabled-container-color: #fafafa;
  --mdc-filled-text-field-label-text-color: rgba(0, 0, 0, 0.6);
  --mdc-filled-text-field-hover-label-text-color: rgba(0, 0, 0, 0.6);
  --mdc-filled-text-field-disabled-label-text-color: rgba(0, 0, 0, 0.38);
  --mdc-filled-text-field-input-text-color: rgba(0, 0, 0, 0.87);
  --mdc-filled-text-field-disabled-input-text-color: rgba(0, 0, 0, 0.38);
  --mdc-filled-text-field-input-text-placeholder-color: rgba(0, 0, 0, 0.6);
  --mdc-filled-text-field-error-hover-label-text-color: #f44336;
  --mdc-filled-text-field-error-focus-label-text-color: #f44336;
  --mdc-filled-text-field-error-label-text-color: #f44336;
  --mdc-filled-text-field-error-caret-color: #f44336;
  --mdc-filled-text-field-active-indicator-color: rgba(0, 0, 0, 0.42);
  --mdc-filled-text-field-disabled-active-indicator-color: rgba(0, 0, 0, 0.06);
  --mdc-filled-text-field-hover-active-indicator-color: rgba(0, 0, 0, 0.87);
  --mdc-filled-text-field-error-active-indicator-color: #f44336;
  --mdc-filled-text-field-error-focus-active-indicator-color: #f44336;
  --mdc-filled-text-field-error-hover-active-indicator-color: #f44336;
  --mdc-outlined-text-field-caret-color: #28303a;
  --mdc-outlined-text-field-focus-outline-color: #28303a;
  --mdc-outlined-text-field-focus-label-text-color: rgba(40, 48, 58, 0.87);
  --mdc-outlined-text-field-label-text-color: rgba(0, 0, 0, 0.6);
  --mdc-outlined-text-field-hover-label-text-color: rgba(0, 0, 0, 0.6);
  --mdc-outlined-text-field-disabled-label-text-color: rgba(0, 0, 0, 0.38);
  --mdc-outlined-text-field-input-text-color: rgba(0, 0, 0, 0.87);
  --mdc-outlined-text-field-disabled-input-text-color: rgba(0, 0, 0, 0.38);
  --mdc-outlined-text-field-input-text-placeholder-color: rgba(0, 0, 0, 0.6);
  --mdc-outlined-text-field-error-caret-color: #f44336;
  --mdc-outlined-text-field-error-focus-label-text-color: #f44336;
  --mdc-outlined-text-field-error-label-text-color: #f44336;
  --mdc-outlined-text-field-error-hover-label-text-color: #f44336;
  --mdc-outlined-text-field-outline-color: rgba(0, 0, 0, 0.38);
  --mdc-outlined-text-field-disabled-outline-color: rgba(0, 0, 0, 0.06);
  --mdc-outlined-text-field-hover-outline-color: rgba(0, 0, 0, 0.87);
  --mdc-outlined-text-field-error-focus-outline-color: #f44336;
  --mdc-outlined-text-field-error-hover-outline-color: #f44336;
  --mdc-outlined-text-field-error-outline-color: #f44336;
  --mat-form-field-focus-select-arrow-color: rgba(40, 48, 58, 0.87);
  --mat-form-field-disabled-input-text-placeholder-color: rgba(0, 0, 0, 0.38);
  --mat-form-field-state-layer-color: rgba(0, 0, 0, 0.87);
  --mat-form-field-error-text-color: #f44336;
  --mat-form-field-select-option-text-color: inherit;
  --mat-form-field-select-disabled-option-text-color: GrayText;
  --mat-form-field-leading-icon-color: unset;
  --mat-form-field-disabled-leading-icon-color: unset;
  --mat-form-field-trailing-icon-color: unset;
  --mat-form-field-disabled-trailing-icon-color: unset;
  --mat-form-field-error-focus-trailing-icon-color: unset;
  --mat-form-field-error-hover-trailing-icon-color: unset;
  --mat-form-field-error-trailing-icon-color: unset;
  --mat-form-field-enabled-select-arrow-color: rgba(0, 0, 0, 0.54);
  --mat-form-field-disabled-select-arrow-color: rgba(0, 0, 0, 0.38);
  --mat-form-field-hover-state-layer-opacity: 0.04;
  --mat-form-field-focus-state-layer-opacity: 0.08;
}

.mat-mdc-form-field.mat-accent {
  --mdc-filled-text-field-caret-color: #fc1154;
  --mdc-filled-text-field-focus-active-indicator-color: #fc1154;
  --mdc-filled-text-field-focus-label-text-color: rgba(252, 17, 84, 0.87);
  --mdc-outlined-text-field-caret-color: #fc1154;
  --mdc-outlined-text-field-focus-outline-color: #fc1154;
  --mdc-outlined-text-field-focus-label-text-color: rgba(252, 17, 84, 0.87);
  --mat-form-field-focus-select-arrow-color: rgba(252, 17, 84, 0.87);
}

.mat-mdc-form-field.mat-warn {
  --mdc-filled-text-field-caret-color: #f44336;
  --mdc-filled-text-field-focus-active-indicator-color: #f44336;
  --mdc-filled-text-field-focus-label-text-color: rgba(244, 67, 54, 0.87);
  --mdc-outlined-text-field-caret-color: #f44336;
  --mdc-outlined-text-field-focus-outline-color: #f44336;
  --mdc-outlined-text-field-focus-label-text-color: rgba(244, 67, 54, 0.87);
  --mat-form-field-focus-select-arrow-color: rgba(244, 67, 54, 0.87);
}

html {
  --mat-form-field-container-height: 56px;
  --mat-form-field-filled-label-display: block;
  --mat-form-field-container-vertical-padding: 16px;
  --mat-form-field-filled-with-label-container-padding-top: 24px;
  --mat-form-field-filled-with-label-container-padding-bottom: 8px;
}

html {
  --mdc-filled-text-field-label-text-font: Inter-Regular, arial, sans-serif;
  --mdc-filled-text-field-label-text-size: 14px;
  --mdc-filled-text-field-label-text-tracking: normal;
  --mdc-filled-text-field-label-text-weight: 400;
  --mdc-outlined-text-field-label-text-font: Inter-Regular, arial, sans-serif;
  --mdc-outlined-text-field-label-text-size: 14px;
  --mdc-outlined-text-field-label-text-tracking: normal;
  --mdc-outlined-text-field-label-text-weight: 400;
  --mat-form-field-container-text-font: Inter-Regular, arial, sans-serif;
  --mat-form-field-container-text-line-height: 20px;
  --mat-form-field-container-text-size: 14px;
  --mat-form-field-container-text-tracking: normal;
  --mat-form-field-container-text-weight: 400;
  --mat-form-field-outlined-label-text-populated-size: 14px;
  --mat-form-field-subscript-text-font: Roboto, sans-serif;
  --mat-form-field-subscript-text-line-height: 20px;
  --mat-form-field-subscript-text-size: 12px;
  --mat-form-field-subscript-text-tracking: 0.0333333333em;
  --mat-form-field-subscript-text-weight: 400;
}

html {
  --mdc-elevated-card-container-shape: 4px;
  --mdc-outlined-card-container-shape: 4px;
  --mdc-outlined-card-outline-width: 1px;
}

html {
  --mdc-elevated-card-container-color: white;
  --mdc-elevated-card-container-elevation: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  --mdc-outlined-card-container-color: white;
  --mdc-outlined-card-outline-color: rgba(0, 0, 0, 0.12);
  --mdc-outlined-card-container-elevation: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
  --mat-card-subtitle-text-color: rgba(0, 0, 0, 0.54);
}

html {
  --mat-card-title-text-font: Visuelt-Bold, arial, sans-serif;
  --mat-card-title-text-line-height: 60px;
  --mat-card-title-text-size: 48px;
  --mat-card-title-text-tracking: normal;
  --mat-card-title-text-weight: 500;
  --mat-card-subtitle-text-font: Visuelt-Bold, arial, sans-serif;
  --mat-card-subtitle-text-line-height: 20px;
  --mat-card-subtitle-text-size: 16px;
  --mat-card-subtitle-text-tracking: normal;
  --mat-card-subtitle-text-weight: 400;
}

html {
  --mat-icon-color: inherit;
}

.mat-icon.mat-primary {
  --mat-icon-color: #94989d;
}
.mat-icon.mat-accent {
  --mat-icon-color: #fc1154;
}
.mat-icon.mat-warn {
  --mat-icon-color: #f44336;
}

html {
  --mat-toolbar-container-background-color: whitesmoke;
  --mat-toolbar-container-text-color: rgba(0, 0, 0, 0.87);
}

.mat-toolbar.mat-primary {
  --mat-toolbar-container-background-color: #28303a;
  --mat-toolbar-container-text-color: white;
}
.mat-toolbar.mat-accent {
  --mat-toolbar-container-background-color: #fc1154;
  --mat-toolbar-container-text-color: white;
}
.mat-toolbar.mat-warn {
  --mat-toolbar-container-background-color: #f44336;
  --mat-toolbar-container-text-color: white;
}

html {
  --mat-toolbar-standard-height: 64px;
  --mat-toolbar-mobile-height: 56px;
}

html {
  --mat-toolbar-title-text-font: Visuelt-Bold, arial, sans-serif;
  --mat-toolbar-title-text-line-height: 60px;
  --mat-toolbar-title-text-size: 48px;
  --mat-toolbar-title-text-tracking: normal;
  --mat-toolbar-title-text-weight: 500;
}

html {
  --mat-table-row-item-outline-width: 1px;
}

html {
  --mat-table-background-color: white;
  --mat-table-header-headline-color: rgba(0, 0, 0, 0.87);
  --mat-table-row-item-label-text-color: rgba(0, 0, 0, 0.87);
  --mat-table-row-item-outline-color: rgba(0, 0, 0, 0.12);
}

html {
  --mat-table-header-container-height: 56px;
  --mat-table-footer-container-height: 52px;
  --mat-table-row-item-container-height: 52px;
}

html {
  --mat-table-header-headline-font: Visuelt-Bold, arial, sans-serif;
  --mat-table-header-headline-line-height: 20px;
  --mat-table-header-headline-size: 16px;
  --mat-table-header-headline-weight: 400;
  --mat-table-header-headline-tracking: normal;
  --mat-table-row-item-label-text-font: Inter-Regular, arial, sans-serif;
  --mat-table-row-item-label-text-line-height: 20px;
  --mat-table-row-item-label-text-size: 14px;
  --mat-table-row-item-label-text-weight: 500;
  --mat-table-row-item-label-text-tracking: normal;
  --mat-table-footer-supporting-text-font: Inter-Regular, arial, sans-serif;
  --mat-table-footer-supporting-text-line-height: 20px;
  --mat-table-footer-supporting-text-size: 14px;
  --mat-table-footer-supporting-text-weight: 500;
  --mat-table-footer-supporting-text-tracking: normal;
}

html {
  --mat-select-panel-background-color: white;
  --mat-select-enabled-trigger-text-color: rgba(0, 0, 0, 0.87);
  --mat-select-disabled-trigger-text-color: rgba(0, 0, 0, 0.38);
  --mat-select-placeholder-text-color: rgba(0, 0, 0, 0.6);
  --mat-select-enabled-arrow-color: rgba(0, 0, 0, 0.54);
  --mat-select-disabled-arrow-color: rgba(0, 0, 0, 0.38);
  --mat-select-focused-arrow-color: rgba(40, 48, 58, 0.87);
  --mat-select-invalid-arrow-color: rgba(244, 67, 54, 0.87);
}
html .mat-mdc-form-field.mat-accent {
  --mat-select-panel-background-color: white;
  --mat-select-enabled-trigger-text-color: rgba(0, 0, 0, 0.87);
  --mat-select-disabled-trigger-text-color: rgba(0, 0, 0, 0.38);
  --mat-select-placeholder-text-color: rgba(0, 0, 0, 0.6);
  --mat-select-enabled-arrow-color: rgba(0, 0, 0, 0.54);
  --mat-select-disabled-arrow-color: rgba(0, 0, 0, 0.38);
  --mat-select-focused-arrow-color: rgba(252, 17, 84, 0.87);
  --mat-select-invalid-arrow-color: rgba(244, 67, 54, 0.87);
}
html .mat-mdc-form-field.mat-warn {
  --mat-select-panel-background-color: white;
  --mat-select-enabled-trigger-text-color: rgba(0, 0, 0, 0.87);
  --mat-select-disabled-trigger-text-color: rgba(0, 0, 0, 0.38);
  --mat-select-placeholder-text-color: rgba(0, 0, 0, 0.6);
  --mat-select-enabled-arrow-color: rgba(0, 0, 0, 0.54);
  --mat-select-disabled-arrow-color: rgba(0, 0, 0, 0.38);
  --mat-select-focused-arrow-color: rgba(244, 67, 54, 0.87);
  --mat-select-invalid-arrow-color: rgba(244, 67, 54, 0.87);
}

html {
  --mat-select-trigger-text-font: Inter-Regular, arial, sans-serif;
  --mat-select-trigger-text-line-height: 20px;
  --mat-select-trigger-text-size: 14px;
  --mat-select-trigger-text-tracking: normal;
  --mat-select-trigger-text-weight: 400;
}

html {
  --mdc-tab-indicator-active-indicator-height: 2px;
  --mdc-tab-indicator-active-indicator-shape: 0;
  --mdc-secondary-navigation-tab-container-height: 48px;
  --mat-tab-header-divider-color: transparent;
  --mat-tab-header-divider-height: 0;
}

.mat-mdc-tab-group, .mat-mdc-tab-nav-bar {
  --mdc-tab-indicator-active-indicator-color: #28303a;
  --mat-tab-header-disabled-ripple-color: rgba(0, 0, 0, 0.38);
  --mat-tab-header-pagination-icon-color: #000;
  --mat-tab-header-inactive-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-active-label-text-color: #28303a;
  --mat-tab-header-active-ripple-color: #28303a;
  --mat-tab-header-inactive-ripple-color: #28303a;
  --mat-tab-header-inactive-focus-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-inactive-hover-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-active-focus-label-text-color: #28303a;
  --mat-tab-header-active-hover-label-text-color: #28303a;
  --mat-tab-header-active-focus-indicator-color: #28303a;
  --mat-tab-header-active-hover-indicator-color: #28303a;
}
.mat-mdc-tab-group.mat-accent, .mat-mdc-tab-nav-bar.mat-accent {
  --mdc-tab-indicator-active-indicator-color: #fc1154;
  --mat-tab-header-disabled-ripple-color: rgba(0, 0, 0, 0.38);
  --mat-tab-header-pagination-icon-color: #000;
  --mat-tab-header-inactive-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-active-label-text-color: #fc1154;
  --mat-tab-header-active-ripple-color: #fc1154;
  --mat-tab-header-inactive-ripple-color: #fc1154;
  --mat-tab-header-inactive-focus-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-inactive-hover-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-active-focus-label-text-color: #fc1154;
  --mat-tab-header-active-hover-label-text-color: #fc1154;
  --mat-tab-header-active-focus-indicator-color: #fc1154;
  --mat-tab-header-active-hover-indicator-color: #fc1154;
}
.mat-mdc-tab-group.mat-warn, .mat-mdc-tab-nav-bar.mat-warn {
  --mdc-tab-indicator-active-indicator-color: #f44336;
  --mat-tab-header-disabled-ripple-color: rgba(0, 0, 0, 0.38);
  --mat-tab-header-pagination-icon-color: #000;
  --mat-tab-header-inactive-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-active-label-text-color: #f44336;
  --mat-tab-header-active-ripple-color: #f44336;
  --mat-tab-header-inactive-ripple-color: #f44336;
  --mat-tab-header-inactive-focus-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-inactive-hover-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-active-focus-label-text-color: #f44336;
  --mat-tab-header-active-hover-label-text-color: #f44336;
  --mat-tab-header-active-focus-indicator-color: #f44336;
  --mat-tab-header-active-hover-indicator-color: #f44336;
}
.mat-mdc-tab-group.mat-background-primary, .mat-mdc-tab-nav-bar.mat-background-primary {
  --mat-tab-header-with-background-background-color: #28303a;
  --mat-tab-header-with-background-foreground-color: white;
}
.mat-mdc-tab-group.mat-background-accent, .mat-mdc-tab-nav-bar.mat-background-accent {
  --mat-tab-header-with-background-background-color: #fc1154;
  --mat-tab-header-with-background-foreground-color: white;
}
.mat-mdc-tab-group.mat-background-warn, .mat-mdc-tab-nav-bar.mat-background-warn {
  --mat-tab-header-with-background-background-color: #f44336;
  --mat-tab-header-with-background-foreground-color: white;
}

.mat-mdc-tab-header {
  --mdc-secondary-navigation-tab-container-height: 48px;
}

.mat-mdc-tab-header {
  --mat-tab-header-label-text-font: Roboto, sans-serif;
  --mat-tab-header-label-text-size: 14px;
  --mat-tab-header-label-text-tracking: 0.0892857143em;
  --mat-tab-header-label-text-line-height: 36px;
  --mat-tab-header-label-text-weight: 500;
}

html {
  --mat-paginator-container-text-color: rgba(0, 0, 0, 0.87);
  --mat-paginator-container-background-color: white;
  --mat-paginator-enabled-icon-color: rgba(0, 0, 0, 0.54);
  --mat-paginator-disabled-icon-color: rgba(0, 0, 0, 0.12);
}

html {
  --mat-paginator-container-size: 56px;
}

.mat-mdc-paginator {
  --mat-form-field-container-height: 40px;
  --mat-form-field-filled-label-display: none;
  --mat-form-field-container-vertical-padding: 8px;
  --mat-form-field-filled-with-label-container-padding-top: 8px;
  --mat-form-field-filled-with-label-container-padding-bottom: 8px;
}

html {
  --mat-paginator-container-text-font: Roboto, sans-serif;
  --mat-paginator-container-text-line-height: 20px;
  --mat-paginator-container-text-size: 12px;
  --mat-paginator-container-text-tracking: 0.0333333333em;
  --mat-paginator-container-text-weight: 400;
  --mat-paginator-select-trigger-text-size: 12px;
}

html {
  --mdc-circular-progress-active-indicator-width: 4px;
  --mdc-circular-progress-size: 48px;
}

html {
  --mdc-circular-progress-active-indicator-color: #28303a;
}
html .mat-accent {
  --mdc-circular-progress-active-indicator-color: #fc1154;
}
html .mat-warn {
  --mdc-circular-progress-active-indicator-color: #f44336;
}

html {
  --mdc-linear-progress-active-indicator-height: 4px;
  --mdc-linear-progress-track-height: 4px;
  --mdc-linear-progress-track-shape: 0;
}

.mat-mdc-progress-bar {
  --mdc-linear-progress-active-indicator-color: #28303a;
  --mdc-linear-progress-track-color: rgba(40, 48, 58, 0.25);
}
.mat-mdc-progress-bar.mat-accent {
  --mdc-linear-progress-active-indicator-color: #fc1154;
  --mdc-linear-progress-track-color: rgba(252, 17, 84, 0.25);
}
.mat-mdc-progress-bar.mat-warn {
  --mdc-linear-progress-active-indicator-color: #f44336;
  --mdc-linear-progress-track-color: rgba(244, 67, 54, 0.25);
}

html {
  --mdc-dialog-container-elevation-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
  --mdc-dialog-container-shadow-color: #000;
  --mdc-dialog-container-shape: 4px;
}

html {
  --mdc-dialog-container-color: white;
  --mdc-dialog-subhead-color: rgba(0, 0, 0, 0.87);
  --mdc-dialog-supporting-text-color: rgba(0, 0, 0, 0.6);
}

html {
  --mdc-dialog-subhead-font: Visuelt-Bold, arial, sans-serif;
  --mdc-dialog-subhead-line-height: 60px;
  --mdc-dialog-subhead-size: 48px;
  --mdc-dialog-subhead-weight: 500;
  --mdc-dialog-subhead-tracking: normal;
  --mdc-dialog-supporting-text-font: Inter-Regular, arial, sans-serif;
  --mdc-dialog-supporting-text-line-height: 20px;
  --mdc-dialog-supporting-text-size: 14px;
  --mdc-dialog-supporting-text-weight: 400;
  --mdc-dialog-supporting-text-tracking: normal;
}

html {
  --mdc-radio-disabled-selected-icon-opacity: 0.38;
  --mdc-radio-disabled-unselected-icon-opacity: 0.38;
  --mdc-radio-state-layer-size: 40px;
}

.mat-mdc-radio-button {
  --mdc-form-field-label-text-color: rgba(0, 0, 0, 0.87);
}
.mat-mdc-radio-button.mat-primary {
  --mdc-radio-disabled-selected-icon-color: #000;
  --mdc-radio-disabled-unselected-icon-color: #000;
  --mdc-radio-unselected-hover-icon-color: #212121;
  --mdc-radio-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-selected-focus-icon-color: #28303a;
  --mdc-radio-selected-hover-icon-color: #28303a;
  --mdc-radio-selected-icon-color: #28303a;
  --mdc-radio-selected-pressed-icon-color: #28303a;
  --mat-radio-ripple-color: #000;
  --mat-radio-checked-ripple-color: #28303a;
  --mat-radio-disabled-label-color: rgba(0, 0, 0, 0.38);
}
.mat-mdc-radio-button.mat-accent {
  --mdc-radio-disabled-selected-icon-color: #000;
  --mdc-radio-disabled-unselected-icon-color: #000;
  --mdc-radio-unselected-hover-icon-color: #212121;
  --mdc-radio-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-selected-focus-icon-color: #fc1154;
  --mdc-radio-selected-hover-icon-color: #fc1154;
  --mdc-radio-selected-icon-color: #fc1154;
  --mdc-radio-selected-pressed-icon-color: #fc1154;
  --mat-radio-ripple-color: #000;
  --mat-radio-checked-ripple-color: #fc1154;
  --mat-radio-disabled-label-color: rgba(0, 0, 0, 0.38);
}
.mat-mdc-radio-button.mat-warn {
  --mdc-radio-disabled-selected-icon-color: #000;
  --mdc-radio-disabled-unselected-icon-color: #000;
  --mdc-radio-unselected-hover-icon-color: #212121;
  --mdc-radio-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-selected-focus-icon-color: #f44336;
  --mdc-radio-selected-hover-icon-color: #f44336;
  --mdc-radio-selected-icon-color: #f44336;
  --mdc-radio-selected-pressed-icon-color: #f44336;
  --mat-radio-ripple-color: #000;
  --mat-radio-checked-ripple-color: #f44336;
  --mat-radio-disabled-label-color: rgba(0, 0, 0, 0.38);
}

html {
  --mdc-radio-state-layer-size: 40px;
}

.mat-mdc-radio-button {
  --mdc-form-field-label-text-font: Inter-Regular, arial, sans-serif;
  --mdc-form-field-label-text-line-height: 20px;
  --mdc-form-field-label-text-size: 14px;
  --mdc-form-field-label-text-tracking: normal;
  --mdc-form-field-label-text-weight: 500;
}

html {
  --mdc-checkbox-disabled-selected-checkmark-color: #fff;
  --mdc-checkbox-selected-focus-state-layer-opacity: 0.16;
  --mdc-checkbox-selected-hover-state-layer-opacity: 0.04;
  --mdc-checkbox-selected-pressed-state-layer-opacity: 0.16;
  --mdc-checkbox-unselected-focus-state-layer-opacity: 0.16;
  --mdc-checkbox-unselected-hover-state-layer-opacity: 0.04;
  --mdc-checkbox-unselected-pressed-state-layer-opacity: 0.16;
}

html {
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-selected-checkmark-color: #fff;
  --mdc-checkbox-selected-focus-icon-color: #fc1154;
  --mdc-checkbox-selected-hover-icon-color: #fc1154;
  --mdc-checkbox-selected-icon-color: #fc1154;
  --mdc-checkbox-selected-pressed-icon-color: #fc1154;
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #fc1154;
  --mdc-checkbox-selected-hover-state-layer-color: #fc1154;
  --mdc-checkbox-selected-pressed-state-layer-color: #fc1154;
  --mdc-checkbox-unselected-focus-state-layer-color: black;
  --mdc-checkbox-unselected-hover-state-layer-color: black;
  --mdc-checkbox-unselected-pressed-state-layer-color: black;
}

.mat-mdc-checkbox {
  --mdc-form-field-label-text-color: rgba(0, 0, 0, 0.87);
}
.mat-mdc-checkbox.mat-primary {
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-selected-checkmark-color: #fff;
  --mdc-checkbox-selected-focus-icon-color: #28303a;
  --mdc-checkbox-selected-hover-icon-color: #28303a;
  --mdc-checkbox-selected-icon-color: #28303a;
  --mdc-checkbox-selected-pressed-icon-color: #28303a;
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #28303a;
  --mdc-checkbox-selected-hover-state-layer-color: #28303a;
  --mdc-checkbox-selected-pressed-state-layer-color: #28303a;
  --mdc-checkbox-unselected-focus-state-layer-color: black;
  --mdc-checkbox-unselected-hover-state-layer-color: black;
  --mdc-checkbox-unselected-pressed-state-layer-color: black;
}
.mat-mdc-checkbox.mat-warn {
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-selected-checkmark-color: #fff;
  --mdc-checkbox-selected-focus-icon-color: #f44336;
  --mdc-checkbox-selected-hover-icon-color: #f44336;
  --mdc-checkbox-selected-icon-color: #f44336;
  --mdc-checkbox-selected-pressed-icon-color: #f44336;
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #f44336;
  --mdc-checkbox-selected-hover-state-layer-color: #f44336;
  --mdc-checkbox-selected-pressed-state-layer-color: #f44336;
  --mdc-checkbox-unselected-focus-state-layer-color: black;
  --mdc-checkbox-unselected-hover-state-layer-color: black;
  --mdc-checkbox-unselected-pressed-state-layer-color: black;
}
.mat-mdc-checkbox.mat-mdc-checkbox-disabled label {
  color: rgba(0, 0, 0, 0.38);
}

html {
  --mdc-checkbox-state-layer-size: 40px;
}

.mat-mdc-checkbox {
  --mdc-form-field-label-text-font: Inter-Regular, arial, sans-serif;
  --mdc-form-field-label-text-line-height: 20px;
  --mdc-form-field-label-text-size: 14px;
  --mdc-form-field-label-text-tracking: normal;
  --mdc-form-field-label-text-weight: 500;
}

html {
  --mdc-plain-tooltip-container-shape: 4px;
  --mdc-plain-tooltip-supporting-text-line-height: 16px;
}

html {
  --mdc-plain-tooltip-container-color: #616161;
  --mdc-plain-tooltip-supporting-text-color: #fff;
}

html {
  --mdc-plain-tooltip-supporting-text-font: Roboto, sans-serif;
  --mdc-plain-tooltip-supporting-text-size: 12px;
  --mdc-plain-tooltip-supporting-text-weight: 400;
  --mdc-plain-tooltip-supporting-text-tracking: 0.0333333333em;
}

html {
  --mat-autocomplete-background-color: white;
}

html {
  --mat-expansion-container-shape: 4px;
}

html {
  --mat-expansion-container-background-color: white;
  --mat-expansion-container-text-color: rgba(0, 0, 0, 0.87);
  --mat-expansion-actions-divider-color: rgba(0, 0, 0, 0.12);
  --mat-expansion-header-hover-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-expansion-header-focus-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-expansion-header-disabled-state-text-color: rgba(0, 0, 0, 0.26);
  --mat-expansion-header-text-color: rgba(0, 0, 0, 0.87);
  --mat-expansion-header-description-color: rgba(0, 0, 0, 0.54);
  --mat-expansion-header-indicator-color: rgba(0, 0, 0, 0.54);
}

html {
  --mat-expansion-header-collapsed-state-height: 48px;
  --mat-expansion-header-expanded-state-height: 64px;
}

html {
  --mat-expansion-header-text-font: Visuelt-Bold, arial, sans-serif;
  --mat-expansion-header-text-size: 16px;
  --mat-expansion-header-text-weight: 400;
  --mat-expansion-header-text-line-height: inherit;
  --mat-expansion-header-text-tracking: inherit;
  --mat-expansion-container-text-font: Inter-Regular, arial, sans-serif;
  --mat-expansion-container-text-line-height: 20px;
  --mat-expansion-container-text-size: 14px;
  --mat-expansion-container-text-tracking: normal;
  --mat-expansion-container-text-weight: 500;
}

.mat-mdc-standard-chip {
  --mdc-chip-container-shape-family: rounded;
  --mdc-chip-container-shape-radius: 16px 16px 16px 16px;
  --mdc-chip-with-avatar-avatar-shape-family: rounded;
  --mdc-chip-with-avatar-avatar-shape-radius: 14px 14px 14px 14px;
  --mdc-chip-with-avatar-avatar-size: 28px;
  --mdc-chip-with-icon-icon-size: 18px;
}

.mat-mdc-standard-chip {
  --mdc-chip-disabled-label-text-color: #212121;
  --mdc-chip-elevated-container-color: #e0e0e0;
  --mdc-chip-elevated-disabled-container-color: #e0e0e0;
  --mdc-chip-focus-state-layer-color: black;
  --mdc-chip-focus-state-layer-opacity: 0.12;
  --mdc-chip-label-text-color: #212121;
  --mdc-chip-with-icon-icon-color: #212121;
  --mdc-chip-with-icon-disabled-icon-color: #212121;
  --mdc-chip-with-icon-selected-icon-color: #212121;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: #212121;
  --mdc-chip-with-trailing-icon-trailing-icon-color: #212121;
}
.mat-mdc-standard-chip.mat-mdc-chip-selected.mat-primary, .mat-mdc-standard-chip.mat-mdc-chip-highlighted.mat-primary {
  --mdc-chip-disabled-label-text-color: white;
  --mdc-chip-elevated-container-color: #28303a;
  --mdc-chip-elevated-disabled-container-color: #28303a;
  --mdc-chip-focus-state-layer-color: black;
  --mdc-chip-focus-state-layer-opacity: 0.12;
  --mdc-chip-label-text-color: white;
  --mdc-chip-with-icon-icon-color: white;
  --mdc-chip-with-icon-disabled-icon-color: white;
  --mdc-chip-with-icon-selected-icon-color: white;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: white;
  --mdc-chip-with-trailing-icon-trailing-icon-color: white;
}
.mat-mdc-standard-chip.mat-mdc-chip-selected.mat-accent, .mat-mdc-standard-chip.mat-mdc-chip-highlighted.mat-accent {
  --mdc-chip-disabled-label-text-color: white;
  --mdc-chip-elevated-container-color: #fc1154;
  --mdc-chip-elevated-disabled-container-color: #fc1154;
  --mdc-chip-focus-state-layer-color: black;
  --mdc-chip-focus-state-layer-opacity: 0.12;
  --mdc-chip-label-text-color: white;
  --mdc-chip-with-icon-icon-color: white;
  --mdc-chip-with-icon-disabled-icon-color: white;
  --mdc-chip-with-icon-selected-icon-color: white;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: white;
  --mdc-chip-with-trailing-icon-trailing-icon-color: white;
}
.mat-mdc-standard-chip.mat-mdc-chip-selected.mat-warn, .mat-mdc-standard-chip.mat-mdc-chip-highlighted.mat-warn {
  --mdc-chip-disabled-label-text-color: white;
  --mdc-chip-elevated-container-color: #f44336;
  --mdc-chip-elevated-disabled-container-color: #f44336;
  --mdc-chip-focus-state-layer-color: black;
  --mdc-chip-focus-state-layer-opacity: 0.12;
  --mdc-chip-label-text-color: white;
  --mdc-chip-with-icon-icon-color: white;
  --mdc-chip-with-icon-disabled-icon-color: white;
  --mdc-chip-with-icon-selected-icon-color: white;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: white;
  --mdc-chip-with-trailing-icon-trailing-icon-color: white;
}

.mat-mdc-chip.mat-mdc-standard-chip {
  --mdc-chip-container-height: 32px;
}

.mat-mdc-standard-chip {
  --mdc-chip-label-text-font: Inter-Regular, arial, sans-serif;
  --mdc-chip-label-text-line-height: 20px;
  --mdc-chip-label-text-size: 14px;
  --mdc-chip-label-text-tracking: normal;
  --mdc-chip-label-text-weight: 500;
}

html {
  --mat-menu-container-shape: 4px;
}

html {
  --mat-menu-item-label-text-color: rgba(0, 0, 0, 0.87);
  --mat-menu-item-icon-color: rgba(0, 0, 0, 0.87);
  --mat-menu-item-hover-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-menu-item-focus-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-menu-container-color: white;
}

html {
  --mat-menu-item-label-text-font: Inter-Regular, arial, sans-serif;
  --mat-menu-item-label-text-size: 14px;
  --mat-menu-item-label-text-tracking: normal;
  --mat-menu-item-label-text-line-height: 20px;
  --mat-menu-item-label-text-weight: 400;
}

html {
  --mdc-switch-disabled-handle-opacity: 0.38;
  --mdc-switch-disabled-selected-icon-opacity: 0.38;
  --mdc-switch-disabled-track-opacity: 0.12;
  --mdc-switch-disabled-unselected-icon-opacity: 0.38;
  --mdc-switch-handle-height: 20px;
  --mdc-switch-handle-shape: 10px;
  --mdc-switch-handle-width: 20px;
  --mdc-switch-selected-icon-size: 18px;
  --mdc-switch-track-height: 14px;
  --mdc-switch-track-shape: 7px;
  --mdc-switch-track-width: 36px;
  --mdc-switch-unselected-icon-size: 18px;
  --mdc-switch-selected-focus-state-layer-opacity: 0.12;
  --mdc-switch-selected-hover-state-layer-opacity: 0.04;
  --mdc-switch-selected-pressed-state-layer-opacity: 0.1;
  --mdc-switch-unselected-focus-state-layer-opacity: 0.12;
  --mdc-switch-unselected-hover-state-layer-opacity: 0.04;
  --mdc-switch-unselected-pressed-state-layer-opacity: 0.1;
}

html {
  --mdc-switch-selected-focus-state-layer-color: #242b34;
  --mdc-switch-selected-handle-color: #242b34;
  --mdc-switch-selected-hover-state-layer-color: #242b34;
  --mdc-switch-selected-pressed-state-layer-color: #242b34;
  --mdc-switch-selected-focus-handle-color: #0f1318;
  --mdc-switch-selected-hover-handle-color: #0f1318;
  --mdc-switch-selected-pressed-handle-color: #0f1318;
  --mdc-switch-selected-focus-track-color: #696e75;
  --mdc-switch-selected-hover-track-color: #696e75;
  --mdc-switch-selected-pressed-track-color: #696e75;
  --mdc-switch-selected-track-color: #696e75;
  --mdc-switch-disabled-selected-handle-color: #424242;
  --mdc-switch-disabled-selected-icon-color: #fff;
  --mdc-switch-disabled-selected-track-color: #424242;
  --mdc-switch-disabled-unselected-handle-color: #424242;
  --mdc-switch-disabled-unselected-icon-color: #fff;
  --mdc-switch-disabled-unselected-track-color: #424242;
  --mdc-switch-handle-surface-color: var(--mdc-theme-surface, #fff);
  --mdc-switch-handle-elevation-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  --mdc-switch-handle-shadow-color: black;
  --mdc-switch-disabled-handle-elevation-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
  --mdc-switch-selected-icon-color: #fff;
  --mdc-switch-unselected-focus-handle-color: #212121;
  --mdc-switch-unselected-focus-state-layer-color: #424242;
  --mdc-switch-unselected-focus-track-color: #e0e0e0;
  --mdc-switch-unselected-handle-color: #616161;
  --mdc-switch-unselected-hover-handle-color: #212121;
  --mdc-switch-unselected-hover-state-layer-color: #424242;
  --mdc-switch-unselected-hover-track-color: #e0e0e0;
  --mdc-switch-unselected-icon-color: #fff;
  --mdc-switch-unselected-pressed-handle-color: #212121;
  --mdc-switch-unselected-pressed-state-layer-color: #424242;
  --mdc-switch-unselected-pressed-track-color: #e0e0e0;
  --mdc-switch-unselected-track-color: #e0e0e0;
  --mdc-switch-disabled-label-text-color: rgba(0, 0, 0, 0.38);
}
html .mat-mdc-slide-toggle {
  --mdc-form-field-label-text-color: rgba(0, 0, 0, 0.87);
}
html .mat-mdc-slide-toggle.mat-accent {
  --mdc-switch-selected-focus-state-layer-color: #242b34;
  --mdc-switch-selected-handle-color: #242b34;
  --mdc-switch-selected-hover-state-layer-color: #242b34;
  --mdc-switch-selected-pressed-state-layer-color: #242b34;
  --mdc-switch-selected-focus-handle-color: #0f1318;
  --mdc-switch-selected-hover-handle-color: #0f1318;
  --mdc-switch-selected-pressed-handle-color: #0f1318;
  --mdc-switch-selected-focus-track-color: #696e75;
  --mdc-switch-selected-hover-track-color: #696e75;
  --mdc-switch-selected-pressed-track-color: #696e75;
  --mdc-switch-selected-track-color: #696e75;
}
html .mat-mdc-slide-toggle.mat-warn {
  --mdc-switch-selected-focus-state-layer-color: #e53935;
  --mdc-switch-selected-handle-color: #e53935;
  --mdc-switch-selected-hover-state-layer-color: #e53935;
  --mdc-switch-selected-pressed-state-layer-color: #e53935;
  --mdc-switch-selected-focus-handle-color: #b71c1c;
  --mdc-switch-selected-hover-handle-color: #b71c1c;
  --mdc-switch-selected-pressed-handle-color: #b71c1c;
  --mdc-switch-selected-focus-track-color: #e57373;
  --mdc-switch-selected-hover-track-color: #e57373;
  --mdc-switch-selected-pressed-track-color: #e57373;
  --mdc-switch-selected-track-color: #e57373;
}

html {
  --mdc-switch-state-layer-size: 40px;
}

html .mat-mdc-slide-toggle {
  --mdc-form-field-label-text-font: Inter-Regular, arial, sans-serif;
  --mdc-form-field-label-text-line-height: 20px;
  --mdc-form-field-label-text-size: 14px;
  --mdc-form-field-label-text-tracking: normal;
  --mdc-form-field-label-text-weight: 500;
}

html {
  --mdc-snackbar-container-shape: 4px;
}

html {
  --mdc-snackbar-container-color: #333333;
  --mdc-snackbar-supporting-text-color: rgba(255, 255, 255, 0.87);
  --mat-snack-bar-button-color: #fc1154;
}

html {
  --mdc-snackbar-supporting-text-font: Inter-Regular, arial, sans-serif;
  --mdc-snackbar-supporting-text-line-height: 20px;
  --mdc-snackbar-supporting-text-size: 14px;
  --mdc-snackbar-supporting-text-weight: 500;
}

html {
  --mat-stepper-header-icon-foreground-color: white;
  --mat-stepper-header-selected-state-icon-background-color: #28303a;
  --mat-stepper-header-selected-state-icon-foreground-color: white;
  --mat-stepper-header-done-state-icon-background-color: #28303a;
  --mat-stepper-header-done-state-icon-foreground-color: white;
  --mat-stepper-header-edit-state-icon-background-color: #28303a;
  --mat-stepper-header-edit-state-icon-foreground-color: white;
  --mat-stepper-container-color: white;
  --mat-stepper-line-color: rgba(0, 0, 0, 0.12);
  --mat-stepper-header-hover-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-stepper-header-focus-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-stepper-header-label-text-color: rgba(0, 0, 0, 0.54);
  --mat-stepper-header-optional-label-text-color: rgba(0, 0, 0, 0.54);
  --mat-stepper-header-selected-state-label-text-color: rgba(0, 0, 0, 0.87);
  --mat-stepper-header-error-state-label-text-color: #f44336;
  --mat-stepper-header-icon-background-color: rgba(0, 0, 0, 0.54);
  --mat-stepper-header-error-state-icon-foreground-color: #f44336;
  --mat-stepper-header-error-state-icon-background-color: transparent;
}
html .mat-step-header.mat-accent {
  --mat-stepper-header-icon-foreground-color: white;
  --mat-stepper-header-selected-state-icon-background-color: #fc1154;
  --mat-stepper-header-selected-state-icon-foreground-color: white;
  --mat-stepper-header-done-state-icon-background-color: #fc1154;
  --mat-stepper-header-done-state-icon-foreground-color: white;
  --mat-stepper-header-edit-state-icon-background-color: #fc1154;
  --mat-stepper-header-edit-state-icon-foreground-color: white;
}
html .mat-step-header.mat-warn {
  --mat-stepper-header-icon-foreground-color: white;
  --mat-stepper-header-selected-state-icon-background-color: #f44336;
  --mat-stepper-header-selected-state-icon-foreground-color: white;
  --mat-stepper-header-done-state-icon-background-color: #f44336;
  --mat-stepper-header-done-state-icon-foreground-color: white;
  --mat-stepper-header-edit-state-icon-background-color: #f44336;
  --mat-stepper-header-edit-state-icon-foreground-color: white;
}

html {
  --mat-stepper-header-height: 72px;
}

html {
  --mat-stepper-container-text-font: Inter-Regular, arial, sans-serif;
  --mat-stepper-header-label-text-font: Inter-Regular, arial, sans-serif;
  --mat-stepper-header-label-text-size: 14px;
  --mat-stepper-header-label-text-weight: 500;
  --mat-stepper-header-error-state-label-text-size: 14px;
  --mat-stepper-header-selected-state-label-text-size: 14px;
  --mat-stepper-header-selected-state-label-text-weight: 400;
}

html {
  --mat-datepicker-calendar-date-selected-state-text-color: white;
  --mat-datepicker-calendar-date-selected-state-background-color: #28303a;
  --mat-datepicker-calendar-date-selected-disabled-state-background-color: rgba(40, 48, 58, 0.4);
  --mat-datepicker-calendar-date-today-selected-state-outline-color: white;
  --mat-datepicker-calendar-date-focus-state-background-color: rgba(40, 48, 58, 0.3);
  --mat-datepicker-calendar-date-hover-state-background-color: rgba(40, 48, 58, 0.3);
  --mat-datepicker-toggle-active-state-icon-color: #94989d;
  --mat-datepicker-calendar-date-in-range-state-background-color: rgba(40, 48, 58, 0.2);
  --mat-datepicker-calendar-date-in-comparison-range-state-background-color: rgba(249, 171, 0, 0.2);
  --mat-datepicker-calendar-date-in-overlap-range-state-background-color: #a8dab5;
  --mat-datepicker-calendar-date-in-overlap-range-selected-state-background-color: #46a35e;
  --mat-datepicker-toggle-icon-color: rgba(0, 0, 0, 0.54);
  --mat-datepicker-calendar-body-label-text-color: rgba(0, 0, 0, 0.54);
  --mat-datepicker-calendar-period-button-icon-color: rgba(0, 0, 0, 0.54);
  --mat-datepicker-calendar-navigation-button-icon-color: rgba(0, 0, 0, 0.54);
  --mat-datepicker-calendar-header-divider-color: rgba(0, 0, 0, 0.12);
  --mat-datepicker-calendar-header-text-color: rgba(0, 0, 0, 0.54);
  --mat-datepicker-calendar-date-today-outline-color: rgba(0, 0, 0, 0.38);
  --mat-datepicker-calendar-date-today-disabled-state-outline-color: rgba(0, 0, 0, 0.18);
  --mat-datepicker-calendar-date-text-color: rgba(0, 0, 0, 0.87);
  --mat-datepicker-calendar-date-outline-color: transparent;
  --mat-datepicker-calendar-date-disabled-state-text-color: rgba(0, 0, 0, 0.38);
  --mat-datepicker-calendar-date-preview-state-outline-color: rgba(0, 0, 0, 0.24);
  --mat-datepicker-range-input-separator-color: rgba(0, 0, 0, 0.87);
  --mat-datepicker-range-input-disabled-state-separator-color: rgba(0, 0, 0, 0.38);
  --mat-datepicker-range-input-disabled-state-text-color: rgba(0, 0, 0, 0.38);
  --mat-datepicker-calendar-container-background-color: white;
  --mat-datepicker-calendar-container-text-color: rgba(0, 0, 0, 0.87);
}

.mat-datepicker-content.mat-accent {
  --mat-datepicker-calendar-date-selected-state-text-color: white;
  --mat-datepicker-calendar-date-selected-state-background-color: #fc1154;
  --mat-datepicker-calendar-date-selected-disabled-state-background-color: rgba(252, 17, 84, 0.4);
  --mat-datepicker-calendar-date-today-selected-state-outline-color: white;
  --mat-datepicker-calendar-date-focus-state-background-color: rgba(252, 17, 84, 0.3);
  --mat-datepicker-calendar-date-hover-state-background-color: rgba(252, 17, 84, 0.3);
  --mat-datepicker-calendar-date-in-range-state-background-color: rgba(252, 17, 84, 0.2);
  --mat-datepicker-calendar-date-in-comparison-range-state-background-color: rgba(249, 171, 0, 0.2);
  --mat-datepicker-calendar-date-in-overlap-range-state-background-color: #a8dab5;
  --mat-datepicker-calendar-date-in-overlap-range-selected-state-background-color: #46a35e;
}
.mat-datepicker-content.mat-warn {
  --mat-datepicker-calendar-date-selected-state-text-color: white;
  --mat-datepicker-calendar-date-selected-state-background-color: #f44336;
  --mat-datepicker-calendar-date-selected-disabled-state-background-color: rgba(244, 67, 54, 0.4);
  --mat-datepicker-calendar-date-today-selected-state-outline-color: white;
  --mat-datepicker-calendar-date-focus-state-background-color: rgba(244, 67, 54, 0.3);
  --mat-datepicker-calendar-date-hover-state-background-color: rgba(244, 67, 54, 0.3);
  --mat-datepicker-calendar-date-in-range-state-background-color: rgba(244, 67, 54, 0.2);
  --mat-datepicker-calendar-date-in-comparison-range-state-background-color: rgba(249, 171, 0, 0.2);
  --mat-datepicker-calendar-date-in-overlap-range-state-background-color: #a8dab5;
  --mat-datepicker-calendar-date-in-overlap-range-selected-state-background-color: #46a35e;
}

.mat-datepicker-toggle-active.mat-accent {
  --mat-datepicker-toggle-active-state-icon-color: #fc1154;
}
.mat-datepicker-toggle-active.mat-warn {
  --mat-datepicker-toggle-active-state-icon-color: #f44336;
}

.mat-calendar-controls .mat-mdc-icon-button.mat-mdc-button-base {
  --mdc-icon-button-state-layer-size: 40px;
  width: var(--mdc-icon-button-state-layer-size);
  height: var(--mdc-icon-button-state-layer-size);
  padding: 8px;
}
.mat-calendar-controls .mat-mdc-icon-button.mat-mdc-button-base .mat-mdc-button-touch-target {
  display: none;
}

html {
  --mat-datepicker-calendar-text-font: Inter-Regular, arial, sans-serif;
  --mat-datepicker-calendar-text-size: 13px;
  --mat-datepicker-calendar-body-label-text-size: 14px;
  --mat-datepicker-calendar-body-label-text-weight: 500;
  --mat-datepicker-calendar-period-button-text-size: 14px;
  --mat-datepicker-calendar-period-button-text-weight: 500;
  --mat-datepicker-calendar-header-text-size: 11px;
  --mat-datepicker-calendar-header-text-weight: 400;
}

html {
  --mdc-filled-text-field-label-text-font: Inter-Regular, arial, sans-serif;
  --mdc-filled-text-field-label-text-size: 16px;
  --mdc-filled-text-field-label-text-tracking: normal;
  --mdc-filled-text-field-label-text-weight: 400;
  --mdc-outlined-text-field-label-text-font: Inter-Regular, arial, sans-serif;
  --mdc-outlined-text-field-label-text-size: 16px;
  --mdc-outlined-text-field-label-text-tracking: normal;
  --mdc-outlined-text-field-label-text-weight: 400;
  --mat-form-field-container-text-font: Inter-Regular, arial, sans-serif;
  --mat-form-field-container-text-line-height: 20px;
  --mat-form-field-container-text-size: 16px;
  --mat-form-field-container-text-tracking: normal;
  --mat-form-field-container-text-weight: 400;
  --mat-form-field-outlined-label-text-populated-size: 16px;
  --mat-form-field-subscript-text-font: Roboto, sans-serif;
  --mat-form-field-subscript-text-line-height: 20px;
  --mat-form-field-subscript-text-size: 12px;
  --mat-form-field-subscript-text-tracking: 0.0333333333em;
  --mat-form-field-subscript-text-weight: 400;
}

html {
  --mat-select-trigger-text-font: Inter-Regular, arial, sans-serif;
  --mat-select-trigger-text-line-height: 20px;
  --mat-select-trigger-text-size: 16px;
  --mat-select-trigger-text-tracking: normal;
  --mat-select-trigger-text-weight: 400;
}

html {
  --mat-option-label-text-font: Inter-Regular, arial, sans-serif;
  --mat-option-label-text-line-height: 20px;
  --mat-option-label-text-size: 16px;
  --mat-option-label-text-tracking: normal;
  --mat-option-label-text-weight: 400;
}

.mat-mdc-tab-header {
  --mat-tab-header-label-text-font: Inter-Regular, arial, sans-serif;
  --mat-tab-header-label-text-size: 14px;
  --mat-tab-header-label-text-tracking: normal;
  --mat-tab-header-label-text-line-height: 20px;
  --mat-tab-header-label-text-weight: 400;
}

html {
  --mat-table-header-headline-font: Roboto, sans-serif;
  --mat-table-header-headline-line-height: 22px;
  --mat-table-header-headline-size: 14px;
  --mat-table-header-headline-weight: 500;
  --mat-table-header-headline-tracking: 0.0071428571em;
  --mat-table-row-item-label-text-font: SF-Mono-Regular, arial, sans-serif;
  --mat-table-row-item-label-text-line-height: 20px;
  --mat-table-row-item-label-text-size: 14px;
  --mat-table-row-item-label-text-weight: 400;
  --mat-table-row-item-label-text-tracking: normal;
  --mat-table-footer-supporting-text-font: SF-Mono-Regular, arial, sans-serif;
  --mat-table-footer-supporting-text-line-height: 20px;
  --mat-table-footer-supporting-text-size: 14px;
  --mat-table-footer-supporting-text-weight: 400;
  --mat-table-footer-supporting-text-tracking: normal;
}

.mat-h1,
.mat-headline-5,
.mat-typography .mat-h1,
.mat-typography .mat-headline-5,
.mat-typography h1 {
  font: 500 60px / 72px "Visuelt-Bold", "arial", sans-serif;
  letter-spacing: normal;
  margin: 0 0 16px;
}

.mat-h2,
.mat-headline-6,
.mat-typography .mat-h2,
.mat-typography .mat-headline-6,
.mat-typography h2 {
  font: 500 48px / 60px "Visuelt-Bold", "arial", sans-serif;
  letter-spacing: normal;
  margin: 0 0 16px;
}

.mat-h3,
.mat-subtitle-1,
.mat-typography .mat-h3,
.mat-typography .mat-subtitle-1,
.mat-typography h3 {
  font: 500 20px / 24px "Visuelt-Bold", "arial", sans-serif;
  letter-spacing: normal;
  margin: 0 0 16px;
}

.mat-h4,
.mat-body-1,
.mat-typography .mat-h4,
.mat-typography .mat-body-1,
.mat-typography h4 {
  font: 400 14px / 20px "Inter-Regular", "arial", sans-serif;
  letter-spacing: normal;
  margin: 0 0 16px;
}

.mat-h5,
.mat-typography .mat-h5,
.mat-typography h5 {
  font: 500 calc(14px * 0.83) / 20px "Inter-Regular", "arial", sans-serif;
  margin: 0 0 12px;
}

.mat-h6,
.mat-typography .mat-h6,
.mat-typography h6 {
  font: 500 calc(14px * 0.67) / 20px "Inter-Regular", "arial", sans-serif;
  margin: 0 0 12px;
}

.mat-body-strong,
.mat-subtitle-2,
.mat-typography .mat-body-strong,
.mat-typography .mat-subtitle-2 {
  font: 400 16px / 20px "Visuelt-Bold", "arial", sans-serif;
  letter-spacing: normal;
}

.mat-body,
.mat-body-2,
.mat-typography .mat-body,
.mat-typography .mat-body-2,
.mat-typography {
  font: 500 14px / 20px "Inter-Regular", "arial", sans-serif;
  letter-spacing: normal;
}
.mat-body p,
.mat-body-2 p,
.mat-typography .mat-body p,
.mat-typography .mat-body-2 p,
.mat-typography p {
  margin: 0 0 12px;
}

.mat-small,
.mat-caption,
.mat-typography .mat-small,
.mat-typography .mat-caption {
  font: 400 12px / 20px Roboto, sans-serif;
  letter-spacing: 0.0333333333em;
}

.mat-headline-1,
.mat-typography .mat-headline-1 {
  font: 300 96px / 96px Roboto, sans-serif;
  letter-spacing: -0.015625em;
  margin: 0 0 56px;
}

.mat-headline-2,
.mat-typography .mat-headline-2 {
  font: 300 60px / 60px Roboto, sans-serif;
  letter-spacing: -0.0083333333em;
  margin: 0 0 64px;
}

.mat-headline-3,
.mat-typography .mat-headline-3 {
  font: 400 48px / 50px Roboto, sans-serif;
  letter-spacing: normal;
  margin: 0 0 64px;
}

.mat-headline-4,
.mat-typography .mat-headline-4 {
  font: 400 34px / 40px Roboto, sans-serif;
  letter-spacing: 0.0073529412em;
  margin: 0 0 64px;
}

plan-button[disabled] {
  background-color: #e60c4a !important;
  color: rgba(0, 0, 0, 0.38) !important;
}
plan-button[disabled] span {
  color: rgba(0, 0, 0, 0.38) !important;
}

.account-layout-active-row mat-icon,
.account-layout-active-row .nav-item-text {
  color: #fc1154 !important;
}

.no-hover-effect.mat-mdc-nav-list .mat-mdc-list-item {
  /* stylelint-disable-next-line selector-class-pattern */
}
.no-hover-effect.mat-mdc-nav-list .mat-mdc-list-item:hover, .no-hover-effect.mat-mdc-nav-list .mat-mdc-list-item:focus {
  background-color: transparent !important;
}
.no-hover-effect.mat-mdc-nav-list .mat-mdc-list-item .mdc-list-item__content {
  padding-right: 0;
}
.no-hover-effect.mat-mdc-nav-list .mat-mdc-list-item .mdc-list-item__content .mat-mdc-list-item-ripple.mat-ripple {
  display: none;
}
.no-hover-effect.mat-mdc-nav-list .mat-mdc-list-item .mdc-list-item__content .mat-mdc-button {
  height: 100%;
  border-radius: 4px !important;
}
.no-hover-effect.mat-mdc-nav-list .mat-mdc-list-item .mdc-list-item__content .mat-mdc-button .mat-mdc-button-persistent-ripple::before {
  background-color: transparent;
}
.no-hover-effect.mat-mdc-nav-list .mat-mdc-list-item .mdc-list-item__content .mat-mdc-button mat-icon {
  color: #9295A3;
}

mat-list-item .mat-mdc-button {
  margin-left: -10px;
  padding-left: 10px !important;
}

.sublist .mat-mdc-expansion-panel {
  box-shadow: none;
}
.sublist .mat-expansion-panel-body {
  padding: 0 0 0 40px;
}
.sublist .mat-expansion-panel-body .mat-mdc-nav-list {
  padding-top: 0;
}
.sublist .mat-expansion-panel-body .mat-mdc-nav-list .mat-mdc-list-item .mat-mdc-button {
  color: #6D7184;
  width: 300px;
  text-align: left;
  font-size: 12px;
}

button.mat-mdc-raised-button,
button.mat-mdc-button {
  font-family: "Inter-Medium", "arial", sans-serif;
  border-radius: 98px;
  padding: 0 20px;
  letter-spacing: normal;
}

button.mat-mdc-raised-button,
.mat-toolbar-row .mat-mdc-button {
  box-shadow: none;
}
button.mat-mdc-raised-button:hover,
.mat-toolbar-row .mat-mdc-button:hover {
  box-shadow: none;
}
button.mat-mdc-raised-button:not(:disabled, .mat-accent, .mat-warn, .delete-btn),
.mat-toolbar-row .mat-mdc-button:not(:disabled, .mat-accent, .mat-warn, .delete-btn) {
  color: #6d7184;
}
button.mat-mdc-raised-button:not(:disabled, .mat-accent, .mat-warn),
.mat-toolbar-row .mat-mdc-button:not(:disabled, .mat-accent, .mat-warn) {
  background: white;
  border: 1px solid #dbdce0;
}
button.mat-mdc-raised-button:not(:disabled, .mat-accent, .mat-warn):hover,
.mat-toolbar-row .mat-mdc-button:not(:disabled, .mat-accent, .mat-warn):hover {
  background: #e6e7ea;
}
button.mat-mdc-raised-button:not(:disabled, .mat-accent, .mat-warn):focus,
.mat-toolbar-row .mat-mdc-button:not(:disabled, .mat-accent, .mat-warn):focus {
  box-shadow: 0 0 0 2px #FFF, 0 0 0 4px #fc1154;
}
button.mat-mdc-raised-button.mat-accent,
.mat-toolbar-row .mat-mdc-button.mat-accent {
  background: radial-gradient(100% 100% at 50.31% 0%, #fc1154 0%, #fc1154 100%);
  color: white;
}
button.mat-mdc-raised-button.mat-accent:hover,
.mat-toolbar-row .mat-mdc-button.mat-accent:hover {
  background: radial-gradient(100% 100% at 50.31% 0%, #e60c4a 0%, #e60c4a 100%);
}
button.mat-mdc-raised-button:disabled,
.mat-toolbar-row .mat-mdc-button:disabled {
  background: #dbdce0;
  color: #9295a3;
  cursor: auto;
}
button.mat-mdc-raised-button.delete-btn,
.mat-toolbar-row .mat-mdc-button.delete-btn {
  color: #B80000;
}
button.mat-mdc-raised-button.margin-top,
.mat-toolbar-row .mat-mdc-button.margin-top {
  margin-top: 8px;
}

button.mat-mdc-icon-button {
  color: #9295a3;
  border-radius: 6px;
}
button.mat-mdc-icon-button:hover {
  background: #e6e7ea;
}
button.mat-mdc-icon-button:focus {
  box-shadow: 0 0 0 4px #FFF;
}
button.mat-mdc-icon-button.small-icon-btn {
  width: 24px;
  height: 24px;
  padding: 0;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}
button.mat-mdc-icon-button.small-icon-btn > *[role=img] {
  width: 20px;
  height: 20px;
  font-size: 20px;
}

.steps-qp {
  height: 2px;
}
.steps-qp.current {
  fill: #fc1154;
  stroke: #fc1154;
}
.steps-qp.inactive {
  fill: #fff;
}
.steps-qp.finished {
  fill: #fff;
  stroke: #fc1154;
}

.progress-check {
  position: relative;
  top: -78px;
  left: 48px;
  font-family: "Material Icons";
}
.progress-check::before {
  display: block;
  content: "check";
  color: #fc1154;
}

.mat-app-background {
  background-color: #f1f1f3;
}

/* --- Header --- */
.header.mat-toolbar,
.header-logo.mat-toolbar {
  background-color: white;
}

/* --- Transaction Tables --- */
/* --- Payments, Payouts, Saved Cards, Subscriptions --- */
.mat-toolbar-row,
.mat-toolbar.top-toolbar {
  background-color: white;
}

/* --- Acquirer List --- */
.mdc-list-item.acquirer-success {
  background-color: #e8effc;
}

/* --- Links --- */
a,
.link,
.no-results-link {
  color: #fc1154;
}

/* --- Input --- */
.search-field-mat .search-widget-mat input,
.search-field-mat .search-widget input,
.search-field-large .search-widget-mat input,
.search-field-large .search-widget input {
  background: whitesmoke;
}
.search-field-mat .search-widget-mat input:focus, .search-field-mat .search-widget-mat input:focus-visible,
.search-field-mat .search-widget input:focus,
.search-field-mat .search-widget input:focus-visible,
.search-field-large .search-widget-mat input:focus,
.search-field-large .search-widget-mat input:focus-visible,
.search-field-large .search-widget input:focus,
.search-field-large .search-widget input:focus-visible {
  border-color: #008fff !important;
  outline: none;
}
.search-field-mat .search-widget-mat .search-options,
.search-field-mat .search-widget .search-options,
.search-field-large .search-widget-mat .search-options,
.search-field-large .search-widget .search-options {
  border-width: 1px 0 1px 1px;
  border-style: solid;
  border-color: #008fff;
}
.search-field-mat .search-widget-mat .search-options .search-option,
.search-field-mat .search-widget .search-options .search-option,
.search-field-large .search-widget-mat .search-options .search-option,
.search-field-large .search-widget .search-options .search-option {
  border-right: 1px solid #008fff;
  background-color: whitesmoke;
}
.search-field-mat .search-widget-mat .search-options .search-option.active,
.search-field-mat .search-widget .search-options .search-option.active,
.search-field-large .search-widget-mat .search-options .search-option.active,
.search-field-large .search-widget .search-options .search-option.active {
  color: #008fff !important;
}

/* ---Statistics--- */
.mat-mdc-tab-group {
  --mdc-tab-indicator-active-indicator-color: #fc1154;
  --mat-tab-header-active-label-text-color: #fc1154;
  --mat-tab-header-active-ripple-color: #fc1154;
  --mat-tab-header-active-focus-label-text-color: #fc1154;
  --mat-tab-header-active-hover-label-text-color: #fc1154;
  --mat-tab-header-active-focus-indicator-color: #fc1154;
  --mat-tab-header-active-hover-indicator-color: #fc1154;
}

.dark-theme {
  --mat-ripple-color: rgba(255, 255, 255, 0.1);
  --mat-option-selected-state-label-text-color: #28303a;
  --mat-option-label-text-color: white;
  --mat-option-hover-state-layer-color: rgba(255, 255, 255, 0.08);
  --mat-option-focus-state-layer-color: rgba(255, 255, 255, 0.08);
  --mat-option-selected-state-layer-color: rgba(255, 255, 255, 0.08);
  --mat-optgroup-label-text-color: white;
  --mat-full-pseudo-checkbox-selected-icon-color: white;
  --mat-full-pseudo-checkbox-selected-checkmark-color: #303030;
  --mat-full-pseudo-checkbox-unselected-icon-color: rgba(255, 255, 255, 0.7);
  --mat-full-pseudo-checkbox-disabled-selected-checkmark-color: #303030;
  --mat-full-pseudo-checkbox-disabled-unselected-icon-color: #686868;
  --mat-full-pseudo-checkbox-disabled-selected-icon-color: #686868;
  --mat-minimal-pseudo-checkbox-selected-checkmark-color: white;
  --mat-minimal-pseudo-checkbox-disabled-selected-checkmark-color: #686868;
  --mat-app-background-color: #303030;
  --mat-app-text-color: white;
  --mdc-text-button-label-text-color: #fff;
  --mdc-text-button-disabled-label-text-color: rgba(255, 255, 255, 0.5);
  --mat-text-button-state-layer-color: #fff;
  --mat-text-button-disabled-state-layer-color: #fff;
  --mat-text-button-ripple-color: rgba(255, 255, 255, 0.1);
  --mat-text-button-hover-state-layer-opacity: 0.08;
  --mat-text-button-focus-state-layer-opacity: 0.24;
  --mat-text-button-pressed-state-layer-opacity: 0.24;
  --mdc-filled-button-container-color: #424242;
  --mdc-filled-button-label-text-color: #fff;
  --mdc-filled-button-disabled-container-color: rgba(255, 255, 255, 0.12);
  --mdc-filled-button-disabled-label-text-color: rgba(255, 255, 255, 0.5);
  --mat-filled-button-state-layer-color: #fff;
  --mat-filled-button-disabled-state-layer-color: #fff;
  --mat-filled-button-ripple-color: rgba(255, 255, 255, 0.1);
  --mat-filled-button-hover-state-layer-opacity: 0.08;
  --mat-filled-button-focus-state-layer-opacity: 0.24;
  --mat-filled-button-pressed-state-layer-opacity: 0.24;
  --mdc-protected-button-container-color: #424242;
  --mdc-protected-button-label-text-color: #fff;
  --mdc-protected-button-disabled-container-color: rgba(255, 255, 255, 0.12);
  --mdc-protected-button-disabled-label-text-color: rgba(255, 255, 255, 0.5);
  --mdc-protected-button-container-elevation-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
  --mdc-protected-button-disabled-container-elevation-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
  --mdc-protected-button-focus-container-elevation-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  --mdc-protected-button-hover-container-elevation-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  --mdc-protected-button-pressed-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mdc-protected-button-container-shadow-color: #000;
  --mat-protected-button-state-layer-color: #fff;
  --mat-protected-button-disabled-state-layer-color: #fff;
  --mat-protected-button-ripple-color: rgba(255, 255, 255, 0.1);
  --mat-protected-button-hover-state-layer-opacity: 0.08;
  --mat-protected-button-focus-state-layer-opacity: 0.24;
  --mat-protected-button-pressed-state-layer-opacity: 0.24;
  --mdc-outlined-button-disabled-outline-color: rgba(255, 255, 255, 0.12);
  --mdc-outlined-button-disabled-label-text-color: rgba(255, 255, 255, 0.5);
  --mdc-outlined-button-label-text-color: #fff;
  --mdc-outlined-button-outline-color: rgba(255, 255, 255, 0.12);
  --mat-outlined-button-state-layer-color: #fff;
  --mat-outlined-button-disabled-state-layer-color: #fff;
  --mat-outlined-button-ripple-color: rgba(255, 255, 255, 0.1);
  --mat-outlined-button-hover-state-layer-opacity: 0.08;
  --mat-outlined-button-focus-state-layer-opacity: 0.24;
  --mat-outlined-button-pressed-state-layer-opacity: 0.24;
  --mdc-list-list-item-label-text-color: white;
  --mdc-list-list-item-supporting-text-color: rgba(255, 255, 255, 0.7);
  --mdc-list-list-item-leading-icon-color: rgba(255, 255, 255, 0.5);
  --mdc-list-list-item-trailing-supporting-text-color: rgba(255, 255, 255, 0.5);
  --mdc-list-list-item-trailing-icon-color: rgba(255, 255, 255, 0.5);
  --mdc-list-list-item-selected-trailing-icon-color: rgba(255, 255, 255, 0.5);
  --mdc-list-list-item-disabled-label-text-color: white;
  --mdc-list-list-item-disabled-leading-icon-color: white;
  --mdc-list-list-item-disabled-trailing-icon-color: white;
  --mdc-list-list-item-hover-label-text-color: white;
  --mdc-list-list-item-hover-leading-icon-color: rgba(255, 255, 255, 0.5);
  --mdc-list-list-item-hover-trailing-icon-color: rgba(255, 255, 255, 0.5);
  --mdc-list-list-item-focus-label-text-color: white;
  --mdc-list-list-item-hover-state-layer-color: white;
  --mdc-list-list-item-hover-state-layer-opacity: 0.08;
  --mdc-list-list-item-focus-state-layer-color: white;
  --mdc-list-list-item-focus-state-layer-opacity: 0.24;
  --mdc-filled-text-field-caret-color: #28303a;
  --mdc-filled-text-field-focus-active-indicator-color: #28303a;
  --mdc-filled-text-field-focus-label-text-color: rgba(40, 48, 58, 0.87);
  --mdc-filled-text-field-container-color: #4a4a4a;
  --mdc-filled-text-field-disabled-container-color: #464646;
  --mdc-filled-text-field-label-text-color: rgba(255, 255, 255, 0.6);
  --mdc-filled-text-field-hover-label-text-color: rgba(255, 255, 255, 0.6);
  --mdc-filled-text-field-disabled-label-text-color: rgba(255, 255, 255, 0.38);
  --mdc-filled-text-field-input-text-color: rgba(255, 255, 255, 0.87);
  --mdc-filled-text-field-disabled-input-text-color: rgba(255, 255, 255, 0.38);
  --mdc-filled-text-field-input-text-placeholder-color: rgba(255, 255, 255, 0.6);
  --mdc-filled-text-field-error-hover-label-text-color: #f44336;
  --mdc-filled-text-field-error-focus-label-text-color: #f44336;
  --mdc-filled-text-field-error-label-text-color: #f44336;
  --mdc-filled-text-field-error-caret-color: #f44336;
  --mdc-filled-text-field-active-indicator-color: rgba(255, 255, 255, 0.42);
  --mdc-filled-text-field-disabled-active-indicator-color: rgba(255, 255, 255, 0.06);
  --mdc-filled-text-field-hover-active-indicator-color: rgba(255, 255, 255, 0.87);
  --mdc-filled-text-field-error-active-indicator-color: #f44336;
  --mdc-filled-text-field-error-focus-active-indicator-color: #f44336;
  --mdc-filled-text-field-error-hover-active-indicator-color: #f44336;
  --mdc-outlined-text-field-caret-color: #28303a;
  --mdc-outlined-text-field-focus-outline-color: #28303a;
  --mdc-outlined-text-field-focus-label-text-color: rgba(40, 48, 58, 0.87);
  --mdc-outlined-text-field-label-text-color: rgba(255, 255, 255, 0.6);
  --mdc-outlined-text-field-hover-label-text-color: rgba(255, 255, 255, 0.6);
  --mdc-outlined-text-field-disabled-label-text-color: rgba(255, 255, 255, 0.38);
  --mdc-outlined-text-field-input-text-color: rgba(255, 255, 255, 0.87);
  --mdc-outlined-text-field-disabled-input-text-color: rgba(255, 255, 255, 0.38);
  --mdc-outlined-text-field-input-text-placeholder-color: rgba(255, 255, 255, 0.6);
  --mdc-outlined-text-field-error-caret-color: #f44336;
  --mdc-outlined-text-field-error-focus-label-text-color: #f44336;
  --mdc-outlined-text-field-error-label-text-color: #f44336;
  --mdc-outlined-text-field-error-hover-label-text-color: #f44336;
  --mdc-outlined-text-field-outline-color: rgba(255, 255, 255, 0.38);
  --mdc-outlined-text-field-disabled-outline-color: rgba(255, 255, 255, 0.06);
  --mdc-outlined-text-field-hover-outline-color: rgba(255, 255, 255, 0.87);
  --mdc-outlined-text-field-error-focus-outline-color: #f44336;
  --mdc-outlined-text-field-error-hover-outline-color: #f44336;
  --mdc-outlined-text-field-error-outline-color: #f44336;
  --mat-form-field-focus-select-arrow-color: rgba(40, 48, 58, 0.87);
  --mat-form-field-disabled-input-text-placeholder-color: rgba(255, 255, 255, 0.38);
  --mat-form-field-state-layer-color: rgba(255, 255, 255, 0.87);
  --mat-form-field-error-text-color: #f44336;
  --mat-form-field-select-option-text-color: rgba(0, 0, 0, 0.87);
  --mat-form-field-select-disabled-option-text-color: rgba(0, 0, 0, 0.38);
  --mat-form-field-leading-icon-color: unset;
  --mat-form-field-disabled-leading-icon-color: unset;
  --mat-form-field-trailing-icon-color: unset;
  --mat-form-field-disabled-trailing-icon-color: unset;
  --mat-form-field-error-focus-trailing-icon-color: unset;
  --mat-form-field-error-hover-trailing-icon-color: unset;
  --mat-form-field-error-trailing-icon-color: unset;
  --mat-form-field-enabled-select-arrow-color: rgba(255, 255, 255, 0.54);
  --mat-form-field-disabled-select-arrow-color: rgba(255, 255, 255, 0.38);
  --mat-form-field-hover-state-layer-opacity: 0.08;
  --mat-form-field-focus-state-layer-opacity: 0.24;
  --mdc-elevated-card-container-color: #424242;
  --mdc-elevated-card-container-elevation: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  --mdc-outlined-card-container-color: #424242;
  --mdc-outlined-card-outline-color: rgba(255, 255, 255, 0.12);
  --mdc-outlined-card-container-elevation: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
  --mat-card-subtitle-text-color: rgba(255, 255, 255, 0.7);
  --mat-icon-color: inherit;
  --mat-toolbar-container-background-color: #212121;
  --mat-toolbar-container-text-color: white;
  --mat-table-background-color: #424242;
  --mat-table-header-headline-color: white;
  --mat-table-row-item-label-text-color: white;
  --mat-table-row-item-outline-color: rgba(255, 255, 255, 0.12);
  --mat-select-panel-background-color: #424242;
  --mat-select-enabled-trigger-text-color: rgba(255, 255, 255, 0.87);
  --mat-select-disabled-trigger-text-color: rgba(255, 255, 255, 0.38);
  --mat-select-placeholder-text-color: rgba(255, 255, 255, 0.6);
  --mat-select-enabled-arrow-color: rgba(255, 255, 255, 0.54);
  --mat-select-disabled-arrow-color: rgba(255, 255, 255, 0.38);
  --mat-select-focused-arrow-color: rgba(40, 48, 58, 0.87);
  --mat-select-invalid-arrow-color: rgba(244, 67, 54, 0.87);
  --mat-paginator-container-text-color: rgba(255, 255, 255, 0.87);
  --mat-paginator-container-background-color: #424242;
  --mat-paginator-enabled-icon-color: rgba(255, 255, 255, 0.54);
  --mat-paginator-disabled-icon-color: rgba(255, 255, 255, 0.12);
  --mdc-circular-progress-active-indicator-color: #28303a;
  --mdc-dialog-container-color: #424242;
  --mdc-dialog-subhead-color: rgba(255, 255, 255, 0.87);
  --mdc-dialog-supporting-text-color: rgba(255, 255, 255, 0.6);
  --mdc-checkbox-disabled-selected-icon-color: rgba(255, 255, 255, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(255, 255, 255, 0.38);
  --mdc-checkbox-selected-checkmark-color: #000;
  --mdc-checkbox-selected-focus-icon-color: white;
  --mdc-checkbox-selected-hover-icon-color: white;
  --mdc-checkbox-selected-icon-color: white;
  --mdc-checkbox-selected-pressed-icon-color: white;
  --mdc-checkbox-unselected-focus-icon-color: #eeeeee;
  --mdc-checkbox-unselected-hover-icon-color: #eeeeee;
  --mdc-checkbox-unselected-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: white;
  --mdc-checkbox-selected-hover-state-layer-color: white;
  --mdc-checkbox-selected-pressed-state-layer-color: white;
  --mdc-checkbox-unselected-focus-state-layer-color: white;
  --mdc-checkbox-unselected-hover-state-layer-color: white;
  --mdc-checkbox-unselected-pressed-state-layer-color: white;
  --mdc-plain-tooltip-container-color: #616161;
  --mdc-plain-tooltip-supporting-text-color: #fff;
  --mat-expansion-container-background-color: #424242;
  --mat-expansion-container-text-color: white;
  --mat-expansion-actions-divider-color: rgba(255, 255, 255, 0.12);
  --mat-expansion-header-hover-state-layer-color: rgba(255, 255, 255, 0.04);
  --mat-expansion-header-focus-state-layer-color: rgba(255, 255, 255, 0.04);
  --mat-expansion-header-disabled-state-text-color: rgba(255, 255, 255, 0.3);
  --mat-expansion-header-text-color: white;
  --mat-expansion-header-description-color: rgba(255, 255, 255, 0.7);
  --mat-expansion-header-indicator-color: rgba(255, 255, 255, 0.7);
  --mat-menu-item-label-text-color: white;
  --mat-menu-item-icon-color: white;
  --mat-menu-item-hover-state-layer-color: rgba(255, 255, 255, 0.08);
  --mat-menu-item-focus-state-layer-color: rgba(255, 255, 255, 0.08);
  --mat-menu-container-color: #424242;
  --mdc-switch-selected-focus-state-layer-color: #696e75;
  --mdc-switch-selected-handle-color: #696e75;
  --mdc-switch-selected-hover-state-layer-color: #696e75;
  --mdc-switch-selected-pressed-state-layer-color: #696e75;
  --mdc-switch-selected-focus-handle-color: #94989d;
  --mdc-switch-selected-hover-handle-color: #94989d;
  --mdc-switch-selected-pressed-handle-color: #94989d;
  --mdc-switch-selected-focus-track-color: #242b34;
  --mdc-switch-selected-hover-track-color: #242b34;
  --mdc-switch-selected-pressed-track-color: #242b34;
  --mdc-switch-selected-track-color: #242b34;
  --mdc-switch-disabled-selected-handle-color: #000;
  --mdc-switch-disabled-selected-icon-color: #212121;
  --mdc-switch-disabled-selected-track-color: #f5f5f5;
  --mdc-switch-disabled-unselected-handle-color: #000;
  --mdc-switch-disabled-unselected-icon-color: #212121;
  --mdc-switch-disabled-unselected-track-color: #f5f5f5;
  --mdc-switch-handle-surface-color: var(--mdc-theme-surface, #fff);
  --mdc-switch-handle-elevation-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  --mdc-switch-handle-shadow-color: black;
  --mdc-switch-disabled-handle-elevation-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
  --mdc-switch-selected-icon-color: #212121;
  --mdc-switch-unselected-focus-handle-color: #fafafa;
  --mdc-switch-unselected-focus-state-layer-color: #f5f5f5;
  --mdc-switch-unselected-focus-track-color: #616161;
  --mdc-switch-unselected-handle-color: #9e9e9e;
  --mdc-switch-unselected-hover-handle-color: #fafafa;
  --mdc-switch-unselected-hover-state-layer-color: #f5f5f5;
  --mdc-switch-unselected-hover-track-color: #616161;
  --mdc-switch-unselected-icon-color: #212121;
  --mdc-switch-unselected-pressed-handle-color: #fafafa;
  --mdc-switch-unselected-pressed-state-layer-color: #f5f5f5;
  --mdc-switch-unselected-pressed-track-color: #616161;
  --mdc-switch-unselected-track-color: #616161;
  --mdc-switch-disabled-label-text-color: rgba(255, 255, 255, 0.5);
  --mdc-snackbar-container-color: #d9d9d9;
  --mdc-snackbar-supporting-text-color: rgba(66, 66, 66, 0.87);
  --mat-snack-bar-button-color: rgba(0, 0, 0, 0.87);
  --mat-stepper-header-icon-foreground-color: white;
  --mat-stepper-header-selected-state-icon-background-color: #28303a;
  --mat-stepper-header-selected-state-icon-foreground-color: white;
  --mat-stepper-header-done-state-icon-background-color: #28303a;
  --mat-stepper-header-done-state-icon-foreground-color: white;
  --mat-stepper-header-edit-state-icon-background-color: #28303a;
  --mat-stepper-header-edit-state-icon-foreground-color: white;
  --mat-stepper-container-color: #424242;
  --mat-stepper-line-color: rgba(255, 255, 255, 0.12);
  --mat-stepper-header-hover-state-layer-color: rgba(255, 255, 255, 0.04);
  --mat-stepper-header-focus-state-layer-color: rgba(255, 255, 255, 0.04);
  --mat-stepper-header-label-text-color: rgba(255, 255, 255, 0.7);
  --mat-stepper-header-optional-label-text-color: rgba(255, 255, 255, 0.7);
  --mat-stepper-header-selected-state-label-text-color: white;
  --mat-stepper-header-error-state-label-text-color: #f44336;
  --mat-stepper-header-icon-background-color: rgba(255, 255, 255, 0.7);
  --mat-stepper-header-error-state-icon-foreground-color: #f44336;
  --mat-stepper-header-error-state-icon-background-color: transparent;
  --mat-datepicker-calendar-date-selected-state-text-color: white;
  --mat-datepicker-calendar-date-selected-state-background-color: #28303a;
  --mat-datepicker-calendar-date-selected-disabled-state-background-color: rgba(40, 48, 58, 0.4);
  --mat-datepicker-calendar-date-today-selected-state-outline-color: white;
  --mat-datepicker-calendar-date-focus-state-background-color: rgba(40, 48, 58, 0.3);
  --mat-datepicker-calendar-date-hover-state-background-color: rgba(40, 48, 58, 0.3);
  --mat-datepicker-toggle-active-state-icon-color: #94989d;
  --mat-datepicker-calendar-date-in-range-state-background-color: rgba(40, 48, 58, 0.2);
  --mat-datepicker-calendar-date-in-comparison-range-state-background-color: rgba(249, 171, 0, 0.2);
  --mat-datepicker-calendar-date-in-overlap-range-state-background-color: #a8dab5;
  --mat-datepicker-calendar-date-in-overlap-range-selected-state-background-color: #46a35e;
  --mat-datepicker-toggle-icon-color: white;
  --mat-datepicker-calendar-body-label-text-color: rgba(255, 255, 255, 0.7);
  --mat-datepicker-calendar-period-button-icon-color: white;
  --mat-datepicker-calendar-navigation-button-icon-color: white;
  --mat-datepicker-calendar-header-divider-color: rgba(255, 255, 255, 0.12);
  --mat-datepicker-calendar-header-text-color: rgba(255, 255, 255, 0.7);
  --mat-datepicker-calendar-date-today-outline-color: rgba(255, 255, 255, 0.5);
  --mat-datepicker-calendar-date-today-disabled-state-outline-color: rgba(255, 255, 255, 0.3);
  --mat-datepicker-calendar-date-text-color: white;
  --mat-datepicker-calendar-date-outline-color: transparent;
  --mat-datepicker-calendar-date-disabled-state-text-color: rgba(255, 255, 255, 0.5);
  --mat-datepicker-calendar-date-preview-state-outline-color: rgba(255, 255, 255, 0.24);
  --mat-datepicker-range-input-separator-color: white;
  --mat-datepicker-range-input-disabled-state-separator-color: rgba(255, 255, 255, 0.5);
  --mat-datepicker-range-input-disabled-state-text-color: rgba(255, 255, 255, 0.5);
  --mat-datepicker-calendar-container-background-color: #424242;
  --mat-datepicker-calendar-container-text-color: white;
  /* --- Header --- */
  /* --- Transaction Tables --- */
  /* --- Payments, Payouts, Saved Cards, Subscriptions --- */
  /* --- Acquirer List --- */
  /* --- Links --- */
  /* --- Input --- */
  /* ---Statistics--- */
}
.dark-theme .mat-accent {
  --mat-option-selected-state-label-text-color: white;
  --mat-option-label-text-color: white;
  --mat-option-hover-state-layer-color: rgba(255, 255, 255, 0.08);
  --mat-option-focus-state-layer-color: rgba(255, 255, 255, 0.08);
  --mat-option-selected-state-layer-color: rgba(255, 255, 255, 0.08);
}
.dark-theme .mat-warn {
  --mat-option-selected-state-label-text-color: #f44336;
  --mat-option-label-text-color: white;
  --mat-option-hover-state-layer-color: rgba(255, 255, 255, 0.08);
  --mat-option-focus-state-layer-color: rgba(255, 255, 255, 0.08);
  --mat-option-selected-state-layer-color: rgba(255, 255, 255, 0.08);
}
.dark-theme .mat-primary {
  --mat-full-pseudo-checkbox-selected-icon-color: #28303a;
  --mat-full-pseudo-checkbox-selected-checkmark-color: #303030;
  --mat-full-pseudo-checkbox-unselected-icon-color: rgba(255, 255, 255, 0.7);
  --mat-full-pseudo-checkbox-disabled-selected-checkmark-color: #303030;
  --mat-full-pseudo-checkbox-disabled-unselected-icon-color: #686868;
  --mat-full-pseudo-checkbox-disabled-selected-icon-color: #686868;
  --mat-minimal-pseudo-checkbox-selected-checkmark-color: #28303a;
  --mat-minimal-pseudo-checkbox-disabled-selected-checkmark-color: #686868;
}
.dark-theme .mat-accent {
  --mat-full-pseudo-checkbox-selected-icon-color: white;
  --mat-full-pseudo-checkbox-selected-checkmark-color: #303030;
  --mat-full-pseudo-checkbox-unselected-icon-color: rgba(255, 255, 255, 0.7);
  --mat-full-pseudo-checkbox-disabled-selected-checkmark-color: #303030;
  --mat-full-pseudo-checkbox-disabled-unselected-icon-color: #686868;
  --mat-full-pseudo-checkbox-disabled-selected-icon-color: #686868;
  --mat-minimal-pseudo-checkbox-selected-checkmark-color: white;
  --mat-minimal-pseudo-checkbox-disabled-selected-checkmark-color: #686868;
}
.dark-theme .mat-warn {
  --mat-full-pseudo-checkbox-selected-icon-color: #f44336;
  --mat-full-pseudo-checkbox-selected-checkmark-color: #303030;
  --mat-full-pseudo-checkbox-unselected-icon-color: rgba(255, 255, 255, 0.7);
  --mat-full-pseudo-checkbox-disabled-selected-checkmark-color: #303030;
  --mat-full-pseudo-checkbox-disabled-unselected-icon-color: #686868;
  --mat-full-pseudo-checkbox-disabled-selected-icon-color: #686868;
  --mat-minimal-pseudo-checkbox-selected-checkmark-color: #f44336;
  --mat-minimal-pseudo-checkbox-disabled-selected-checkmark-color: #686868;
}
.dark-theme .mat-elevation-z0, .dark-theme .mat-mdc-elevation-specific.mat-elevation-z0 {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.dark-theme .mat-elevation-z1, .dark-theme .mat-mdc-elevation-specific.mat-elevation-z1 {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}
.dark-theme .mat-elevation-z2, .dark-theme .mat-mdc-elevation-specific.mat-elevation-z2 {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.dark-theme .mat-elevation-z3, .dark-theme .mat-mdc-elevation-specific.mat-elevation-z3 {
  box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
}
.dark-theme .mat-elevation-z4, .dark-theme .mat-mdc-elevation-specific.mat-elevation-z4 {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.dark-theme .mat-elevation-z5, .dark-theme .mat-mdc-elevation-specific.mat-elevation-z5 {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 5px 8px 0px rgba(0, 0, 0, 0.14), 0px 1px 14px 0px rgba(0, 0, 0, 0.12);
}
.dark-theme .mat-elevation-z6, .dark-theme .mat-mdc-elevation-specific.mat-elevation-z6 {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}
.dark-theme .mat-elevation-z7, .dark-theme .mat-mdc-elevation-specific.mat-elevation-z7 {
  box-shadow: 0px 4px 5px -2px rgba(0, 0, 0, 0.2), 0px 7px 10px 1px rgba(0, 0, 0, 0.14), 0px 2px 16px 1px rgba(0, 0, 0, 0.12);
}
.dark-theme .mat-elevation-z8, .dark-theme .mat-mdc-elevation-specific.mat-elevation-z8 {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}
.dark-theme .mat-elevation-z9, .dark-theme .mat-mdc-elevation-specific.mat-elevation-z9 {
  box-shadow: 0px 5px 6px -3px rgba(0, 0, 0, 0.2), 0px 9px 12px 1px rgba(0, 0, 0, 0.14), 0px 3px 16px 2px rgba(0, 0, 0, 0.12);
}
.dark-theme .mat-elevation-z10, .dark-theme .mat-mdc-elevation-specific.mat-elevation-z10 {
  box-shadow: 0px 6px 6px -3px rgba(0, 0, 0, 0.2), 0px 10px 14px 1px rgba(0, 0, 0, 0.14), 0px 4px 18px 3px rgba(0, 0, 0, 0.12);
}
.dark-theme .mat-elevation-z11, .dark-theme .mat-mdc-elevation-specific.mat-elevation-z11 {
  box-shadow: 0px 6px 7px -4px rgba(0, 0, 0, 0.2), 0px 11px 15px 1px rgba(0, 0, 0, 0.14), 0px 4px 20px 3px rgba(0, 0, 0, 0.12);
}
.dark-theme .mat-elevation-z12, .dark-theme .mat-mdc-elevation-specific.mat-elevation-z12 {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
}
.dark-theme .mat-elevation-z13, .dark-theme .mat-mdc-elevation-specific.mat-elevation-z13 {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 13px 19px 2px rgba(0, 0, 0, 0.14), 0px 5px 24px 4px rgba(0, 0, 0, 0.12);
}
.dark-theme .mat-elevation-z14, .dark-theme .mat-mdc-elevation-specific.mat-elevation-z14 {
  box-shadow: 0px 7px 9px -4px rgba(0, 0, 0, 0.2), 0px 14px 21px 2px rgba(0, 0, 0, 0.14), 0px 5px 26px 4px rgba(0, 0, 0, 0.12);
}
.dark-theme .mat-elevation-z15, .dark-theme .mat-mdc-elevation-specific.mat-elevation-z15 {
  box-shadow: 0px 8px 9px -5px rgba(0, 0, 0, 0.2), 0px 15px 22px 2px rgba(0, 0, 0, 0.14), 0px 6px 28px 5px rgba(0, 0, 0, 0.12);
}
.dark-theme .mat-elevation-z16, .dark-theme .mat-mdc-elevation-specific.mat-elevation-z16 {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
}
.dark-theme .mat-elevation-z17, .dark-theme .mat-mdc-elevation-specific.mat-elevation-z17 {
  box-shadow: 0px 8px 11px -5px rgba(0, 0, 0, 0.2), 0px 17px 26px 2px rgba(0, 0, 0, 0.14), 0px 6px 32px 5px rgba(0, 0, 0, 0.12);
}
.dark-theme .mat-elevation-z18, .dark-theme .mat-mdc-elevation-specific.mat-elevation-z18 {
  box-shadow: 0px 9px 11px -5px rgba(0, 0, 0, 0.2), 0px 18px 28px 2px rgba(0, 0, 0, 0.14), 0px 7px 34px 6px rgba(0, 0, 0, 0.12);
}
.dark-theme .mat-elevation-z19, .dark-theme .mat-mdc-elevation-specific.mat-elevation-z19 {
  box-shadow: 0px 9px 12px -6px rgba(0, 0, 0, 0.2), 0px 19px 29px 2px rgba(0, 0, 0, 0.14), 0px 7px 36px 6px rgba(0, 0, 0, 0.12);
}
.dark-theme .mat-elevation-z20, .dark-theme .mat-mdc-elevation-specific.mat-elevation-z20 {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 20px 31px 3px rgba(0, 0, 0, 0.14), 0px 8px 38px 7px rgba(0, 0, 0, 0.12);
}
.dark-theme .mat-elevation-z21, .dark-theme .mat-mdc-elevation-specific.mat-elevation-z21 {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 21px 33px 3px rgba(0, 0, 0, 0.14), 0px 8px 40px 7px rgba(0, 0, 0, 0.12);
}
.dark-theme .mat-elevation-z22, .dark-theme .mat-mdc-elevation-specific.mat-elevation-z22 {
  box-shadow: 0px 10px 14px -6px rgba(0, 0, 0, 0.2), 0px 22px 35px 3px rgba(0, 0, 0, 0.14), 0px 8px 42px 7px rgba(0, 0, 0, 0.12);
}
.dark-theme .mat-elevation-z23, .dark-theme .mat-mdc-elevation-specific.mat-elevation-z23 {
  box-shadow: 0px 11px 14px -7px rgba(0, 0, 0, 0.2), 0px 23px 36px 3px rgba(0, 0, 0, 0.14), 0px 9px 44px 8px rgba(0, 0, 0, 0.12);
}
.dark-theme .mat-elevation-z24, .dark-theme .mat-mdc-elevation-specific.mat-elevation-z24 {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
}
.mat-theme-loaded-marker {
  display: none;
}

.dark-theme .mat-mdc-button.mat-primary {
  --mdc-text-button-label-text-color: #28303a;
  --mat-text-button-state-layer-color: #28303a;
  --mat-text-button-ripple-color: rgba(40, 48, 58, 0.1);
}
.dark-theme .mat-mdc-button.mat-accent {
  --mdc-text-button-label-text-color: white;
  --mat-text-button-state-layer-color: white;
  --mat-text-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.dark-theme .mat-mdc-button.mat-warn {
  --mdc-text-button-label-text-color: #f44336;
  --mat-text-button-state-layer-color: #f44336;
  --mat-text-button-ripple-color: rgba(244, 67, 54, 0.1);
}
.dark-theme .mat-mdc-unelevated-button.mat-primary {
  --mdc-filled-button-container-color: #28303a;
  --mdc-filled-button-label-text-color: #fff;
  --mat-filled-button-state-layer-color: #fff;
  --mat-filled-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.dark-theme .mat-mdc-unelevated-button.mat-accent {
  --mdc-filled-button-container-color: white;
  --mdc-filled-button-label-text-color: #000;
  --mat-filled-button-state-layer-color: #000;
  --mat-filled-button-ripple-color: rgba(0, 0, 0, 0.1);
}
.dark-theme .mat-mdc-unelevated-button.mat-warn {
  --mdc-filled-button-container-color: #f44336;
  --mdc-filled-button-label-text-color: #fff;
  --mat-filled-button-state-layer-color: #fff;
  --mat-filled-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.dark-theme .mat-mdc-raised-button.mat-primary {
  --mdc-protected-button-container-color: #28303a;
  --mdc-protected-button-label-text-color: #fff;
  --mat-protected-button-state-layer-color: #fff;
  --mat-protected-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.dark-theme .mat-mdc-raised-button.mat-accent {
  --mdc-protected-button-container-color: white;
  --mdc-protected-button-label-text-color: #000;
  --mat-protected-button-state-layer-color: #000;
  --mat-protected-button-ripple-color: rgba(0, 0, 0, 0.1);
}
.dark-theme .mat-mdc-raised-button.mat-warn {
  --mdc-protected-button-container-color: #f44336;
  --mdc-protected-button-label-text-color: #fff;
  --mat-protected-button-state-layer-color: #fff;
  --mat-protected-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.dark-theme .mat-mdc-outlined-button.mat-primary {
  --mdc-outlined-button-label-text-color: #28303a;
  --mdc-outlined-button-outline-color: rgba(255, 255, 255, 0.12);
  --mat-outlined-button-state-layer-color: #28303a;
  --mat-outlined-button-ripple-color: rgba(40, 48, 58, 0.1);
}
.dark-theme .mat-mdc-outlined-button.mat-accent {
  --mdc-outlined-button-label-text-color: white;
  --mdc-outlined-button-outline-color: rgba(255, 255, 255, 0.12);
  --mat-outlined-button-state-layer-color: white;
  --mat-outlined-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.dark-theme .mat-mdc-outlined-button.mat-warn {
  --mdc-outlined-button-label-text-color: #f44336;
  --mdc-outlined-button-outline-color: rgba(255, 255, 255, 0.12);
  --mat-outlined-button-state-layer-color: #f44336;
  --mat-outlined-button-ripple-color: rgba(244, 67, 54, 0.1);
}
.dark-theme .mdc-list-item__start,
.dark-theme .mdc-list-item__end {
  --mdc-radio-disabled-selected-icon-color: #fff;
  --mdc-radio-disabled-unselected-icon-color: #fff;
  --mdc-radio-unselected-hover-icon-color: #eeeeee;
  --mdc-radio-unselected-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-radio-selected-focus-icon-color: #28303a;
  --mdc-radio-selected-hover-icon-color: #28303a;
  --mdc-radio-selected-icon-color: #28303a;
  --mdc-radio-selected-pressed-icon-color: #28303a;
}
.dark-theme .mat-accent .mdc-list-item__start,
.dark-theme .mat-accent .mdc-list-item__end {
  --mdc-radio-disabled-selected-icon-color: #fff;
  --mdc-radio-disabled-unselected-icon-color: #fff;
  --mdc-radio-unselected-hover-icon-color: #eeeeee;
  --mdc-radio-unselected-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-radio-selected-focus-icon-color: white;
  --mdc-radio-selected-hover-icon-color: white;
  --mdc-radio-selected-icon-color: white;
  --mdc-radio-selected-pressed-icon-color: white;
}
.dark-theme .mat-warn .mdc-list-item__start,
.dark-theme .mat-warn .mdc-list-item__end {
  --mdc-radio-disabled-selected-icon-color: #fff;
  --mdc-radio-disabled-unselected-icon-color: #fff;
  --mdc-radio-unselected-hover-icon-color: #eeeeee;
  --mdc-radio-unselected-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-radio-selected-focus-icon-color: #f44336;
  --mdc-radio-selected-hover-icon-color: #f44336;
  --mdc-radio-selected-icon-color: #f44336;
  --mdc-radio-selected-pressed-icon-color: #f44336;
}
.dark-theme .mat-mdc-list-option {
  --mdc-checkbox-disabled-selected-icon-color: rgba(255, 255, 255, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(255, 255, 255, 0.38);
  --mdc-checkbox-selected-checkmark-color: #fff;
  --mdc-checkbox-selected-focus-icon-color: #28303a;
  --mdc-checkbox-selected-hover-icon-color: #28303a;
  --mdc-checkbox-selected-icon-color: #28303a;
  --mdc-checkbox-selected-pressed-icon-color: #28303a;
  --mdc-checkbox-unselected-focus-icon-color: #eeeeee;
  --mdc-checkbox-unselected-hover-icon-color: #eeeeee;
  --mdc-checkbox-unselected-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #28303a;
  --mdc-checkbox-selected-hover-state-layer-color: #28303a;
  --mdc-checkbox-selected-pressed-state-layer-color: #28303a;
  --mdc-checkbox-unselected-focus-state-layer-color: white;
  --mdc-checkbox-unselected-hover-state-layer-color: white;
  --mdc-checkbox-unselected-pressed-state-layer-color: white;
}
.dark-theme .mat-mdc-list-option.mat-accent {
  --mdc-checkbox-disabled-selected-icon-color: rgba(255, 255, 255, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(255, 255, 255, 0.38);
  --mdc-checkbox-selected-checkmark-color: #000;
  --mdc-checkbox-selected-focus-icon-color: white;
  --mdc-checkbox-selected-hover-icon-color: white;
  --mdc-checkbox-selected-icon-color: white;
  --mdc-checkbox-selected-pressed-icon-color: white;
  --mdc-checkbox-unselected-focus-icon-color: #eeeeee;
  --mdc-checkbox-unselected-hover-icon-color: #eeeeee;
  --mdc-checkbox-unselected-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: white;
  --mdc-checkbox-selected-hover-state-layer-color: white;
  --mdc-checkbox-selected-pressed-state-layer-color: white;
  --mdc-checkbox-unselected-focus-state-layer-color: white;
  --mdc-checkbox-unselected-hover-state-layer-color: white;
  --mdc-checkbox-unselected-pressed-state-layer-color: white;
}
.dark-theme .mat-mdc-list-option.mat-warn {
  --mdc-checkbox-disabled-selected-icon-color: rgba(255, 255, 255, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(255, 255, 255, 0.38);
  --mdc-checkbox-selected-checkmark-color: #fff;
  --mdc-checkbox-selected-focus-icon-color: #f44336;
  --mdc-checkbox-selected-hover-icon-color: #f44336;
  --mdc-checkbox-selected-icon-color: #f44336;
  --mdc-checkbox-selected-pressed-icon-color: #f44336;
  --mdc-checkbox-unselected-focus-icon-color: #eeeeee;
  --mdc-checkbox-unselected-hover-icon-color: #eeeeee;
  --mdc-checkbox-unselected-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #f44336;
  --mdc-checkbox-selected-hover-state-layer-color: #f44336;
  --mdc-checkbox-selected-pressed-state-layer-color: #f44336;
  --mdc-checkbox-unselected-focus-state-layer-color: white;
  --mdc-checkbox-unselected-hover-state-layer-color: white;
  --mdc-checkbox-unselected-pressed-state-layer-color: white;
}
.dark-theme .mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--selected .mdc-list-item__primary-text,
.dark-theme .mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--activated .mdc-list-item__primary-text {
  color: #28303a;
}
.dark-theme .mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--selected.mdc-list-item--with-leading-icon .mdc-list-item__start,
.dark-theme .mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--activated.mdc-list-item--with-leading-icon .mdc-list-item__start {
  color: #28303a;
}
.dark-theme .mat-mdc-list-base .mdc-list-item--disabled .mdc-list-item__start,
.dark-theme .mat-mdc-list-base .mdc-list-item--disabled .mdc-list-item__content,
.dark-theme .mat-mdc-list-base .mdc-list-item--disabled .mdc-list-item__end {
  opacity: 1;
}
.dark-theme .mat-mdc-form-field.mat-accent {
  --mdc-filled-text-field-caret-color: white;
  --mdc-filled-text-field-focus-active-indicator-color: white;
  --mdc-filled-text-field-focus-label-text-color: rgba(255, 255, 255, 0.87);
  --mdc-outlined-text-field-caret-color: white;
  --mdc-outlined-text-field-focus-outline-color: white;
  --mdc-outlined-text-field-focus-label-text-color: rgba(255, 255, 255, 0.87);
  --mat-form-field-focus-select-arrow-color: rgba(255, 255, 255, 0.87);
}
.dark-theme .mat-mdc-form-field.mat-warn {
  --mdc-filled-text-field-caret-color: #f44336;
  --mdc-filled-text-field-focus-active-indicator-color: #f44336;
  --mdc-filled-text-field-focus-label-text-color: rgba(244, 67, 54, 0.87);
  --mdc-outlined-text-field-caret-color: #f44336;
  --mdc-outlined-text-field-focus-outline-color: #f44336;
  --mdc-outlined-text-field-focus-label-text-color: rgba(244, 67, 54, 0.87);
  --mat-form-field-focus-select-arrow-color: rgba(244, 67, 54, 0.87);
}
.dark-theme .mat-icon.mat-primary {
  --mat-icon-color: #94989d;
}
.dark-theme .mat-icon.mat-accent {
  --mat-icon-color: white;
}
.dark-theme .mat-icon.mat-warn {
  --mat-icon-color: #f44336;
}
.dark-theme .mat-toolbar.mat-primary {
  --mat-toolbar-container-background-color: #28303a;
  --mat-toolbar-container-text-color: white;
}
.dark-theme .mat-toolbar.mat-accent {
  --mat-toolbar-container-background-color: white;
  --mat-toolbar-container-text-color: white;
}
.dark-theme .mat-toolbar.mat-warn {
  --mat-toolbar-container-background-color: #f44336;
  --mat-toolbar-container-text-color: white;
}
.dark-theme .mat-mdc-form-field.mat-accent {
  --mat-select-panel-background-color: #424242;
  --mat-select-enabled-trigger-text-color: rgba(255, 255, 255, 0.87);
  --mat-select-disabled-trigger-text-color: rgba(255, 255, 255, 0.38);
  --mat-select-placeholder-text-color: rgba(255, 255, 255, 0.6);
  --mat-select-enabled-arrow-color: rgba(255, 255, 255, 0.54);
  --mat-select-disabled-arrow-color: rgba(255, 255, 255, 0.38);
  --mat-select-focused-arrow-color: rgba(255, 255, 255, 0.87);
  --mat-select-invalid-arrow-color: rgba(244, 67, 54, 0.87);
}
.dark-theme .mat-mdc-form-field.mat-warn {
  --mat-select-panel-background-color: #424242;
  --mat-select-enabled-trigger-text-color: rgba(255, 255, 255, 0.87);
  --mat-select-disabled-trigger-text-color: rgba(255, 255, 255, 0.38);
  --mat-select-placeholder-text-color: rgba(255, 255, 255, 0.6);
  --mat-select-enabled-arrow-color: rgba(255, 255, 255, 0.54);
  --mat-select-disabled-arrow-color: rgba(255, 255, 255, 0.38);
  --mat-select-focused-arrow-color: rgba(244, 67, 54, 0.87);
  --mat-select-invalid-arrow-color: rgba(244, 67, 54, 0.87);
}
.dark-theme .mat-mdc-tab-group, .dark-theme .mat-mdc-tab-nav-bar {
  --mdc-tab-indicator-active-indicator-color: #28303a;
  --mat-tab-header-disabled-ripple-color: rgba(255, 255, 255, 0.5);
  --mat-tab-header-pagination-icon-color: #fff;
  --mat-tab-header-inactive-label-text-color: rgba(255, 255, 255, 0.6);
  --mat-tab-header-active-label-text-color: #28303a;
  --mat-tab-header-active-ripple-color: #28303a;
  --mat-tab-header-inactive-ripple-color: #28303a;
  --mat-tab-header-inactive-focus-label-text-color: rgba(255, 255, 255, 0.6);
  --mat-tab-header-inactive-hover-label-text-color: rgba(255, 255, 255, 0.6);
  --mat-tab-header-active-focus-label-text-color: #28303a;
  --mat-tab-header-active-hover-label-text-color: #28303a;
  --mat-tab-header-active-focus-indicator-color: #28303a;
  --mat-tab-header-active-hover-indicator-color: #28303a;
}
.dark-theme .mat-mdc-tab-group.mat-accent, .dark-theme .mat-mdc-tab-nav-bar.mat-accent {
  --mdc-tab-indicator-active-indicator-color: white;
  --mat-tab-header-disabled-ripple-color: rgba(255, 255, 255, 0.5);
  --mat-tab-header-pagination-icon-color: #fff;
  --mat-tab-header-inactive-label-text-color: rgba(255, 255, 255, 0.6);
  --mat-tab-header-active-label-text-color: white;
  --mat-tab-header-active-ripple-color: white;
  --mat-tab-header-inactive-ripple-color: white;
  --mat-tab-header-inactive-focus-label-text-color: rgba(255, 255, 255, 0.6);
  --mat-tab-header-inactive-hover-label-text-color: rgba(255, 255, 255, 0.6);
  --mat-tab-header-active-focus-label-text-color: white;
  --mat-tab-header-active-hover-label-text-color: white;
  --mat-tab-header-active-focus-indicator-color: white;
  --mat-tab-header-active-hover-indicator-color: white;
}
.dark-theme .mat-mdc-tab-group.mat-warn, .dark-theme .mat-mdc-tab-nav-bar.mat-warn {
  --mdc-tab-indicator-active-indicator-color: #f44336;
  --mat-tab-header-disabled-ripple-color: rgba(255, 255, 255, 0.5);
  --mat-tab-header-pagination-icon-color: #fff;
  --mat-tab-header-inactive-label-text-color: rgba(255, 255, 255, 0.6);
  --mat-tab-header-active-label-text-color: #f44336;
  --mat-tab-header-active-ripple-color: #f44336;
  --mat-tab-header-inactive-ripple-color: #f44336;
  --mat-tab-header-inactive-focus-label-text-color: rgba(255, 255, 255, 0.6);
  --mat-tab-header-inactive-hover-label-text-color: rgba(255, 255, 255, 0.6);
  --mat-tab-header-active-focus-label-text-color: #f44336;
  --mat-tab-header-active-hover-label-text-color: #f44336;
  --mat-tab-header-active-focus-indicator-color: #f44336;
  --mat-tab-header-active-hover-indicator-color: #f44336;
}
.dark-theme .mat-mdc-tab-group.mat-background-primary, .dark-theme .mat-mdc-tab-nav-bar.mat-background-primary {
  --mat-tab-header-with-background-background-color: #28303a;
  --mat-tab-header-with-background-foreground-color: white;
}
.dark-theme .mat-mdc-tab-group.mat-background-accent, .dark-theme .mat-mdc-tab-nav-bar.mat-background-accent {
  --mat-tab-header-with-background-background-color: white;
  --mat-tab-header-with-background-foreground-color: white;
}
.dark-theme .mat-mdc-tab-group.mat-background-warn, .dark-theme .mat-mdc-tab-nav-bar.mat-background-warn {
  --mat-tab-header-with-background-background-color: #f44336;
  --mat-tab-header-with-background-foreground-color: white;
}
.dark-theme .mat-accent {
  --mdc-circular-progress-active-indicator-color: white;
}
.dark-theme .mat-warn {
  --mdc-circular-progress-active-indicator-color: #f44336;
}
.dark-theme .mat-mdc-progress-bar {
  --mdc-linear-progress-active-indicator-color: #28303a;
  --mdc-linear-progress-track-color: rgba(40, 48, 58, 0.25);
}
.dark-theme .mat-mdc-progress-bar.mat-accent {
  --mdc-linear-progress-active-indicator-color: white;
  --mdc-linear-progress-track-color: rgba(255, 255, 255, 0.25);
}
.dark-theme .mat-mdc-progress-bar.mat-warn {
  --mdc-linear-progress-active-indicator-color: #f44336;
  --mdc-linear-progress-track-color: rgba(244, 67, 54, 0.25);
}
.dark-theme .mat-mdc-radio-button {
  --mdc-form-field-label-text-color: white;
}
.dark-theme .mat-mdc-radio-button.mat-primary {
  --mdc-radio-disabled-selected-icon-color: #fff;
  --mdc-radio-disabled-unselected-icon-color: #fff;
  --mdc-radio-unselected-hover-icon-color: #eeeeee;
  --mdc-radio-unselected-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-radio-selected-focus-icon-color: #28303a;
  --mdc-radio-selected-hover-icon-color: #28303a;
  --mdc-radio-selected-icon-color: #28303a;
  --mdc-radio-selected-pressed-icon-color: #28303a;
  --mat-radio-ripple-color: #fff;
  --mat-radio-checked-ripple-color: #28303a;
  --mat-radio-disabled-label-color: rgba(255, 255, 255, 0.5);
}
.dark-theme .mat-mdc-radio-button.mat-accent {
  --mdc-radio-disabled-selected-icon-color: #fff;
  --mdc-radio-disabled-unselected-icon-color: #fff;
  --mdc-radio-unselected-hover-icon-color: #eeeeee;
  --mdc-radio-unselected-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-radio-selected-focus-icon-color: white;
  --mdc-radio-selected-hover-icon-color: white;
  --mdc-radio-selected-icon-color: white;
  --mdc-radio-selected-pressed-icon-color: white;
  --mat-radio-ripple-color: #fff;
  --mat-radio-checked-ripple-color: white;
  --mat-radio-disabled-label-color: rgba(255, 255, 255, 0.5);
}
.dark-theme .mat-mdc-radio-button.mat-warn {
  --mdc-radio-disabled-selected-icon-color: #fff;
  --mdc-radio-disabled-unselected-icon-color: #fff;
  --mdc-radio-unselected-hover-icon-color: #eeeeee;
  --mdc-radio-unselected-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-radio-selected-focus-icon-color: #f44336;
  --mdc-radio-selected-hover-icon-color: #f44336;
  --mdc-radio-selected-icon-color: #f44336;
  --mdc-radio-selected-pressed-icon-color: #f44336;
  --mat-radio-ripple-color: #fff;
  --mat-radio-checked-ripple-color: #f44336;
  --mat-radio-disabled-label-color: rgba(255, 255, 255, 0.5);
}
.dark-theme .mat-mdc-checkbox {
  --mdc-form-field-label-text-color: white;
}
.dark-theme .mat-mdc-checkbox.mat-primary {
  --mdc-checkbox-disabled-selected-icon-color: rgba(255, 255, 255, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(255, 255, 255, 0.38);
  --mdc-checkbox-selected-checkmark-color: #fff;
  --mdc-checkbox-selected-focus-icon-color: #28303a;
  --mdc-checkbox-selected-hover-icon-color: #28303a;
  --mdc-checkbox-selected-icon-color: #28303a;
  --mdc-checkbox-selected-pressed-icon-color: #28303a;
  --mdc-checkbox-unselected-focus-icon-color: #eeeeee;
  --mdc-checkbox-unselected-hover-icon-color: #eeeeee;
  --mdc-checkbox-unselected-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #28303a;
  --mdc-checkbox-selected-hover-state-layer-color: #28303a;
  --mdc-checkbox-selected-pressed-state-layer-color: #28303a;
  --mdc-checkbox-unselected-focus-state-layer-color: white;
  --mdc-checkbox-unselected-hover-state-layer-color: white;
  --mdc-checkbox-unselected-pressed-state-layer-color: white;
}
.dark-theme .mat-mdc-checkbox.mat-warn {
  --mdc-checkbox-disabled-selected-icon-color: rgba(255, 255, 255, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(255, 255, 255, 0.38);
  --mdc-checkbox-selected-checkmark-color: #fff;
  --mdc-checkbox-selected-focus-icon-color: #f44336;
  --mdc-checkbox-selected-hover-icon-color: #f44336;
  --mdc-checkbox-selected-icon-color: #f44336;
  --mdc-checkbox-selected-pressed-icon-color: #f44336;
  --mdc-checkbox-unselected-focus-icon-color: #eeeeee;
  --mdc-checkbox-unselected-hover-icon-color: #eeeeee;
  --mdc-checkbox-unselected-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #f44336;
  --mdc-checkbox-selected-hover-state-layer-color: #f44336;
  --mdc-checkbox-selected-pressed-state-layer-color: #f44336;
  --mdc-checkbox-unselected-focus-state-layer-color: white;
  --mdc-checkbox-unselected-hover-state-layer-color: white;
  --mdc-checkbox-unselected-pressed-state-layer-color: white;
}
.dark-theme .mat-mdc-checkbox.mat-mdc-checkbox-disabled label {
  color: rgba(255, 255, 255, 0.5);
}
.dark-theme .mat-mdc-standard-chip {
  --mdc-chip-disabled-label-text-color: #fafafa;
  --mdc-chip-elevated-container-color: #595959;
  --mdc-chip-elevated-disabled-container-color: #595959;
  --mdc-chip-focus-state-layer-color: white;
  --mdc-chip-focus-state-layer-opacity: 0.12;
  --mdc-chip-label-text-color: #fafafa;
  --mdc-chip-with-icon-icon-color: #fafafa;
  --mdc-chip-with-icon-disabled-icon-color: #fafafa;
  --mdc-chip-with-icon-selected-icon-color: #fafafa;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: #fafafa;
  --mdc-chip-with-trailing-icon-trailing-icon-color: #fafafa;
}
.dark-theme .mat-mdc-standard-chip.mat-mdc-chip-selected.mat-primary, .dark-theme .mat-mdc-standard-chip.mat-mdc-chip-highlighted.mat-primary {
  --mdc-chip-disabled-label-text-color: white;
  --mdc-chip-elevated-container-color: #28303a;
  --mdc-chip-elevated-disabled-container-color: #28303a;
  --mdc-chip-focus-state-layer-color: white;
  --mdc-chip-focus-state-layer-opacity: 0.12;
  --mdc-chip-label-text-color: white;
  --mdc-chip-with-icon-icon-color: white;
  --mdc-chip-with-icon-disabled-icon-color: white;
  --mdc-chip-with-icon-selected-icon-color: white;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: white;
  --mdc-chip-with-trailing-icon-trailing-icon-color: white;
}
.dark-theme .mat-mdc-standard-chip.mat-mdc-chip-selected.mat-accent, .dark-theme .mat-mdc-standard-chip.mat-mdc-chip-highlighted.mat-accent {
  --mdc-chip-disabled-label-text-color: white;
  --mdc-chip-elevated-container-color: white;
  --mdc-chip-elevated-disabled-container-color: white;
  --mdc-chip-focus-state-layer-color: white;
  --mdc-chip-focus-state-layer-opacity: 0.12;
  --mdc-chip-label-text-color: white;
  --mdc-chip-with-icon-icon-color: white;
  --mdc-chip-with-icon-disabled-icon-color: white;
  --mdc-chip-with-icon-selected-icon-color: white;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: white;
  --mdc-chip-with-trailing-icon-trailing-icon-color: white;
}
.dark-theme .mat-mdc-standard-chip.mat-mdc-chip-selected.mat-warn, .dark-theme .mat-mdc-standard-chip.mat-mdc-chip-highlighted.mat-warn {
  --mdc-chip-disabled-label-text-color: white;
  --mdc-chip-elevated-container-color: #f44336;
  --mdc-chip-elevated-disabled-container-color: #f44336;
  --mdc-chip-focus-state-layer-color: white;
  --mdc-chip-focus-state-layer-opacity: 0.12;
  --mdc-chip-label-text-color: white;
  --mdc-chip-with-icon-icon-color: white;
  --mdc-chip-with-icon-disabled-icon-color: white;
  --mdc-chip-with-icon-selected-icon-color: white;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: white;
  --mdc-chip-with-trailing-icon-trailing-icon-color: white;
}
.dark-theme .mat-mdc-slide-toggle {
  --mdc-form-field-label-text-color: white;
}
.dark-theme .mat-mdc-slide-toggle.mat-accent {
  --mdc-switch-selected-focus-state-layer-color: #696e75;
  --mdc-switch-selected-handle-color: #696e75;
  --mdc-switch-selected-hover-state-layer-color: #696e75;
  --mdc-switch-selected-pressed-state-layer-color: #696e75;
  --mdc-switch-selected-focus-handle-color: #94989d;
  --mdc-switch-selected-hover-handle-color: #94989d;
  --mdc-switch-selected-pressed-handle-color: #94989d;
  --mdc-switch-selected-focus-track-color: #242b34;
  --mdc-switch-selected-hover-track-color: #242b34;
  --mdc-switch-selected-pressed-track-color: #242b34;
  --mdc-switch-selected-track-color: #242b34;
}
.dark-theme .mat-mdc-slide-toggle.mat-warn {
  --mdc-switch-selected-focus-state-layer-color: #e57373;
  --mdc-switch-selected-handle-color: #e57373;
  --mdc-switch-selected-hover-state-layer-color: #e57373;
  --mdc-switch-selected-pressed-state-layer-color: #e57373;
  --mdc-switch-selected-focus-handle-color: #ef9a9a;
  --mdc-switch-selected-hover-handle-color: #ef9a9a;
  --mdc-switch-selected-pressed-handle-color: #ef9a9a;
  --mdc-switch-selected-focus-track-color: #e53935;
  --mdc-switch-selected-hover-track-color: #e53935;
  --mdc-switch-selected-pressed-track-color: #e53935;
  --mdc-switch-selected-track-color: #e53935;
}
.dark-theme .mat-step-header.mat-accent {
  --mat-stepper-header-icon-foreground-color: white;
  --mat-stepper-header-selected-state-icon-background-color: white;
  --mat-stepper-header-selected-state-icon-foreground-color: white;
  --mat-stepper-header-done-state-icon-background-color: white;
  --mat-stepper-header-done-state-icon-foreground-color: white;
  --mat-stepper-header-edit-state-icon-background-color: white;
  --mat-stepper-header-edit-state-icon-foreground-color: white;
}
.dark-theme .mat-step-header.mat-warn {
  --mat-stepper-header-icon-foreground-color: white;
  --mat-stepper-header-selected-state-icon-background-color: #f44336;
  --mat-stepper-header-selected-state-icon-foreground-color: white;
  --mat-stepper-header-done-state-icon-background-color: #f44336;
  --mat-stepper-header-done-state-icon-foreground-color: white;
  --mat-stepper-header-edit-state-icon-background-color: #f44336;
  --mat-stepper-header-edit-state-icon-foreground-color: white;
}
.dark-theme .mat-datepicker-content.mat-accent {
  --mat-datepicker-calendar-date-selected-state-text-color: white;
  --mat-datepicker-calendar-date-selected-state-background-color: white;
  --mat-datepicker-calendar-date-selected-disabled-state-background-color: rgba(255, 255, 255, 0.4);
  --mat-datepicker-calendar-date-today-selected-state-outline-color: white;
  --mat-datepicker-calendar-date-focus-state-background-color: rgba(255, 255, 255, 0.3);
  --mat-datepicker-calendar-date-hover-state-background-color: rgba(255, 255, 255, 0.3);
  --mat-datepicker-calendar-date-in-range-state-background-color: rgba(255, 255, 255, 0.2);
  --mat-datepicker-calendar-date-in-comparison-range-state-background-color: rgba(249, 171, 0, 0.2);
  --mat-datepicker-calendar-date-in-overlap-range-state-background-color: #a8dab5;
  --mat-datepicker-calendar-date-in-overlap-range-selected-state-background-color: #46a35e;
}
.dark-theme .mat-datepicker-content.mat-warn {
  --mat-datepicker-calendar-date-selected-state-text-color: white;
  --mat-datepicker-calendar-date-selected-state-background-color: #f44336;
  --mat-datepicker-calendar-date-selected-disabled-state-background-color: rgba(244, 67, 54, 0.4);
  --mat-datepicker-calendar-date-today-selected-state-outline-color: white;
  --mat-datepicker-calendar-date-focus-state-background-color: rgba(244, 67, 54, 0.3);
  --mat-datepicker-calendar-date-hover-state-background-color: rgba(244, 67, 54, 0.3);
  --mat-datepicker-calendar-date-in-range-state-background-color: rgba(244, 67, 54, 0.2);
  --mat-datepicker-calendar-date-in-comparison-range-state-background-color: rgba(249, 171, 0, 0.2);
  --mat-datepicker-calendar-date-in-overlap-range-state-background-color: #a8dab5;
  --mat-datepicker-calendar-date-in-overlap-range-selected-state-background-color: #46a35e;
}
.dark-theme .mat-datepicker-toggle-active.mat-accent {
  --mat-datepicker-toggle-active-state-icon-color: white;
}
.dark-theme .mat-datepicker-toggle-active.mat-warn {
  --mat-datepicker-toggle-active-state-icon-color: #f44336;
}
.dark-theme plan-button[disabled] {
  background-color: #535353 !important;
  color: rgba(0, 0, 0, 0.38) !important;
}
.dark-theme plan-button[disabled] span {
  color: rgba(0, 0, 0, 0.38) !important;
}
.dark-theme .account-layout-active-row mat-icon,
.dark-theme .account-layout-active-row .nav-item-text {
  color: white !important;
}
.dark-theme .no-hover-effect.mat-mdc-nav-list .mat-mdc-list-item {
  /* stylelint-disable-next-line selector-class-pattern */
}
.dark-theme .no-hover-effect.mat-mdc-nav-list .mat-mdc-list-item:hover, .dark-theme .no-hover-effect.mat-mdc-nav-list .mat-mdc-list-item:focus {
  background-color: transparent !important;
}
.dark-theme .no-hover-effect.mat-mdc-nav-list .mat-mdc-list-item .mdc-list-item__content {
  padding-right: 0;
}
.dark-theme .no-hover-effect.mat-mdc-nav-list .mat-mdc-list-item .mdc-list-item__content .mat-mdc-list-item-ripple.mat-ripple {
  display: none;
}
.dark-theme .no-hover-effect.mat-mdc-nav-list .mat-mdc-list-item .mdc-list-item__content .mat-mdc-button {
  height: 100%;
  border-radius: 4px !important;
}
.dark-theme .no-hover-effect.mat-mdc-nav-list .mat-mdc-list-item .mdc-list-item__content .mat-mdc-button .mat-mdc-button-persistent-ripple::before {
  background-color: transparent;
}
.dark-theme .no-hover-effect.mat-mdc-nav-list .mat-mdc-list-item .mdc-list-item__content .mat-mdc-button mat-icon {
  color: #9295A3;
}
.dark-theme mat-list-item .mat-mdc-button {
  margin-left: -10px;
  padding-left: 10px !important;
}
.dark-theme .sublist .mat-mdc-expansion-panel {
  box-shadow: none;
}
.dark-theme .sublist .mat-expansion-panel-body {
  padding: 0 0 0 40px;
}
.dark-theme .sublist .mat-expansion-panel-body .mat-mdc-nav-list {
  padding-top: 0;
}
.dark-theme .sublist .mat-expansion-panel-body .mat-mdc-nav-list .mat-mdc-list-item .mat-mdc-button {
  color: #6D7184;
  width: 300px;
  text-align: left;
  font-size: 12px;
}
.dark-theme button.mat-mdc-raised-button,
.dark-theme button.mat-mdc-button {
  font-family: "Inter-Medium", "arial", sans-serif;
  border-radius: 98px;
  padding: 0 20px;
  letter-spacing: normal;
}
.dark-theme button.mat-mdc-raised-button,
.dark-theme .mat-toolbar-row .mat-mdc-button {
  box-shadow: none;
}
.dark-theme button.mat-mdc-raised-button:hover,
.dark-theme .mat-toolbar-row .mat-mdc-button:hover {
  box-shadow: none;
}
.dark-theme button.mat-mdc-raised-button:not(:disabled, .mat-accent, .mat-warn, .delete-btn),
.dark-theme .mat-toolbar-row .mat-mdc-button:not(:disabled, .mat-accent, .mat-warn, .delete-btn) {
  color: white;
}
.dark-theme button.mat-mdc-raised-button:not(:disabled, .mat-accent, .mat-warn),
.dark-theme .mat-toolbar-row .mat-mdc-button:not(:disabled, .mat-accent, .mat-warn) {
  background: #535353;
  border: 1px solid none;
}
.dark-theme button.mat-mdc-raised-button:not(:disabled, .mat-accent, .mat-warn):hover,
.dark-theme .mat-toolbar-row .mat-mdc-button:not(:disabled, .mat-accent, .mat-warn):hover {
  background: #494949;
}
.dark-theme button.mat-mdc-raised-button:not(:disabled, .mat-accent, .mat-warn):focus,
.dark-theme .mat-toolbar-row .mat-mdc-button:not(:disabled, .mat-accent, .mat-warn):focus {
  box-shadow: 0 0 0 2px #FFF, 0 0 0 4px white;
}
.dark-theme button.mat-mdc-raised-button.mat-accent,
.dark-theme .mat-toolbar-row .mat-mdc-button.mat-accent {
  background: radial-gradient(100% 100% at 50.31% 0%, #535353 0%, white 100%);
  color: white;
}
.dark-theme button.mat-mdc-raised-button.mat-accent:hover,
.dark-theme .mat-toolbar-row .mat-mdc-button.mat-accent:hover {
  background: radial-gradient(100% 100% at 50.31% 0%, #535353 0%, #535353 100%);
}
.dark-theme button.mat-mdc-raised-button:disabled,
.dark-theme .mat-toolbar-row .mat-mdc-button:disabled {
  background: rgba(83, 83, 83, 0.3137254902);
  color: #9295a3;
  cursor: auto;
}
.dark-theme button.mat-mdc-raised-button.delete-btn,
.dark-theme .mat-toolbar-row .mat-mdc-button.delete-btn {
  color: #B80000;
}
.dark-theme button.mat-mdc-raised-button.margin-top,
.dark-theme .mat-toolbar-row .mat-mdc-button.margin-top {
  margin-top: 8px;
}
.dark-theme button.mat-mdc-icon-button {
  color: white;
  border-radius: 6px;
}
.dark-theme button.mat-mdc-icon-button:hover {
  background: #494949;
}
.dark-theme button.mat-mdc-icon-button:focus {
  box-shadow: 0 0 0 4px #FFF;
}
.dark-theme button.mat-mdc-icon-button.small-icon-btn {
  width: 24px;
  height: 24px;
  padding: 0;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}
.dark-theme button.mat-mdc-icon-button.small-icon-btn > *[role=img] {
  width: 20px;
  height: 20px;
  font-size: 20px;
}
.dark-theme .steps-qp {
  height: 2px;
}
.dark-theme .steps-qp.current {
  fill: white;
  stroke: white;
}
.dark-theme .steps-qp.inactive {
  fill: #fff;
}
.dark-theme .steps-qp.finished {
  fill: #fff;
  stroke: white;
}
.dark-theme .progress-check {
  position: relative;
  top: -78px;
  left: 48px;
  font-family: "Material Icons";
}
.dark-theme .progress-check::before {
  display: block;
  content: "check";
  color: white;
}
.dark-theme .mat-app-background {
  background-color: #303030;
}
.dark-theme .header.mat-toolbar,
.dark-theme .header-logo.mat-toolbar {
  background-color: #424242;
}
.dark-theme .mat-toolbar-row,
.dark-theme .mat-toolbar.top-toolbar {
  background-color: #424242;
}
.dark-theme .mdc-list-item.acquirer-success {
  background-color: #303030;
}
.dark-theme a,
.dark-theme .link,
.dark-theme .no-results-link {
  color: #8cb4ff;
}
.dark-theme .search-field-mat .search-widget-mat input,
.dark-theme .search-field-mat .search-widget input,
.dark-theme .search-field-large .search-widget-mat input,
.dark-theme .search-field-large .search-widget input {
  background: #535353;
}
.dark-theme .search-field-mat .search-widget-mat input:focus, .dark-theme .search-field-mat .search-widget-mat input:focus-visible,
.dark-theme .search-field-mat .search-widget input:focus,
.dark-theme .search-field-mat .search-widget input:focus-visible,
.dark-theme .search-field-large .search-widget-mat input:focus,
.dark-theme .search-field-large .search-widget-mat input:focus-visible,
.dark-theme .search-field-large .search-widget input:focus,
.dark-theme .search-field-large .search-widget input:focus-visible {
  border-color: white !important;
  outline: none;
}
.dark-theme .search-field-mat .search-widget-mat .search-options,
.dark-theme .search-field-mat .search-widget .search-options,
.dark-theme .search-field-large .search-widget-mat .search-options,
.dark-theme .search-field-large .search-widget .search-options {
  border-width: 1px 0 1px 1px;
  border-style: solid;
  border-color: white;
}
.dark-theme .search-field-mat .search-widget-mat .search-options .search-option,
.dark-theme .search-field-mat .search-widget .search-options .search-option,
.dark-theme .search-field-large .search-widget-mat .search-options .search-option,
.dark-theme .search-field-large .search-widget .search-options .search-option {
  border-right: 1px solid white;
  background-color: #535353;
}
.dark-theme .search-field-mat .search-widget-mat .search-options .search-option.active,
.dark-theme .search-field-mat .search-widget .search-options .search-option.active,
.dark-theme .search-field-large .search-widget-mat .search-options .search-option.active,
.dark-theme .search-field-large .search-widget .search-options .search-option.active {
  color: white !important;
}
.dark-theme .mat-mdc-tab-group {
  --mdc-tab-indicator-active-indicator-color: white;
  --mat-tab-header-active-label-text-color: white;
  --mat-tab-header-active-ripple-color: white;
  --mat-tab-header-active-focus-label-text-color: white;
  --mat-tab-header-active-hover-label-text-color: white;
  --mat-tab-header-active-focus-indicator-color: white;
  --mat-tab-header-active-hover-indicator-color: white;
}

mat-card-title {
  font-family: "Visuelt-Bold", "arial", sans-serif !important;
  font-size: 20px !important;
  line-height: 24px !important;
}

mat-card-subtitle {
  font-family: "Inter-Regular", "arial", sans-serif !important;
  font-size: 16px !important;
  line-height: 24px !important;
}

.mat-mdc-card {
  margin: 8px;
  padding: 16px;
  border-radius: 6px !important;
  font-family: "Inter-Regular", "arial", sans-serif;
}

.mat-mdc-card-header {
  padding: 0 !important;
}

.mat-mdc-card-avatar {
  border-radius: 0 !important;
}

.mat-mdc-card-content {
  margin-bottom: 0 !important;
  padding: 0 !important;
  font-size: 14px;
}

.mat-mdc-card-image {
  margin-bottom: 0 !important;
}

mat-toolbar {
  height: 64px;
}

.header.mat-toolbar {
  z-index: 2;
  position: absolute;
}

.mat-mdc-toolbar {
  font-family: "Inter-Regular", "arial", sans-serif;
}
.mat-mdc-toolbar .mat-mdc-card-title {
  position: absolute;
  left: 26px;
}

.alignment-fix {
  margin-top: -10px;
}

@media screen and (width <= 600px) {
  mat-toolbar {
    height: 56px;
  }
}
mat-radio-group[fxlayout=column] mat-radio-button:not(:last-child) {
  margin-bottom: 12px;
}

.mat-mdc-radio-label-content {
  font-family: "Inter-Regular", "arial", sans-serif !important;
  font-size: 16px !important;
  line-height: 24px !important;
}

mat-mdc-list.no-p .mat-mdc-list-item-content {
  padding: 0 !important;
}
mat-mdc-list.list1 .mat-mdc-list-item-content {
  padding-top: 8px !important;
}

.mat-mdc-list-item.list1 {
  padding-top: 8px !important;
}

.mat-mdc-nav-list .mat-mdc-list-item {
  font-family: "Inter-Regular", "arial", sans-serif;
}

.mat-mdc-nav-list .mat-mdc-list-item {
  font-size: 16px;
}

.icon-list mat-mdc-list-item img {
  max-width: 50px;
}

.mat-mdc-list-item-content {
  width: 100%;
}

.no-padding .mat-mdc-list-item-content {
  padding: 0 !important;
}

.no-left-padding .mat-mdc-list-item-content {
  padding-left: 0 !important;
}

.mat-mdc-dialog-container {
  box-shadow: 0 1.6px 3.6px rgba(0, 0, 0, 0.132), 0 0.3px 0.9px rgba(0, 0, 0, 0.108) !important;
}
.mat-mdc-dialog-container .mdc-dialog__content {
  --mdc-dialog-supporting-text-color: $black_1;
}

.cdk-overlay-backdrop {
  background: rgba(20, 20, 20, 0.5) !important;
}

.no-padding .mat-mdc-dialog-container {
  padding: 0 !important;
}

table .mat-cell,
table .mat-footer-cell {
  font-size: 14px;
}

.mat-mdc-table {
  font-family: "SF-Mono-Regular", "arial", sans-serif;
}
.mat-mdc-table .mat-mdc-row:hover {
  background-color: rgba(158, 158, 158, 0.2) !important;
  cursor: pointer;
}
.mat-mdc-table .mat-mdc-row[qpsref]:hover:not([disabled]), .mat-mdc-table .mat-mdc-rowqpsref:hover:not([disabled]) {
  background-color: #eee !important;
  cursor: pointer;
}
.mat-mdc-table .mat-mdc-row.no-pointer:hover {
  cursor: default;
}

.mat-mdc-sort-header-content,
.mat-mdc-paginator {
  font-family: "Inter-Regular", "arial", sans-serif;
  font-size: 12px;
  line-height: 16px;
}

.mat-mdc-paginator-container:last-child {
  margin-bottom: -16px !important;
}

.mat-mdc-paginator-range-label {
  display: none;
}

.removeLastButton .mat-mdc-paginator-navigation-last {
  display: none;
}

.mat-mdc-card-image {
  display: table !important;
}

.align-right .mat-sort-header-container {
  justify-content: flex-end;
  text-align: right;
}

.mdc-data-table__header-cell {
  color: rgba(0, 0, 0, 0.5411764706);
}

.mat-accordion.accordion mat-expansion-panel {
  display: unset;
}
.mat-accordion.accordion .mat-expansion-panel-header {
  padding: 0;
  flex-direction: row-reverse;
  font-size: 16px;
}
.mat-accordion.accordion .mat-expansion-panel-header .mat-content {
  padding-left: 36px;
}
.mat-accordion.accordion .mat-expansion-panel-header.mat-expanded {
  height: 48px;
}
.mat-accordion.accordion .mat-expansion-panel-content .mat-expansion-panel-body {
  padding: 0 0 24px;
}

.mat-expansion-panel-header-title {
  font-family: "Inter-Regular", "arial", sans-serif;
}

.mat-expansion-panel:not([class*=mat-elevation-z]) {
  box-shadow: none !important;
}

/* mat-form-field / matInput - readonly support */
.mat-mdc-form-field .mat-mdc-form-field-wrapper .mat-mdc-form-field-flex .mat-mdc-form-field-infix input[readonly=true] {
  opacity: 0.5;
}

.hide-errors .md-errors-spacer {
  display: none;
}

.mat-mdc-form-field[hidden] {
  display: none;
}

.mat-mdc-form-field-infix {
  padding-bottom: 3px !important;
  min-height: 40px !important;
}

.mat-mdc-form-field-wrapper {
  padding-bottom: 0;
}

.mat-mdc-form-field-focus-overlay {
  background-color: transparent !important;
}

.mat-mdc-form-field-error-wrapper,
.mat-mdc-form-field-hint-wrapper {
  padding: 0 !important;
}

.mdc-text-field {
  padding: 0 !important;
}

.mat-mdc-error {
  font-family: "Inter-Regular", "arial", sans-serif !important;
  font-size: 12px !important;
  line-height: 16px !important;
}
.mat-mdc-error .error1 {
  font-size: 12px;
  margin-top: -15px;
}

.mat-mdc-form-field-appearance-legacy .mat-mdc-form-field-underline {
  background-color: #DBDCE0;
}

.mdc-text-field--filled:not(.mdc-text-field--disabled) .mdc-line-ripple::before {
  border-bottom-color: #DBDCE0;
}

.mdc-text-field--filled:not(.mdc-text-field--disabled) {
  background-color: transparent !important;
}

.mat-mdc-floating-label {
  font-size: 16px;
}

.mat-mdc-slide-toggle label {
  margin-left: 5px !important;
}

.mat-mdc-step-label {
  font-family: "Inter-Regular", "arial", sans-serif !important;
  font-size: 16px !important;
  line-height: 24px !important;
}

.mat-mdc-step-label-selected {
  font-family: "Inter-Regular", "arial", sans-serif !important;
  font-size: 16px !important;
  line-height: 24px !important;
}

.mat-divider {
  border-top-color: #DBDCE0;
  border-top-width: 0 !important;
}

.divider.mat-divider {
  margin: 16px -16px;
  position: relative !important;
  width: auto !important;
}

.mat-mdc-checkbox {
  font-family: "Inter-Regular", "arial", sans-serif;
}
.mat-mdc-checkbox .mdc-checkbox {
  padding-top: 0;
  padding-bottom: 0;
}
.mat-mdc-checkbox .mdc-checkbox .mdc-checkbox__background {
  top: 0;
}
.mat-mdc-checkbox .mdc-checkbox .mat-mdc-checkbox-touch-target {
  height: 24px;
  width: 24px;
}
.mat-mdc-checkbox .mdc-checkbox .mdc-checkbox__native-control {
  height: 19px;
}
.mat-mdc-checkbox .mdc-checkbox .mdc-checkbox__ripple,
.mat-mdc-checkbox .mdc-checkbox .mat-mdc-checkbox-ripple {
  top: -4px;
  height: 28px;
  width: 28px;
  left: 6px;
}

.qp-small .mat-mdc-checkbox-layout div {
  transform: scale(0.8);
}

.multiple-line-align .mat-mdc-checkbox-inner-container {
  margin: 4px 8px auto 0;
}

.mat-mdc-select-arrow {
  color: rgba(0, 0, 0, 0.5411764706) !important;
}

.mat-mdc-select-trigger {
  padding-right: 8px !important;
}

.wide-select .mat-mdc-option .mdc-list-item__primary-text {
  white-space: nowrap;
  overflow: visible;
  display: flex;
  width: 100%;
}

[mattooltip] {
  touch-action: auto !important;
}

.mat-slide-toggle-content {
  font-family: "Inter-Regular", "arial", sans-serif !important;
  font-size: 16px !important;
  line-height: 24px !important;
}

.mat-mdc-slide-toggle.mat-mdc-slide-toggle-checked:not(.mat-disabled) .mdc-switch__shadow {
  background-color: #fc1154;
  /* replace with your color */
}

.mat-mdc-slide-toggle.mat-mdc-slide-toggle-checked:not(.mat-disabled) .mdc-switch__track::after {
  background-color: rgba(252, 17, 84, 0.54);
  /* replace with your color */
}

.mat-snack-bar-container.mat-snack-bar-top {
  margin-top: 75px;
}

a.mat-line {
  font-family: "Inter-Regular", "arial", sans-serif;
  line-height: 24px;
}
a.mat-line.semi-bold {
  font-family: "Inter-SemiBold", "arial", sans-serif;
}

.mat-mdc-tab-label {
  padding: 12px 18px !important;
  min-width: 0 !important;
  text-transform: uppercase;
}
.mat-mdc-tab-label .mat-mdc-tab-label-content {
  font-family: "Inter-Regular", "arial", sans-serif;
  font-size: 14px;
  line-height: 20px;
}

.mdc-tab {
  padding: 0 !important;
}

.mdc-tab__text-label {
  padding: 12px 18px !important;
  min-width: 0 !important;
  text-transform: uppercase;
  font-weight: normal;
}

.mat-mdc-tab-nav-bar,
.mat-mdc-tab-header {
  border-bottom: none;
}

.mat-mdc-paginator .mat-mdc-text-field-wrapper.mdc-text-field--outlined .mat-mdc-form-field-infix {
  padding: 8px 14px;
}

ui-view {
  margin-bottom: 36px;
}

.ui-container {
  overflow: auto !important;
  height: calc(100% - 64px) !important;
  position: relative;
  top: 64px;
}

@media screen and (width <= 600px) {
  .ui-container {
    height: calc(100% - 56px) !important;
    position: relative;
    top: 56px;
  }
}
.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.text-left {
  text-align: left;
}

.centered-text {
  vertical-align: middle;
  text-align: center;
}

.footnote {
  text-align: right;
  display: inline-block;
  width: 15px;
}

.align-right {
  text-align: right !important;
  justify-content: flex-end !important;
}

.expandable-element {
  overflow: hidden;
  display: flex;
}

.error-border {
  border-left: 3px solid #B80000;
  position: relative;
  left: -16px;
  padding-left: 16px;
}

.warning-border {
  border-left: 3px solid #D97000;
  position: relative;
  left: -16px;
  padding-left: 16px;
}

.success-border {
  border-left: 3px solid #00A862;
  position: relative;
  left: -16px;
  padding-left: 16px;
}

.grey-underline {
  border-bottom: 1px solid #e0e0e0;
}

.grey-border-bottom {
  border-bottom: 1px solid #e0e0e0;
  margin-bottom: 1px !important;
  padding-bottom: 16px !important;
}

.bottom-border {
  border-bottom: 1px solid #e0e0e0;
}

.tap-group-border {
  border-bottom: 1px solid #DBDCE0;
}

.text-grey {
  color: #6D7184;
}

.text-grey-2 {
  color: #9295A3;
}

.text-white {
  color: #FFF;
}

.text-grey-icon {
  color: #9295A3;
}

.text-blue {
  color: #3EA4F4;
}

.text-light-blue {
  color: #298AE1;
}

.text-darkred {
  color: #B80000;
}

.text-green,
.success {
  color: #00A862;
}

.text-yellow {
  color: #D97000;
}

.text-black {
  color: #000;
}

.text-red {
  color: #fc1154;
}

.bg-white {
  background-color: #FFF;
}

html,
body {
  height: 100%;
}

body {
  margin: 0;
}

.ng-hide.ng-hide-animate {
  display: none !important;
}

p {
  font-family: "Inter-Regular", "arial", sans-serif;
  font-size: 16px;
  line-height: 24px;
  margin-block-start: 0;
}
p.info-text {
  color: #6D7184;
  font-size: 14px;
}

.cursor {
  cursor: pointer;
}

.header-login-layout {
  padding-top: 50px !important;
}

.text-wrap {
  white-space: pre-wrap;
}

/* stylelint-disable selector-class-pattern */
.dark-theme {
  background-color: #303030;
  /* Buttons */
  /* Cards */
  /* Labels */
  /* Slide toggles */
  /* Tables */
  /* Menus */
  /* Text */
  /* Icons */
  /* Input */
  /* Statistics */
  /* Scrollbar */
  /* Mobile */
}
.dark-theme button.mat-mdc-raised-button.mat-accent,
.dark-theme .mat-mdc-raised-button,
.dark-theme .mat-toolbar-row .mat-mdc-button {
  background: #535353;
  border: none !important;
}
.dark-theme .notification {
  background-color: #424242 !important;
}
.dark-theme .warning {
  background: #303030 !important;
}
.dark-theme .error {
  background-color: #303030 !important;
}
.dark-theme .qp-label,
.dark-theme .qp-label.success {
  background-color: #535353;
}
.dark-theme .mat-mdc-slide-toggle.mat-mdc-slide-toggle-checked:not(.mat-disabled) .mdc-switch__shadow,
.dark-theme .mat-mdc-slide-toggle.mat-mdc-slide-toggle-checked:not(.mat-disabled) .mdc-switch__track::after {
  background-color: #F1F1F1;
}
.dark-theme .mat-table .mat-row[qpsref]:hover:not([disabled]),
.dark-theme .mat-table .mat-rowqpsref:hover:not([disabled]) {
  background-color: #303030 !important;
}
.dark-theme .mdc-data-table__header-cell {
  color: #FFF;
}
.dark-theme table tbody tr:nth-child(odd) {
  background: #535353;
}
.dark-theme table.card-table th {
  color: #FFF;
}
.dark-theme .nav-item-text {
  color: #CDCDCD;
}
.dark-theme .nav-item-text.text-black {
  color: #FFF;
}
.dark-theme .info-text,
.dark-theme .text-grey {
  color: #CDCDCD;
}
.dark-theme a.mat-mdc-list-item-title {
  color: #FFF;
}
.dark-theme a.mat-mdc-list-item-line.mdc-list-item__secondary-text {
  color: #FFF !important;
}
.dark-theme .mat-expansion-panel-header-title {
  color: #FFF !important;
}
.dark-theme .mat-icon:not(.text-grey-icon, .text-blue, .text-light-blue, .text-darkred, .text-green, .text-yellow, .text-black, .text-red) {
  color: #FFF !important;
}
.dark-theme .search-widget-mat input,
.dark-theme .search-widget input,
.dark-theme .search-option {
  color: #FFF;
}
.dark-theme .search-option,
.dark-theme input::placeholder {
  color: #FFF !important;
}
.dark-theme .mat-mdc-select-arrow {
  color: #FFF !important;
}
.dark-theme ngx-charts-chart text {
  fill: #FFF;
}
.dark-theme ::-webkit-scrollbar {
  width: 10px;
}
.dark-theme ::-webkit-scrollbar-thumb {
  border-radius: 2px;
  background-color: #535353;
  box-shadow: inset 0 0 1px rgba(0, 0, 0, 0.5);
}
@media screen and (width <= 1279px) {
  .dark-theme .side-nav {
    background: #424242 !important;
  }
}

@font-face {
  font-family: Visuelt-Bold;
  src: url("../../assets/fonts/UnzerVisuelt/UnzerVisuelt-Bold.otf") format("opentype");
}
@font-face {
  font-family: Inter-Bold;
  src: url("../../assets/fonts/Inter/Inter-Bold.ttf") format("opentype");
}
@font-face {
  font-family: Inter-Medium;
  src: url("../../assets/fonts/Inter/Inter-Medium.ttf") format("opentype");
}
@font-face {
  font-family: Inter-SemiBold;
  src: url("../../assets/fonts/Inter/Inter-SemiBold.ttf") format("opentype");
}
@font-face {
  font-family: Inter-Regular;
  src: url("../../assets/fonts/Inter/Inter-Regular.ttf") format("opentype");
}
@font-face {
  font-family: SF-Mono-Regular;
  src: url("../../assets/fonts/SFMono/SF-Mono-Regular.otf") format("opentype");
}
.fs2 {
  font-size: 2px;
}

.fs4 {
  font-size: 4px;
}

.fs6 {
  font-size: 6px;
}

.fs8 {
  font-size: 8px;
}

.fs10 {
  font-size: 10px;
}

.fs12 {
  font-size: 12px;
}

.fs14 {
  font-size: 14px;
}

.fs16 {
  font-size: 16px;
}

.fs18 {
  font-size: 18px;
}

.fs20 {
  font-size: 20px;
}

.fs22 {
  font-size: 22px;
}

.fs24 {
  font-size: 24px;
}

.fs26 {
  font-size: 26px;
}

.fs28 {
  font-size: 28px;
}

.fs30 {
  font-size: 30px;
}

.fs32 {
  font-size: 32px;
}

.fs34 {
  font-size: 34px;
}

.fs36 {
  font-size: 36px;
}

.fs38 {
  font-size: 38px;
}

.fs40 {
  font-size: 40px;
}

.ff-mono-regular {
  font-family: "SF-Mono-Regular", "arial", sans-serif;
}

.overline {
  font-family: "Inter-Regular", "arial", sans-serif !important;
  font-size: 11px !important;
  line-height: 16px !important;
}

.code {
  font-family: "SF-Mono-Regular", "arial", sans-serif !important;
  font-size: 14px !important;
  line-height: 20px !important;
}

.white-space-normal {
  white-space: normal;
}

.white-space-pre {
  white-space: pre;
}

.pre-wrap {
  white-space: pre-wrap;
}

.breakword {
  word-break: break-all;
}

.uppercase {
  text-transform: uppercase;
}

h1 {
  font-family: "Visuelt-Bold", "arial", sans-serif !important;
  font-size: 60px !important;
  line-height: 72px !important;
}

h2 {
  font-family: "Visuelt-Bold", "arial", sans-serif !important;
  font-size: 48px !important;
  line-height: 60px !important;
}

h3 {
  font-family: "Visuelt-Bold", "arial", sans-serif !important;
  font-size: 34px !important;
  line-height: 40px !important;
}

h4 {
  font-family: "Visuelt-Bold", "arial", sans-serif !important;
  font-size: 24px !important;
  line-height: 28px !important;
}

h5 {
  font-family: "Visuelt-Bold", "arial", sans-serif !important;
  font-size: 20px !important;
  line-height: 24px !important;
}

.subtitle1 {
  font-family: "Inter-SemiBold", "arial", sans-serif !important;
  font-size: 16px !important;
  line-height: 24px !important;
  font-weight: normal !important;
}

.subtitle2 {
  font-family: "Inter-SemiBold", "arial", sans-serif !important;
  font-size: 14px !important;
  line-height: 20px !important;
}

.subtitle3 {
  font-family: "Inter-SemiBold", "arial", sans-serif !important;
  font-size: 16px !important;
  line-height: 24px !important;
}

.input-left-label {
  position: absolute;
  top: 35%;
}

.caption {
  font-family: "Inter-Regular", "arial", sans-serif !important;
  font-size: 12px !important;
  line-height: 16px !important;
}

.qp-label {
  font-family: "Inter-Regular", "arial", sans-serif !important;
  font-size: 12px !important;
  line-height: 22px !important;
  background-color: #F1F1F3;
  text-align: center;
  padding: 0 6px;
  margin: 0 8px;
  display: inline-block;
  line-height: 22px !important;
  height: 22px;
  width: 60px;
}
.qp-label.success {
  background-color: #F2FBF7;
  color: #00A862;
}
.qp-label.warning {
  background-color: #FBF1E6;
  color: #D97000;
}

/* Scrollbar */
::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-thumb {
  border-radius: 2px;
  background-color: #CCC;
  box-shadow: inset 0 0 1px rgba(0, 0, 0, 0.5);
}

a {
  text-decoration: none;
}
a:hover {
  text-decoration: underline;
}

.link {
  text-decoration: underline;
  cursor: pointer;
}

.no-results-link {
  cursor: pointer;
}

.small-text {
  font-size: 14px;
}

.font-weight-normal {
  font-weight: normal !important;
}

.large-text {
  font-size: 120% !important;
}

.uppercase {
  text-transform: uppercase;
}

th,
thead {
  font-family: "Inter-Regular", "arial", sans-serif !important;
  font-size: 12px !important;
  line-height: 16px !important;
}

#operationTable td {
  padding-top: 8px;
  padding-bottom: 8px;
}
#operationTable tr.no-print td {
  padding-top: 0;
  padding-bottom: 0;
}

table {
  font-family: "SF-Mono-Regular", "arial", sans-serif !important;
  font-size: 14px !important;
  line-height: 20px !important;
  border-collapse: collapse;
}
table thead {
  color: #8b8b8b;
}
table tbody td {
  padding-left: 0;
  padding-right: 0;
}
table tbody tr:nth-child(odd) {
  background: rgba(62, 164, 244, 0.05);
}
table th.qp-width-25 {
  width: 25%;
}
table th.qp-width-20 {
  width: 20%;
}
table th.qp-width-15 {
  width: 15%;
}
table th.qp-width-5 {
  width: 5%;
}
table tr.border-bottom td {
  border-bottom: 1px solid #DDD;
  padding-top: 8px;
  padding-bottom: 8px;
}
table tr:last-child td {
  border-bottom: none;
}
table tr.md-qp tr:hover {
  background-color: fade(#9e9e9e, 20%);
}
table .mat-cell,
table .mat-footer-cell {
  font-size: 14px;
}
table.card-table {
  margin: 0 -16px;
}
table.card-table thead tr,
table.card-table tbody tr:not(:last-child) {
  border-bottom: 1px solid #DDD;
}
table.card-table th,
table.card-table td {
  padding: 0 16px;
}
table.card-table thead {
  color: rgba(0, 0, 0, 0.541);
  height: 56px;
}
table.card-table th {
  font-weight: 200;
  text-align: left;
}
table.info-table {
  border-spacing: 0;
  width: calc(100% + 32px);
  margin: 8px -16px 16px;
}
table.info-table tr td {
  border-bottom: 1px solid #DDD;
  padding: 8px;
  padding-left: 16px;
  padding-right: 32px;
}
table.info-table tr td:first-child {
  font-family: "Inter-SemiBold", "arial", sans-serif;
}
table.info-table tr td:last-child {
  text-align: right;
  font-weight: 600;
  color: #6D7184;
}
table.table1, table.table2 {
  font-family: "Inter-Regular", "arial", sans-serif;
  font-size: 16px;
  border-spacing: 6px;
  margin-left: -6px;
}
table.table1 tr td:nth-child(2), table.table2 tr td:nth-child(2) {
  width: 15%;
  text-align: right;
}
table.table1 tr td:nth-child(1) {
  width: 52%;
}
table.table1 tr td:nth-child(3) {
  padding-left: 40px;
}
table.table2 tr td:nth-child(3) {
  padding-left: 25px;
}

.width-600px mat-card {
  width: 100%;
}

@media screen and (width <= 1279px) {
  .dashboard-card {
    width: 95vw !important;
  }
}
@media screen and (width >= 1280px) {
  .dashboard-card {
    width: calc(95vw - 220px);
  }
}

.dashboard-card-image {
  max-height: 300px;
  margin: -16px -16px -16px 0;
  border-radius: 0 6px 6px 0;
}

.dashboard-card-logo {
  max-height: 35px;
}

.card02 {
  box-shadow: 0 1.6px 3.6px rgba(0, 0, 0, 0.132), 0 0.3px 0.9px rgba(0, 0, 0, 0.108) !important;
}

.flex-row {
  display: flex !important;
  flex-direction: row !important;
  box-sizing: border-box;
}

.flex-col {
  display: flex !important;
  flex-direction: column !important;
  box-sizing: border-box;
}

.flex-wrap {
  flex-wrap: wrap !important;
}

@media screen and (width <= 959px) {
  .flex-row-mobile-col {
    flex-direction: column !important;
    display: flex !important;
    box-sizing: border-box;
  }
}
@media screen and (width >= 960px) {
  .flex-row-mobile-col {
    flex-direction: row !important;
    display: flex !important;
    box-sizing: border-box;
  }
}
.space-between {
  justify-content: space-between;
}

.space-around {
  justify-content: space-around;
}

.justify-center {
  justify-content: center;
}

.justify-start {
  justify-content: flex-start;
}

.justify-end {
  justify-content: flex-end;
}

.stretch {
  align-content: stretch;
}

.items-start {
  align-items: start;
}

.items-center {
  align-items: center;
}

.items-end {
  align-items: end;
}

.self-center {
  align-self: center;
}

.order--10 {
  order: -10;
}

.order--9 {
  order: -9;
}

.order--8 {
  order: -8;
}

.order--7 {
  order: -7;
}

.order--6 {
  order: -6;
}

.order--5 {
  order: -5;
}

.order--4 {
  order: -4;
}

.order--3 {
  order: -3;
}

.order--2 {
  order: -2;
}

.order--1 {
  order: -1;
}

.order-0 {
  order: 0;
}

.order-1 {
  order: 1;
}

.order-2 {
  order: 2;
}

.order-3 {
  order: 3;
}

.order-4 {
  order: 4;
}

.order-5 {
  order: 5;
}

.order-6 {
  order: 6;
}

.order-7 {
  order: 7;
}

.order-8 {
  order: 8;
}

.order-9 {
  order: 9;
}

.order-10 {
  order: 10;
}

@media screen and (width <= 959px) {
  .align-responsive-1 {
    justify-content: center;
    align-items: center;
  }
  .align-responsive-2 {
    justify-content: center;
  }
}
@media screen and (width >= 960px) {
  .align-responsive-1 {
    justify-content: flex-start;
    align-items: center;
  }
}
.flex-1 {
  flex: 1;
}

.flex-4 {
  box-sizing: border-box;
  flex: 1 1 100%;
  max-width: 4%;
}

.flex-5 {
  box-sizing: border-box;
  flex: 1 1 100%;
  max-width: 5%;
}

.flex-6 {
  box-sizing: border-box;
  flex: 1 1 100%;
  max-width: 6%;
}

.flex-7 {
  box-sizing: border-box;
  flex: 1 1 100%;
  max-width: 7%;
}

.flex-8 {
  box-sizing: border-box;
  flex: 1 1 100%;
  max-width: 8%;
}

.flex-9 {
  box-sizing: border-box;
  flex: 1 1 100%;
  max-width: 9%;
}

.flex-10 {
  box-sizing: border-box;
  flex: 1 1 100%;
  max-width: 10%;
}

.flex-11 {
  box-sizing: border-box;
  flex: 1 1 100%;
  max-width: 11%;
}

.flex-12 {
  box-sizing: border-box;
  flex: 1 1 100%;
  max-width: 12%;
}

.flex-13 {
  box-sizing: border-box;
  flex: 1 1 100%;
  max-width: 13%;
}

.flex-14 {
  box-sizing: border-box;
  flex: 1 1 100%;
  max-width: 14%;
}

.flex-15 {
  box-sizing: border-box;
  flex: 1 1 100%;
  max-width: 15%;
}

.flex-16 {
  box-sizing: border-box;
  flex: 1 1 100%;
  max-width: 16%;
}

.flex-17 {
  box-sizing: border-box;
  flex: 1 1 100%;
  max-width: 17%;
}

.flex-18 {
  box-sizing: border-box;
  flex: 1 1 100%;
  max-width: 18%;
}

.flex-19 {
  box-sizing: border-box;
  flex: 1 1 100%;
  max-width: 19%;
}

.flex-20 {
  box-sizing: border-box;
  flex: 1 1 100%;
  max-width: 20%;
}

.flex-21 {
  box-sizing: border-box;
  flex: 1 1 100%;
  max-width: 21%;
}

.flex-22 {
  box-sizing: border-box;
  flex: 1 1 100%;
  max-width: 22%;
}

.flex-23 {
  box-sizing: border-box;
  flex: 1 1 100%;
  max-width: 23%;
}

.flex-24 {
  box-sizing: border-box;
  flex: 1 1 100%;
  max-width: 24%;
}

.flex-25 {
  box-sizing: border-box;
  flex: 1 1 100%;
  max-width: 25%;
}

.flex-26 {
  box-sizing: border-box;
  flex: 1 1 100%;
  max-width: 26%;
}

.flex-27 {
  box-sizing: border-box;
  flex: 1 1 100%;
  max-width: 27%;
}

.flex-28 {
  box-sizing: border-box;
  flex: 1 1 100%;
  max-width: 28%;
}

.flex-29 {
  box-sizing: border-box;
  flex: 1 1 100%;
  max-width: 29%;
}

.flex-30 {
  box-sizing: border-box;
  flex: 1 1 100%;
  max-width: 30%;
}

.flex-31 {
  box-sizing: border-box;
  flex: 1 1 100%;
  max-width: 31%;
}

.flex-32 {
  box-sizing: border-box;
  flex: 1 1 100%;
  max-width: 32%;
}

.flex-33 {
  box-sizing: border-box;
  flex: 1 1 100%;
  max-width: 33%;
}

.flex-34 {
  box-sizing: border-box;
  flex: 1 1 100%;
  max-width: 34%;
}

.flex-35 {
  box-sizing: border-box;
  flex: 1 1 100%;
  max-width: 35%;
}

.flex-36 {
  box-sizing: border-box;
  flex: 1 1 100%;
  max-width: 36%;
}

.flex-37 {
  box-sizing: border-box;
  flex: 1 1 100%;
  max-width: 37%;
}

.flex-38 {
  box-sizing: border-box;
  flex: 1 1 100%;
  max-width: 38%;
}

.flex-39 {
  box-sizing: border-box;
  flex: 1 1 100%;
  max-width: 39%;
}

.flex-40 {
  box-sizing: border-box;
  flex: 1 1 100%;
  max-width: 40%;
}

.flex-41 {
  box-sizing: border-box;
  flex: 1 1 100%;
  max-width: 41%;
}

.flex-42 {
  box-sizing: border-box;
  flex: 1 1 100%;
  max-width: 42%;
}

.flex-43 {
  box-sizing: border-box;
  flex: 1 1 100%;
  max-width: 43%;
}

.flex-44 {
  box-sizing: border-box;
  flex: 1 1 100%;
  max-width: 44%;
}

.flex-45 {
  box-sizing: border-box;
  flex: 1 1 100%;
  max-width: 45%;
}

.flex-46 {
  box-sizing: border-box;
  flex: 1 1 100%;
  max-width: 46%;
}

.flex-47 {
  box-sizing: border-box;
  flex: 1 1 100%;
  max-width: 47%;
}

.flex-48 {
  box-sizing: border-box;
  flex: 1 1 100%;
  max-width: 48%;
}

.flex-49 {
  box-sizing: border-box;
  flex: 1 1 100%;
  max-width: 49%;
}

.flex-50 {
  box-sizing: border-box;
  flex: 1 1 100%;
  max-width: 50%;
}

.flex-51 {
  box-sizing: border-box;
  flex: 1 1 100%;
  max-width: 51%;
}

.flex-52 {
  box-sizing: border-box;
  flex: 1 1 100%;
  max-width: 52%;
}

.flex-53 {
  box-sizing: border-box;
  flex: 1 1 100%;
  max-width: 53%;
}

.flex-54 {
  box-sizing: border-box;
  flex: 1 1 100%;
  max-width: 54%;
}

.flex-55 {
  box-sizing: border-box;
  flex: 1 1 100%;
  max-width: 55%;
}

.flex-56 {
  box-sizing: border-box;
  flex: 1 1 100%;
  max-width: 56%;
}

.flex-57 {
  box-sizing: border-box;
  flex: 1 1 100%;
  max-width: 57%;
}

.flex-58 {
  box-sizing: border-box;
  flex: 1 1 100%;
  max-width: 58%;
}

.flex-59 {
  box-sizing: border-box;
  flex: 1 1 100%;
  max-width: 59%;
}

.flex-60 {
  box-sizing: border-box;
  flex: 1 1 100%;
  max-width: 60%;
}

.flex-61 {
  box-sizing: border-box;
  flex: 1 1 100%;
  max-width: 61%;
}

.flex-62 {
  box-sizing: border-box;
  flex: 1 1 100%;
  max-width: 62%;
}

.flex-63 {
  box-sizing: border-box;
  flex: 1 1 100%;
  max-width: 63%;
}

.flex-64 {
  box-sizing: border-box;
  flex: 1 1 100%;
  max-width: 64%;
}

.flex-65 {
  box-sizing: border-box;
  flex: 1 1 100%;
  max-width: 65%;
}

.flex-66 {
  box-sizing: border-box;
  flex: 1 1 100%;
  max-width: 66%;
}

.flex-67 {
  box-sizing: border-box;
  flex: 1 1 100%;
  max-width: 67%;
}

.flex-68 {
  box-sizing: border-box;
  flex: 1 1 100%;
  max-width: 68%;
}

.flex-69 {
  box-sizing: border-box;
  flex: 1 1 100%;
  max-width: 69%;
}

.flex-70 {
  box-sizing: border-box;
  flex: 1 1 100%;
  max-width: 70%;
}

.flex-71 {
  box-sizing: border-box;
  flex: 1 1 100%;
  max-width: 71%;
}

.flex-72 {
  box-sizing: border-box;
  flex: 1 1 100%;
  max-width: 72%;
}

.flex-73 {
  box-sizing: border-box;
  flex: 1 1 100%;
  max-width: 73%;
}

.flex-74 {
  box-sizing: border-box;
  flex: 1 1 100%;
  max-width: 74%;
}

.flex-75 {
  box-sizing: border-box;
  flex: 1 1 100%;
  max-width: 75%;
}

.flex-76 {
  box-sizing: border-box;
  flex: 1 1 100%;
  max-width: 76%;
}

.flex-77 {
  box-sizing: border-box;
  flex: 1 1 100%;
  max-width: 77%;
}

.flex-78 {
  box-sizing: border-box;
  flex: 1 1 100%;
  max-width: 78%;
}

.flex-79 {
  box-sizing: border-box;
  flex: 1 1 100%;
  max-width: 79%;
}

.flex-80 {
  box-sizing: border-box;
  flex: 1 1 100%;
  max-width: 80%;
}

.flex-81 {
  box-sizing: border-box;
  flex: 1 1 100%;
  max-width: 81%;
}

.flex-82 {
  box-sizing: border-box;
  flex: 1 1 100%;
  max-width: 82%;
}

.flex-83 {
  box-sizing: border-box;
  flex: 1 1 100%;
  max-width: 83%;
}

.flex-84 {
  box-sizing: border-box;
  flex: 1 1 100%;
  max-width: 84%;
}

.flex-85 {
  box-sizing: border-box;
  flex: 1 1 100%;
  max-width: 85%;
}

.flex-86 {
  box-sizing: border-box;
  flex: 1 1 100%;
  max-width: 86%;
}

.flex-87 {
  box-sizing: border-box;
  flex: 1 1 100%;
  max-width: 87%;
}

.flex-88 {
  box-sizing: border-box;
  flex: 1 1 100%;
  max-width: 88%;
}

.flex-89 {
  box-sizing: border-box;
  flex: 1 1 100%;
  max-width: 89%;
}

.flex-90 {
  box-sizing: border-box;
  flex: 1 1 100%;
  max-width: 90%;
}

.flex-91 {
  box-sizing: border-box;
  flex: 1 1 100%;
  max-width: 91%;
}

.flex-92 {
  box-sizing: border-box;
  flex: 1 1 100%;
  max-width: 92%;
}

.flex-93 {
  box-sizing: border-box;
  flex: 1 1 100%;
  max-width: 93%;
}

.flex-94 {
  box-sizing: border-box;
  flex: 1 1 100%;
  max-width: 94%;
}

.flex-95 {
  box-sizing: border-box;
  flex: 1 1 100%;
  max-width: 95%;
}

.flex-96 {
  box-sizing: border-box;
  flex: 1 1 100%;
  max-width: 96%;
}

.flex-97 {
  box-sizing: border-box;
  flex: 1 1 100%;
  max-width: 97%;
}

.flex-98 {
  box-sizing: border-box;
  flex: 1 1 100%;
  max-width: 98%;
}

.flex-99 {
  box-sizing: border-box;
  flex: 1 1 100%;
  max-width: 99%;
}

.flex-100 {
  box-sizing: border-box;
  flex: 1 1 100%;
}

.flex-h2 {
  box-sizing: border-box;
  flex: 1 1 100%;
  max-height: 2%;
}

@media screen and (width <= 959px) {
  .flex-25-mobile-100 {
    box-sizing: border-box;
    flex: 1 1 100%;
  }
  .flex-26-mobile-100 {
    box-sizing: border-box;
    flex: 1 1 100%;
  }
  .flex-27-mobile-100 {
    box-sizing: border-box;
    flex: 1 1 100%;
  }
  .flex-28-mobile-100 {
    box-sizing: border-box;
    flex: 1 1 100%;
  }
  .flex-29-mobile-100 {
    box-sizing: border-box;
    flex: 1 1 100%;
  }
  .flex-30-mobile-100 {
    box-sizing: border-box;
    flex: 1 1 100%;
  }
  .flex-31-mobile-100 {
    box-sizing: border-box;
    flex: 1 1 100%;
  }
  .flex-32-mobile-100 {
    box-sizing: border-box;
    flex: 1 1 100%;
  }
  .flex-33-mobile-100 {
    box-sizing: border-box;
    flex: 1 1 100%;
  }
  .flex-34-mobile-100 {
    box-sizing: border-box;
    flex: 1 1 100%;
  }
  .flex-35-mobile-100 {
    box-sizing: border-box;
    flex: 1 1 100%;
  }
  .flex-36-mobile-100 {
    box-sizing: border-box;
    flex: 1 1 100%;
  }
  .flex-37-mobile-100 {
    box-sizing: border-box;
    flex: 1 1 100%;
  }
  .flex-38-mobile-100 {
    box-sizing: border-box;
    flex: 1 1 100%;
  }
  .flex-39-mobile-100 {
    box-sizing: border-box;
    flex: 1 1 100%;
  }
  .flex-40-mobile-100 {
    box-sizing: border-box;
    flex: 1 1 100%;
  }
  .flex-41-mobile-100 {
    box-sizing: border-box;
    flex: 1 1 100%;
  }
  .flex-42-mobile-100 {
    box-sizing: border-box;
    flex: 1 1 100%;
  }
  .flex-43-mobile-100 {
    box-sizing: border-box;
    flex: 1 1 100%;
  }
  .flex-44-mobile-100 {
    box-sizing: border-box;
    flex: 1 1 100%;
  }
  .flex-45-mobile-100 {
    box-sizing: border-box;
    flex: 1 1 100%;
  }
  .flex-46-mobile-100 {
    box-sizing: border-box;
    flex: 1 1 100%;
  }
  .flex-47-mobile-100 {
    box-sizing: border-box;
    flex: 1 1 100%;
  }
  .flex-48-mobile-100 {
    box-sizing: border-box;
    flex: 1 1 100%;
  }
  .flex-49-mobile-100 {
    box-sizing: border-box;
    flex: 1 1 100%;
  }
  .flex-50-mobile-100 {
    box-sizing: border-box;
    flex: 1 1 100%;
  }
  .flex-51-mobile-100 {
    box-sizing: border-box;
    flex: 1 1 100%;
  }
  .flex-52-mobile-100 {
    box-sizing: border-box;
    flex: 1 1 100%;
  }
  .flex-53-mobile-100 {
    box-sizing: border-box;
    flex: 1 1 100%;
  }
  .flex-54-mobile-100 {
    box-sizing: border-box;
    flex: 1 1 100%;
  }
  .flex-55-mobile-100 {
    box-sizing: border-box;
    flex: 1 1 100%;
  }
  .flex-56-mobile-100 {
    box-sizing: border-box;
    flex: 1 1 100%;
  }
  .flex-57-mobile-100 {
    box-sizing: border-box;
    flex: 1 1 100%;
  }
  .flex-58-mobile-100 {
    box-sizing: border-box;
    flex: 1 1 100%;
  }
  .flex-59-mobile-100 {
    box-sizing: border-box;
    flex: 1 1 100%;
  }
  .flex-60-mobile-100 {
    box-sizing: border-box;
    flex: 1 1 100%;
  }
  .flex-61-mobile-100 {
    box-sizing: border-box;
    flex: 1 1 100%;
  }
  .flex-62-mobile-100 {
    box-sizing: border-box;
    flex: 1 1 100%;
  }
  .flex-63-mobile-100 {
    box-sizing: border-box;
    flex: 1 1 100%;
  }
  .flex-64-mobile-100 {
    box-sizing: border-box;
    flex: 1 1 100%;
  }
  .flex-65-mobile-100 {
    box-sizing: border-box;
    flex: 1 1 100%;
  }
  .flex-66-mobile-100 {
    box-sizing: border-box;
    flex: 1 1 100%;
  }
  .flex-67-mobile-100 {
    box-sizing: border-box;
    flex: 1 1 100%;
  }
  .flex-68-mobile-100 {
    box-sizing: border-box;
    flex: 1 1 100%;
  }
  .flex-69-mobile-100 {
    box-sizing: border-box;
    flex: 1 1 100%;
  }
  .flex-70-mobile-100 {
    box-sizing: border-box;
    flex: 1 1 100%;
  }
  .flex-71-mobile-100 {
    box-sizing: border-box;
    flex: 1 1 100%;
  }
  .flex-72-mobile-100 {
    box-sizing: border-box;
    flex: 1 1 100%;
  }
  .flex-73-mobile-100 {
    box-sizing: border-box;
    flex: 1 1 100%;
  }
  .flex-74-mobile-100 {
    box-sizing: border-box;
    flex: 1 1 100%;
  }
  .flex-75-mobile-100 {
    box-sizing: border-box;
    flex: 1 1 100%;
  }
  .flex-76-mobile-100 {
    box-sizing: border-box;
    flex: 1 1 100%;
  }
  .flex-77-mobile-100 {
    box-sizing: border-box;
    flex: 1 1 100%;
  }
  .flex-78-mobile-100 {
    box-sizing: border-box;
    flex: 1 1 100%;
  }
  .flex-79-mobile-100 {
    box-sizing: border-box;
    flex: 1 1 100%;
  }
  .flex-80-mobile-100 {
    box-sizing: border-box;
    flex: 1 1 100%;
  }
}
@media screen and (width >= 960px) {
  .flex-25-mobile-100 {
    box-sizing: border-box;
    flex: 1 1 100%;
    max-width: 25%;
  }
  .flex-26-mobile-100 {
    box-sizing: border-box;
    flex: 1 1 100%;
    max-width: 26%;
  }
  .flex-27-mobile-100 {
    box-sizing: border-box;
    flex: 1 1 100%;
    max-width: 27%;
  }
  .flex-28-mobile-100 {
    box-sizing: border-box;
    flex: 1 1 100%;
    max-width: 28%;
  }
  .flex-29-mobile-100 {
    box-sizing: border-box;
    flex: 1 1 100%;
    max-width: 29%;
  }
  .flex-30-mobile-100 {
    box-sizing: border-box;
    flex: 1 1 100%;
    max-width: 30%;
  }
  .flex-31-mobile-100 {
    box-sizing: border-box;
    flex: 1 1 100%;
    max-width: 31%;
  }
  .flex-32-mobile-100 {
    box-sizing: border-box;
    flex: 1 1 100%;
    max-width: 32%;
  }
  .flex-33-mobile-100 {
    box-sizing: border-box;
    flex: 1 1 100%;
    max-width: 33%;
  }
  .flex-34-mobile-100 {
    box-sizing: border-box;
    flex: 1 1 100%;
    max-width: 34%;
  }
  .flex-35-mobile-100 {
    box-sizing: border-box;
    flex: 1 1 100%;
    max-width: 35%;
  }
  .flex-36-mobile-100 {
    box-sizing: border-box;
    flex: 1 1 100%;
    max-width: 36%;
  }
  .flex-37-mobile-100 {
    box-sizing: border-box;
    flex: 1 1 100%;
    max-width: 37%;
  }
  .flex-38-mobile-100 {
    box-sizing: border-box;
    flex: 1 1 100%;
    max-width: 38%;
  }
  .flex-39-mobile-100 {
    box-sizing: border-box;
    flex: 1 1 100%;
    max-width: 39%;
  }
  .flex-40-mobile-100 {
    box-sizing: border-box;
    flex: 1 1 100%;
    max-width: 40%;
  }
  .flex-41-mobile-100 {
    box-sizing: border-box;
    flex: 1 1 100%;
    max-width: 41%;
  }
  .flex-42-mobile-100 {
    box-sizing: border-box;
    flex: 1 1 100%;
    max-width: 42%;
  }
  .flex-43-mobile-100 {
    box-sizing: border-box;
    flex: 1 1 100%;
    max-width: 43%;
  }
  .flex-44-mobile-100 {
    box-sizing: border-box;
    flex: 1 1 100%;
    max-width: 44%;
  }
  .flex-45-mobile-100 {
    box-sizing: border-box;
    flex: 1 1 100%;
    max-width: 45%;
  }
  .flex-46-mobile-100 {
    box-sizing: border-box;
    flex: 1 1 100%;
    max-width: 46%;
  }
  .flex-47-mobile-100 {
    box-sizing: border-box;
    flex: 1 1 100%;
    max-width: 47%;
  }
  .flex-48-mobile-100 {
    box-sizing: border-box;
    flex: 1 1 100%;
    max-width: 48%;
  }
  .flex-49-mobile-100 {
    box-sizing: border-box;
    flex: 1 1 100%;
    max-width: 49%;
  }
  .flex-50-mobile-100 {
    box-sizing: border-box;
    flex: 1 1 100%;
    max-width: 50%;
  }
  .flex-51-mobile-100 {
    box-sizing: border-box;
    flex: 1 1 100%;
    max-width: 51%;
  }
  .flex-52-mobile-100 {
    box-sizing: border-box;
    flex: 1 1 100%;
    max-width: 52%;
  }
  .flex-53-mobile-100 {
    box-sizing: border-box;
    flex: 1 1 100%;
    max-width: 53%;
  }
  .flex-54-mobile-100 {
    box-sizing: border-box;
    flex: 1 1 100%;
    max-width: 54%;
  }
  .flex-55-mobile-100 {
    box-sizing: border-box;
    flex: 1 1 100%;
    max-width: 55%;
  }
  .flex-56-mobile-100 {
    box-sizing: border-box;
    flex: 1 1 100%;
    max-width: 56%;
  }
  .flex-57-mobile-100 {
    box-sizing: border-box;
    flex: 1 1 100%;
    max-width: 57%;
  }
  .flex-58-mobile-100 {
    box-sizing: border-box;
    flex: 1 1 100%;
    max-width: 58%;
  }
  .flex-59-mobile-100 {
    box-sizing: border-box;
    flex: 1 1 100%;
    max-width: 59%;
  }
  .flex-60-mobile-100 {
    box-sizing: border-box;
    flex: 1 1 100%;
    max-width: 60%;
  }
  .flex-61-mobile-100 {
    box-sizing: border-box;
    flex: 1 1 100%;
    max-width: 61%;
  }
  .flex-62-mobile-100 {
    box-sizing: border-box;
    flex: 1 1 100%;
    max-width: 62%;
  }
  .flex-63-mobile-100 {
    box-sizing: border-box;
    flex: 1 1 100%;
    max-width: 63%;
  }
  .flex-64-mobile-100 {
    box-sizing: border-box;
    flex: 1 1 100%;
    max-width: 64%;
  }
  .flex-65-mobile-100 {
    box-sizing: border-box;
    flex: 1 1 100%;
    max-width: 65%;
  }
  .flex-66-mobile-100 {
    box-sizing: border-box;
    flex: 1 1 100%;
    max-width: 66%;
  }
  .flex-67-mobile-100 {
    box-sizing: border-box;
    flex: 1 1 100%;
    max-width: 67%;
  }
  .flex-68-mobile-100 {
    box-sizing: border-box;
    flex: 1 1 100%;
    max-width: 68%;
  }
  .flex-69-mobile-100 {
    box-sizing: border-box;
    flex: 1 1 100%;
    max-width: 69%;
  }
  .flex-70-mobile-100 {
    box-sizing: border-box;
    flex: 1 1 100%;
    max-width: 70%;
  }
  .flex-71-mobile-100 {
    box-sizing: border-box;
    flex: 1 1 100%;
    max-width: 71%;
  }
  .flex-72-mobile-100 {
    box-sizing: border-box;
    flex: 1 1 100%;
    max-width: 72%;
  }
  .flex-73-mobile-100 {
    box-sizing: border-box;
    flex: 1 1 100%;
    max-width: 73%;
  }
  .flex-74-mobile-100 {
    box-sizing: border-box;
    flex: 1 1 100%;
    max-width: 74%;
  }
  .flex-75-mobile-100 {
    box-sizing: border-box;
    flex: 1 1 100%;
    max-width: 75%;
  }
  .flex-76-mobile-100 {
    box-sizing: border-box;
    flex: 1 1 100%;
    max-width: 76%;
  }
  .flex-77-mobile-100 {
    box-sizing: border-box;
    flex: 1 1 100%;
    max-width: 77%;
  }
  .flex-78-mobile-100 {
    box-sizing: border-box;
    flex: 1 1 100%;
    max-width: 78%;
  }
  .flex-79-mobile-100 {
    box-sizing: border-box;
    flex: 1 1 100%;
    max-width: 79%;
  }
  .flex-80-mobile-100 {
    box-sizing: border-box;
    flex: 1 1 100%;
    max-width: 80%;
  }
}
@media screen and (width >= 600px) {
  .flex-desktop-30 {
    box-sizing: border-box;
    flex: 1 1 100%;
    max-width: 30%;
  }
}
@media screen and (width <= 959px) {
  .flex-60-mobile-90,
  .flex-95-mobile-90 {
    box-sizing: border-box;
    flex: 1 1 100%;
    max-width: 90%;
  }
  .flex-30-mobile-50 {
    box-sizing: border-box;
    flex: 1 1 100%;
    max-width: 50%;
  }
  .flex-100-mobile-70 {
    box-sizing: border-box;
    flex: 1 1 100%;
    max-width: 70%;
  }
}
@media screen and (width >= 960px) {
  .flex-60-mobile-90 {
    box-sizing: border-box;
    flex: 1 1 100%;
    max-width: 60%;
  }
  .flex-95-mobile-90 {
    box-sizing: border-box;
    flex: 1 1 100%;
    max-width: 95%;
  }
  .flex-30-mobile-50 {
    box-sizing: border-box;
    flex: 1 1 100%;
    max-width: 30%;
  }
  .flex-100-mobile-70 {
    box-sizing: border-box;
    flex: 1 1 100%;
    max-width: 100%;
  }
}
@media screen and (width <= 959px) {
  .flex-90-60-50 {
    box-sizing: border-box;
    flex: 1 1 100%;
    max-width: 90%;
  }
  .flex-70-60-60-50 {
    box-sizing: border-box;
    flex: 1 1 100%;
    max-width: 70%;
  }
  .flex-90-70 {
    box-sizing: border-box;
    flex: 1 1 100%;
    max-width: 90%;
  }
}
@media screen and (width >= 960px) and (width <= 1279px) {
  .flex-90-60-50 {
    box-sizing: border-box;
    flex: 1 1 100%;
    max-width: 60%;
  }
  .flex-90-70 {
    box-sizing: border-box;
    flex: 1 1 100%;
    max-width: 70%;
  }
}
@media screen and (width >= 960px) and (width <= 1919px) {
  .flex-70-60-60-50 {
    box-sizing: border-box;
    flex: 1 1 100%;
    max-width: 60%;
  }
}
@media screen and (width >= 1280px) {
  .flex-90-60-50 {
    box-sizing: border-box;
    flex: 1 1 100%;
    max-width: 50%;
  }
}
@media screen and (width >= 1919px) {
  .flex-70-60-60-50 {
    box-sizing: border-box;
    flex: 1 1 100%;
    max-width: 50%;
  }
}
@media screen and (width <= 959px) {
  .flex-632px-mobile-100 {
    flex: 1 1 100%;
  }
}
@media screen and (width >= 960px) {
  .flex-632px-mobile-100 {
    flex: 1 1 100%;
    max-width: 632px;
  }
}
.fill {
  height: 100%;
  min-height: 100%;
  min-width: 100%;
  width: 100%;
}

@media screen and (width <= 599px) {
  .hide-lt-sm {
    display: none !important;
  }
}

@media screen and (width <= 959px) {
  .hide-lt-md {
    display: none !important;
  }
}

@media screen and (width <= 1279px) {
  .hide-lt-lg {
    display: none !important;
  }
}

@media screen and (width <= 1919px) {
  .hide-lt-xl {
    display: none !important;
  }
}

@media screen and (width >= 600px) {
  .hide-gt-xs {
    display: none !important;
  }
}

@media screen and (width >= 960px) {
  .hide-gt-sm {
    display: none !important;
  }
}

@media screen and (width >= 1280px) {
  .hide-gt-md {
    display: none !important;
  }
}

@media screen and (width >= 1920px) {
  .hide-gt-lg {
    display: none !important;
  }
}

.cg1 {
  column-gap: 1px;
}

.cg2 {
  column-gap: 2px;
}

.cg3 {
  column-gap: 3px;
}

.cg4 {
  column-gap: 4px;
}

.cg5 {
  column-gap: 5px;
}

.cg6 {
  column-gap: 6px;
}

.cg7 {
  column-gap: 7px;
}

.cg8 {
  column-gap: 8px;
}

.cg9 {
  column-gap: 9px;
}

.cg10 {
  column-gap: 10px;
}

.cg11 {
  column-gap: 11px;
}

.cg12 {
  column-gap: 12px;
}

.cg13 {
  column-gap: 13px;
}

.cg14 {
  column-gap: 14px;
}

.cg15 {
  column-gap: 15px;
}

.cg16 {
  column-gap: 16px;
}

.cg17 {
  column-gap: 17px;
}

.cg18 {
  column-gap: 18px;
}

.cg19 {
  column-gap: 19px;
}

.cg20 {
  column-gap: 20px;
}

.cg21 {
  column-gap: 21px;
}

.cg22 {
  column-gap: 22px;
}

.cg23 {
  column-gap: 23px;
}

.cg24 {
  column-gap: 24px;
}

.cg25 {
  column-gap: 25px;
}

.cg26 {
  column-gap: 26px;
}

.cg27 {
  column-gap: 27px;
}

.cg28 {
  column-gap: 28px;
}

.cg29 {
  column-gap: 29px;
}

.cg30 {
  column-gap: 30px;
}

.cg31 {
  column-gap: 31px;
}

.cg32 {
  column-gap: 32px;
}

.cg33 {
  column-gap: 33px;
}

.cg34 {
  column-gap: 34px;
}

.cg35 {
  column-gap: 35px;
}

.cg36 {
  column-gap: 36px;
}

.cg4p {
  column-gap: 4%;
}

.cg6p {
  column-gap: 6%;
}

.cg15p {
  column-gap: 15%;
}

.cg20p {
  column-gap: 20%;
}

.rg1 {
  row-gap: 1px;
}

.rg2 {
  row-gap: 2px;
}

.rg3 {
  row-gap: 3px;
}

.rg4 {
  row-gap: 4px;
}

.rg5 {
  row-gap: 5px;
}

.rg6 {
  row-gap: 6px;
}

.rg7 {
  row-gap: 7px;
}

.rg8 {
  row-gap: 8px;
}

.rg9 {
  row-gap: 9px;
}

.rg10 {
  row-gap: 10px;
}

.rg11 {
  row-gap: 11px;
}

.rg12 {
  row-gap: 12px;
}

.rg13 {
  row-gap: 13px;
}

.rg14 {
  row-gap: 14px;
}

.rg15 {
  row-gap: 15px;
}

.rg16 {
  row-gap: 16px;
}

.rg17 {
  row-gap: 17px;
}

.rg18 {
  row-gap: 18px;
}

.rg19 {
  row-gap: 19px;
}

.rg20 {
  row-gap: 20px;
}

.rg21 {
  row-gap: 21px;
}

.rg22 {
  row-gap: 22px;
}

.rg23 {
  row-gap: 23px;
}

.rg24 {
  row-gap: 24px;
}

.rg25 {
  row-gap: 25px;
}

.rg26 {
  row-gap: 26px;
}

.rg27 {
  row-gap: 27px;
}

.rg28 {
  row-gap: 28px;
}

.rg29 {
  row-gap: 29px;
}

.rg30 {
  row-gap: 30px;
}

.rg31 {
  row-gap: 31px;
}

.rg32 {
  row-gap: 32px;
}

.rg33 {
  row-gap: 33px;
}

.rg34 {
  row-gap: 34px;
}

.rg35 {
  row-gap: 35px;
}

.rg36 {
  row-gap: 36px;
}

.height-100px {
  height: 100px;
}

.max-height-5p {
  max-height: 5%;
}

.max-size {
  max-width: 100%;
  max-height: 100%;
}

.width-780px {
  width: 780px;
}

.width-700px {
  width: 700px;
}

.width-400px {
  width: 400px;
}

.width-600px {
  width: 600px;
}

.width-250px {
  width: 250px;
}

.width-150px {
  width: 150px;
}

.width-60px {
  width: 60px;
}

.width-40px {
  width: 40px;
}

.width-60p {
  width: 60%;
}

.width-100p {
  width: 100%;
}

.max-width-8px {
  max-width: 8px;
}

.max-width-16px {
  max-width: 16px;
}

.max-width-75px {
  max-width: 75px;
}

.max-width-400px {
  max-width: 400px;
}

.max-width-600px {
  max-width: 600px;
}

.max-width-500px {
  max-width: 500px;
}

.max-width-800px {
  max-width: 800px;
}

.max-width-20p {
  max-width: 20pct;
}

.max-width-25p {
  max-width: 25pct;
}

.min-width-400px {
  min-width: 400px;
}

.min-width-650px {
  min-width: 650px;
}

.hide {
  display: none;
}

.line-height-1 {
  line-height: 1px;
}

.line-height-2 {
  line-height: 2px;
}

.line-height-3 {
  line-height: 3px;
}

.line-height-4 {
  line-height: 4px;
}

.line-height-5 {
  line-height: 5px;
}

.line-height-6 {
  line-height: 6px;
}

.line-height-7 {
  line-height: 7px;
}

.line-height-8 {
  line-height: 8px;
}

.line-height-9 {
  line-height: 9px;
}

.line-height-10 {
  line-height: 10px;
}

.line-height-11 {
  line-height: 11px;
}

.line-height-12 {
  line-height: 12px;
}

.line-height-13 {
  line-height: 13px;
}

.line-height-14 {
  line-height: 14px;
}

.line-height-15 {
  line-height: 15px;
}

.line-height-16 {
  line-height: 16px;
}

.line-height-17 {
  line-height: 17px;
}

.line-height-18 {
  line-height: 18px;
}

.line-height-19 {
  line-height: 19px;
}

.line-height-20 {
  line-height: 20px;
}

.line-height-21 {
  line-height: 21px;
}

.line-height-22 {
  line-height: 22px;
}

.line-height-23 {
  line-height: 23px;
}

.line-height-24 {
  line-height: 24px;
}

.line-height-25 {
  line-height: 25px;
}

.line-height-26 {
  line-height: 26px;
}

.line-height-27 {
  line-height: 27px;
}

.line-height-28 {
  line-height: 28px;
}

.line-height-29 {
  line-height: 29px;
}

.line-height-30 {
  line-height: 30px;
}

.line-height-31 {
  line-height: 31px;
}

.line-height-32 {
  line-height: 32px;
}

.line-height-33 {
  line-height: 33px;
}

.line-height-34 {
  line-height: 34px;
}

.line-height-35 {
  line-height: 35px;
}

.line-height-36 {
  line-height: 36px;
}

.line-height-37 {
  line-height: 37px;
}

.line-height-38 {
  line-height: 38px;
}

.line-height-39 {
  line-height: 39px;
}

.line-height-40 {
  line-height: 40px;
}

.margin-negative {
  margin: 0 -16px;
}

.margin-0 {
  margin: 0 !important;
}

.margin.t0 {
  margin-top: 0px !important;
}
.margin.t1 {
  margin-top: 1px !important;
}
.margin.t2 {
  margin-top: 2px !important;
}
.margin.t3 {
  margin-top: 3px !important;
}
.margin.t4 {
  margin-top: 4px !important;
}
.margin.t5 {
  margin-top: 5px !important;
}
.margin.t6 {
  margin-top: 6px !important;
}
.margin.t7 {
  margin-top: 7px !important;
}
.margin.t8 {
  margin-top: 8px !important;
}
.margin.t9 {
  margin-top: 9px !important;
}
.margin.t10 {
  margin-top: 10px !important;
}
.margin.t11 {
  margin-top: 11px !important;
}
.margin.t12 {
  margin-top: 12px !important;
}
.margin.t13 {
  margin-top: 13px !important;
}
.margin.t14 {
  margin-top: 14px !important;
}
.margin.t15 {
  margin-top: 15px !important;
}
.margin.t16 {
  margin-top: 16px !important;
}
.margin.t17 {
  margin-top: 17px !important;
}
.margin.t18 {
  margin-top: 18px !important;
}
.margin.t19 {
  margin-top: 19px !important;
}
.margin.t20 {
  margin-top: 20px !important;
}
.margin.t21 {
  margin-top: 21px !important;
}
.margin.t22 {
  margin-top: 22px !important;
}
.margin.t23 {
  margin-top: 23px !important;
}
.margin.t24 {
  margin-top: 24px !important;
}
.margin.t25 {
  margin-top: 25px !important;
}
.margin.t26 {
  margin-top: 26px !important;
}
.margin.t27 {
  margin-top: 27px !important;
}
.margin.t28 {
  margin-top: 28px !important;
}
.margin.t29 {
  margin-top: 29px !important;
}
.margin.t30 {
  margin-top: 30px !important;
}
.margin.t31 {
  margin-top: 31px !important;
}
.margin.t32 {
  margin-top: 32px !important;
}
.margin.t33 {
  margin-top: 33px !important;
}
.margin.t34 {
  margin-top: 34px !important;
}
.margin.t35 {
  margin-top: 35px !important;
}
.margin.t36 {
  margin-top: 36px !important;
}
.margin.t37 {
  margin-top: 37px !important;
}
.margin.t38 {
  margin-top: 38px !important;
}
.margin.t39 {
  margin-top: 39px !important;
}
.margin.t40 {
  margin-top: 40px !important;
}
.margin.b0 {
  margin-bottom: 0px;
}
.margin.b1 {
  margin-bottom: 1px;
}
.margin.b2 {
  margin-bottom: 2px;
}
.margin.b3 {
  margin-bottom: 3px;
}
.margin.b4 {
  margin-bottom: 4px;
}
.margin.b5 {
  margin-bottom: 5px;
}
.margin.b6 {
  margin-bottom: 6px;
}
.margin.b7 {
  margin-bottom: 7px;
}
.margin.b8 {
  margin-bottom: 8px;
}
.margin.b9 {
  margin-bottom: 9px;
}
.margin.b10 {
  margin-bottom: 10px;
}
.margin.b11 {
  margin-bottom: 11px;
}
.margin.b12 {
  margin-bottom: 12px;
}
.margin.b13 {
  margin-bottom: 13px;
}
.margin.b14 {
  margin-bottom: 14px;
}
.margin.b15 {
  margin-bottom: 15px;
}
.margin.b16 {
  margin-bottom: 16px;
}
.margin.b17 {
  margin-bottom: 17px;
}
.margin.b18 {
  margin-bottom: 18px;
}
.margin.b19 {
  margin-bottom: 19px;
}
.margin.b20 {
  margin-bottom: 20px;
}
.margin.b21 {
  margin-bottom: 21px;
}
.margin.b22 {
  margin-bottom: 22px;
}
.margin.b23 {
  margin-bottom: 23px;
}
.margin.b24 {
  margin-bottom: 24px;
}
.margin.b25 {
  margin-bottom: 25px;
}
.margin.b26 {
  margin-bottom: 26px;
}
.margin.b27 {
  margin-bottom: 27px;
}
.margin.b28 {
  margin-bottom: 28px;
}
.margin.b29 {
  margin-bottom: 29px;
}
.margin.b30 {
  margin-bottom: 30px;
}
.margin.b31 {
  margin-bottom: 31px;
}
.margin.b32 {
  margin-bottom: 32px;
}
.margin.b33 {
  margin-bottom: 33px;
}
.margin.b34 {
  margin-bottom: 34px;
}
.margin.b35 {
  margin-bottom: 35px;
}
.margin.b36 {
  margin-bottom: 36px;
}
.margin.b37 {
  margin-bottom: 37px;
}
.margin.b38 {
  margin-bottom: 38px;
}
.margin.b39 {
  margin-bottom: 39px;
}
.margin.b40 {
  margin-bottom: 40px;
}
.margin.l0 {
  margin-left: 0px;
}
.margin.l1 {
  margin-left: 1px;
}
.margin.l2 {
  margin-left: 2px;
}
.margin.l3 {
  margin-left: 3px;
}
.margin.l4 {
  margin-left: 4px;
}
.margin.l5 {
  margin-left: 5px;
}
.margin.l6 {
  margin-left: 6px;
}
.margin.l7 {
  margin-left: 7px;
}
.margin.l8 {
  margin-left: 8px;
}
.margin.l9 {
  margin-left: 9px;
}
.margin.l10 {
  margin-left: 10px;
}
.margin.l11 {
  margin-left: 11px;
}
.margin.l12 {
  margin-left: 12px;
}
.margin.l13 {
  margin-left: 13px;
}
.margin.l14 {
  margin-left: 14px;
}
.margin.l15 {
  margin-left: 15px;
}
.margin.l16 {
  margin-left: 16px;
}
.margin.l17 {
  margin-left: 17px;
}
.margin.l18 {
  margin-left: 18px;
}
.margin.l19 {
  margin-left: 19px;
}
.margin.l20 {
  margin-left: 20px;
}
.margin.l21 {
  margin-left: 21px;
}
.margin.l22 {
  margin-left: 22px;
}
.margin.l23 {
  margin-left: 23px;
}
.margin.l24 {
  margin-left: 24px;
}
.margin.l25 {
  margin-left: 25px;
}
.margin.l26 {
  margin-left: 26px;
}
.margin.l27 {
  margin-left: 27px;
}
.margin.l28 {
  margin-left: 28px;
}
.margin.l29 {
  margin-left: 29px;
}
.margin.l30 {
  margin-left: 30px;
}
.margin.l31 {
  margin-left: 31px;
}
.margin.l32 {
  margin-left: 32px;
}
.margin.l33 {
  margin-left: 33px;
}
.margin.l34 {
  margin-left: 34px;
}
.margin.l35 {
  margin-left: 35px;
}
.margin.l36 {
  margin-left: 36px;
}
.margin.l37 {
  margin-left: 37px;
}
.margin.l38 {
  margin-left: 38px;
}
.margin.l39 {
  margin-left: 39px;
}
.margin.l40 {
  margin-left: 40px;
}
.margin.r0 {
  margin-right: 0px;
}
.margin.r1 {
  margin-right: 1px;
}
.margin.r2 {
  margin-right: 2px;
}
.margin.r3 {
  margin-right: 3px;
}
.margin.r4 {
  margin-right: 4px;
}
.margin.r5 {
  margin-right: 5px;
}
.margin.r6 {
  margin-right: 6px;
}
.margin.r7 {
  margin-right: 7px;
}
.margin.r8 {
  margin-right: 8px;
}
.margin.r9 {
  margin-right: 9px;
}
.margin.r10 {
  margin-right: 10px;
}
.margin.r11 {
  margin-right: 11px;
}
.margin.r12 {
  margin-right: 12px;
}
.margin.r13 {
  margin-right: 13px;
}
.margin.r14 {
  margin-right: 14px;
}
.margin.r15 {
  margin-right: 15px;
}
.margin.r16 {
  margin-right: 16px;
}
.margin.r17 {
  margin-right: 17px;
}
.margin.r18 {
  margin-right: 18px;
}
.margin.r19 {
  margin-right: 19px;
}
.margin.r20 {
  margin-right: 20px;
}
.margin.r21 {
  margin-right: 21px;
}
.margin.r22 {
  margin-right: 22px;
}
.margin.r23 {
  margin-right: 23px;
}
.margin.r24 {
  margin-right: 24px;
}
.margin.r25 {
  margin-right: 25px;
}
.margin.r26 {
  margin-right: 26px;
}
.margin.r27 {
  margin-right: 27px;
}
.margin.r28 {
  margin-right: 28px;
}
.margin.r29 {
  margin-right: 29px;
}
.margin.r30 {
  margin-right: 30px;
}
.margin.r31 {
  margin-right: 31px;
}
.margin.r32 {
  margin-right: 32px;
}
.margin.r33 {
  margin-right: 33px;
}
.margin.r34 {
  margin-right: 34px;
}
.margin.r35 {
  margin-right: 35px;
}
.margin.r36 {
  margin-right: 36px;
}
.margin.r37 {
  margin-right: 37px;
}
.margin.r38 {
  margin-right: 38px;
}
.margin.r39 {
  margin-right: 39px;
}
.margin.r40 {
  margin-right: 40px;
}
.margin.negative.v1 {
  margin-top: -1px;
  margin-bottom: -1px;
}
.margin.negative.v2 {
  margin-top: -2px;
  margin-bottom: -2px;
}
.margin.negative.v3 {
  margin-top: -3px;
  margin-bottom: -3px;
}
.margin.negative.v4 {
  margin-top: -4px;
  margin-bottom: -4px;
}
.margin.negative.v5 {
  margin-top: -5px;
  margin-bottom: -5px;
}
.margin.negative.v6 {
  margin-top: -6px;
  margin-bottom: -6px;
}
.margin.negative.v7 {
  margin-top: -7px;
  margin-bottom: -7px;
}
.margin.negative.v8 {
  margin-top: -8px;
  margin-bottom: -8px;
}
.margin.negative.v9 {
  margin-top: -9px;
  margin-bottom: -9px;
}
.margin.negative.v10 {
  margin-top: -10px;
  margin-bottom: -10px;
}
.margin.negative.v11 {
  margin-top: -11px;
  margin-bottom: -11px;
}
.margin.negative.v12 {
  margin-top: -12px;
  margin-bottom: -12px;
}
.margin.negative.v13 {
  margin-top: -13px;
  margin-bottom: -13px;
}
.margin.negative.v14 {
  margin-top: -14px;
  margin-bottom: -14px;
}
.margin.negative.v15 {
  margin-top: -15px;
  margin-bottom: -15px;
}
.margin.negative.v16 {
  margin-top: -16px;
  margin-bottom: -16px;
}
.margin.negative.v17 {
  margin-top: -17px;
  margin-bottom: -17px;
}
.margin.negative.v18 {
  margin-top: -18px;
  margin-bottom: -18px;
}
.margin.negative.v19 {
  margin-top: -19px;
  margin-bottom: -19px;
}
.margin.negative.v20 {
  margin-top: -20px;
  margin-bottom: -20px;
}
.margin.negative.v21 {
  margin-top: -21px;
  margin-bottom: -21px;
}
.margin.negative.v22 {
  margin-top: -22px;
  margin-bottom: -22px;
}
.margin.negative.v23 {
  margin-top: -23px;
  margin-bottom: -23px;
}
.margin.negative.v24 {
  margin-top: -24px;
  margin-bottom: -24px;
}
.margin.negative.v25 {
  margin-top: -25px;
  margin-bottom: -25px;
}
.margin.negative.v26 {
  margin-top: -26px;
  margin-bottom: -26px;
}
.margin.negative.v27 {
  margin-top: -27px;
  margin-bottom: -27px;
}
.margin.negative.v28 {
  margin-top: -28px;
  margin-bottom: -28px;
}
.margin.negative.v29 {
  margin-top: -29px;
  margin-bottom: -29px;
}
.margin.negative.v30 {
  margin-top: -30px;
  margin-bottom: -30px;
}
.margin.negative.v31 {
  margin-top: -31px;
  margin-bottom: -31px;
}
.margin.negative.v32 {
  margin-top: -32px;
  margin-bottom: -32px;
}
.margin.negative.v33 {
  margin-top: -33px;
  margin-bottom: -33px;
}
.margin.negative.v34 {
  margin-top: -34px;
  margin-bottom: -34px;
}
.margin.negative.v35 {
  margin-top: -35px;
  margin-bottom: -35px;
}
.margin.negative.v36 {
  margin-top: -36px;
  margin-bottom: -36px;
}
.margin.negative.v37 {
  margin-top: -37px;
  margin-bottom: -37px;
}
.margin.negative.v38 {
  margin-top: -38px;
  margin-bottom: -38px;
}
.margin.negative.v39 {
  margin-top: -39px;
  margin-bottom: -39px;
}
.margin.negative.v40 {
  margin-top: -40px;
  margin-bottom: -40px;
}
.margin.negative.h1 {
  margin-right: -1px;
  margin-left: -1px;
}
.margin.negative.h2 {
  margin-right: -2px;
  margin-left: -2px;
}
.margin.negative.h3 {
  margin-right: -3px;
  margin-left: -3px;
}
.margin.negative.h4 {
  margin-right: -4px;
  margin-left: -4px;
}
.margin.negative.h5 {
  margin-right: -5px;
  margin-left: -5px;
}
.margin.negative.h6 {
  margin-right: -6px;
  margin-left: -6px;
}
.margin.negative.h7 {
  margin-right: -7px;
  margin-left: -7px;
}
.margin.negative.h8 {
  margin-right: -8px;
  margin-left: -8px;
}
.margin.negative.h9 {
  margin-right: -9px;
  margin-left: -9px;
}
.margin.negative.h10 {
  margin-right: -10px;
  margin-left: -10px;
}
.margin.negative.h11 {
  margin-right: -11px;
  margin-left: -11px;
}
.margin.negative.h12 {
  margin-right: -12px;
  margin-left: -12px;
}
.margin.negative.h13 {
  margin-right: -13px;
  margin-left: -13px;
}
.margin.negative.h14 {
  margin-right: -14px;
  margin-left: -14px;
}
.margin.negative.h15 {
  margin-right: -15px;
  margin-left: -15px;
}
.margin.negative.h16 {
  margin-right: -16px;
  margin-left: -16px;
}
.margin.negative.h17 {
  margin-right: -17px;
  margin-left: -17px;
}
.margin.negative.h18 {
  margin-right: -18px;
  margin-left: -18px;
}
.margin.negative.h19 {
  margin-right: -19px;
  margin-left: -19px;
}
.margin.negative.h20 {
  margin-right: -20px;
  margin-left: -20px;
}
.margin.negative.h21 {
  margin-right: -21px;
  margin-left: -21px;
}
.margin.negative.h22 {
  margin-right: -22px;
  margin-left: -22px;
}
.margin.negative.h23 {
  margin-right: -23px;
  margin-left: -23px;
}
.margin.negative.h24 {
  margin-right: -24px;
  margin-left: -24px;
}
.margin.negative.h25 {
  margin-right: -25px;
  margin-left: -25px;
}
.margin.negative.h26 {
  margin-right: -26px;
  margin-left: -26px;
}
.margin.negative.h27 {
  margin-right: -27px;
  margin-left: -27px;
}
.margin.negative.h28 {
  margin-right: -28px;
  margin-left: -28px;
}
.margin.negative.h29 {
  margin-right: -29px;
  margin-left: -29px;
}
.margin.negative.h30 {
  margin-right: -30px;
  margin-left: -30px;
}
.margin.negative.h31 {
  margin-right: -31px;
  margin-left: -31px;
}
.margin.negative.h32 {
  margin-right: -32px;
  margin-left: -32px;
}
.margin.negative.h33 {
  margin-right: -33px;
  margin-left: -33px;
}
.margin.negative.h34 {
  margin-right: -34px;
  margin-left: -34px;
}
.margin.negative.h35 {
  margin-right: -35px;
  margin-left: -35px;
}
.margin.negative.h36 {
  margin-right: -36px;
  margin-left: -36px;
}
.margin.negative.h37 {
  margin-right: -37px;
  margin-left: -37px;
}
.margin.negative.h38 {
  margin-right: -38px;
  margin-left: -38px;
}
.margin.negative.h39 {
  margin-right: -39px;
  margin-left: -39px;
}
.margin.negative.h40 {
  margin-right: -40px;
  margin-left: -40px;
}
.margin.tm8 {
  margin-top: -8px;
}
.margin.t2p {
  margin-top: 2%;
}
.margin.rm20 {
  margin-right: -20px;
}
.margin.l2p {
  margin-left: 2%;
}
.margin.l4p {
  margin-left: 4%;
}
.margin.l5p {
  margin-left: 5%;
}
.margin.l10p {
  margin-left: 10%;
}
.margin.l15p {
  margin-left: 15%;
}

.padding.t0 {
  padding-top: 0px;
}
.padding.t1 {
  padding-top: 1px;
}
.padding.t2 {
  padding-top: 2px;
}
.padding.t3 {
  padding-top: 3px;
}
.padding.t4 {
  padding-top: 4px;
}
.padding.t5 {
  padding-top: 5px;
}
.padding.t6 {
  padding-top: 6px;
}
.padding.t7 {
  padding-top: 7px;
}
.padding.t8 {
  padding-top: 8px;
}
.padding.t9 {
  padding-top: 9px;
}
.padding.t10 {
  padding-top: 10px;
}
.padding.t11 {
  padding-top: 11px;
}
.padding.t12 {
  padding-top: 12px;
}
.padding.t13 {
  padding-top: 13px;
}
.padding.t14 {
  padding-top: 14px;
}
.padding.t15 {
  padding-top: 15px;
}
.padding.t16 {
  padding-top: 16px;
}
.padding.t17 {
  padding-top: 17px;
}
.padding.t18 {
  padding-top: 18px;
}
.padding.t19 {
  padding-top: 19px;
}
.padding.t20 {
  padding-top: 20px;
}
.padding.t21 {
  padding-top: 21px;
}
.padding.t22 {
  padding-top: 22px;
}
.padding.t23 {
  padding-top: 23px;
}
.padding.t24 {
  padding-top: 24px;
}
.padding.t25 {
  padding-top: 25px;
}
.padding.t26 {
  padding-top: 26px;
}
.padding.t27 {
  padding-top: 27px;
}
.padding.t28 {
  padding-top: 28px;
}
.padding.t29 {
  padding-top: 29px;
}
.padding.t30 {
  padding-top: 30px;
}
.padding.t31 {
  padding-top: 31px;
}
.padding.t32 {
  padding-top: 32px;
}
.padding.t33 {
  padding-top: 33px;
}
.padding.t34 {
  padding-top: 34px;
}
.padding.t35 {
  padding-top: 35px;
}
.padding.t36 {
  padding-top: 36px;
}
.padding.t37 {
  padding-top: 37px;
}
.padding.t38 {
  padding-top: 38px;
}
.padding.t39 {
  padding-top: 39px;
}
.padding.t40 {
  padding-top: 40px;
}
.padding.b0 {
  padding-bottom: 0px;
}
.padding.b1 {
  padding-bottom: 1px;
}
.padding.b2 {
  padding-bottom: 2px;
}
.padding.b3 {
  padding-bottom: 3px;
}
.padding.b4 {
  padding-bottom: 4px;
}
.padding.b5 {
  padding-bottom: 5px;
}
.padding.b6 {
  padding-bottom: 6px;
}
.padding.b7 {
  padding-bottom: 7px;
}
.padding.b8 {
  padding-bottom: 8px;
}
.padding.b9 {
  padding-bottom: 9px;
}
.padding.b10 {
  padding-bottom: 10px;
}
.padding.b11 {
  padding-bottom: 11px;
}
.padding.b12 {
  padding-bottom: 12px;
}
.padding.b13 {
  padding-bottom: 13px;
}
.padding.b14 {
  padding-bottom: 14px;
}
.padding.b15 {
  padding-bottom: 15px;
}
.padding.b16 {
  padding-bottom: 16px;
}
.padding.b17 {
  padding-bottom: 17px;
}
.padding.b18 {
  padding-bottom: 18px;
}
.padding.b19 {
  padding-bottom: 19px;
}
.padding.b20 {
  padding-bottom: 20px;
}
.padding.b21 {
  padding-bottom: 21px;
}
.padding.b22 {
  padding-bottom: 22px;
}
.padding.b23 {
  padding-bottom: 23px;
}
.padding.b24 {
  padding-bottom: 24px;
}
.padding.b25 {
  padding-bottom: 25px;
}
.padding.b26 {
  padding-bottom: 26px;
}
.padding.b27 {
  padding-bottom: 27px;
}
.padding.b28 {
  padding-bottom: 28px;
}
.padding.b29 {
  padding-bottom: 29px;
}
.padding.b30 {
  padding-bottom: 30px;
}
.padding.b31 {
  padding-bottom: 31px;
}
.padding.b32 {
  padding-bottom: 32px;
}
.padding.b33 {
  padding-bottom: 33px;
}
.padding.b34 {
  padding-bottom: 34px;
}
.padding.b35 {
  padding-bottom: 35px;
}
.padding.b36 {
  padding-bottom: 36px;
}
.padding.b37 {
  padding-bottom: 37px;
}
.padding.b38 {
  padding-bottom: 38px;
}
.padding.b39 {
  padding-bottom: 39px;
}
.padding.b40 {
  padding-bottom: 40px;
}
.padding.l0 {
  padding-left: 0px;
}
.padding.l1 {
  padding-left: 1px;
}
.padding.l2 {
  padding-left: 2px;
}
.padding.l3 {
  padding-left: 3px;
}
.padding.l4 {
  padding-left: 4px;
}
.padding.l5 {
  padding-left: 5px;
}
.padding.l6 {
  padding-left: 6px;
}
.padding.l7 {
  padding-left: 7px;
}
.padding.l8 {
  padding-left: 8px;
}
.padding.l9 {
  padding-left: 9px;
}
.padding.l10 {
  padding-left: 10px;
}
.padding.l11 {
  padding-left: 11px;
}
.padding.l12 {
  padding-left: 12px;
}
.padding.l13 {
  padding-left: 13px;
}
.padding.l14 {
  padding-left: 14px;
}
.padding.l15 {
  padding-left: 15px;
}
.padding.l16 {
  padding-left: 16px;
}
.padding.l17 {
  padding-left: 17px;
}
.padding.l18 {
  padding-left: 18px;
}
.padding.l19 {
  padding-left: 19px;
}
.padding.l20 {
  padding-left: 20px;
}
.padding.l21 {
  padding-left: 21px;
}
.padding.l22 {
  padding-left: 22px;
}
.padding.l23 {
  padding-left: 23px;
}
.padding.l24 {
  padding-left: 24px;
}
.padding.l25 {
  padding-left: 25px;
}
.padding.l26 {
  padding-left: 26px;
}
.padding.l27 {
  padding-left: 27px;
}
.padding.l28 {
  padding-left: 28px;
}
.padding.l29 {
  padding-left: 29px;
}
.padding.l30 {
  padding-left: 30px;
}
.padding.l31 {
  padding-left: 31px;
}
.padding.l32 {
  padding-left: 32px;
}
.padding.l33 {
  padding-left: 33px;
}
.padding.l34 {
  padding-left: 34px;
}
.padding.l35 {
  padding-left: 35px;
}
.padding.l36 {
  padding-left: 36px;
}
.padding.l37 {
  padding-left: 37px;
}
.padding.l38 {
  padding-left: 38px;
}
.padding.l39 {
  padding-left: 39px;
}
.padding.l40 {
  padding-left: 40px;
}
.padding.r0 {
  padding-right: 0px;
}
.padding.r1 {
  padding-right: 1px;
}
.padding.r2 {
  padding-right: 2px;
}
.padding.r3 {
  padding-right: 3px;
}
.padding.r4 {
  padding-right: 4px;
}
.padding.r5 {
  padding-right: 5px;
}
.padding.r6 {
  padding-right: 6px;
}
.padding.r7 {
  padding-right: 7px;
}
.padding.r8 {
  padding-right: 8px;
}
.padding.r9 {
  padding-right: 9px;
}
.padding.r10 {
  padding-right: 10px;
}
.padding.r11 {
  padding-right: 11px;
}
.padding.r12 {
  padding-right: 12px;
}
.padding.r13 {
  padding-right: 13px;
}
.padding.r14 {
  padding-right: 14px;
}
.padding.r15 {
  padding-right: 15px;
}
.padding.r16 {
  padding-right: 16px;
}
.padding.r17 {
  padding-right: 17px;
}
.padding.r18 {
  padding-right: 18px;
}
.padding.r19 {
  padding-right: 19px;
}
.padding.r20 {
  padding-right: 20px;
}
.padding.r21 {
  padding-right: 21px;
}
.padding.r22 {
  padding-right: 22px;
}
.padding.r23 {
  padding-right: 23px;
}
.padding.r24 {
  padding-right: 24px;
}
.padding.r25 {
  padding-right: 25px;
}
.padding.r26 {
  padding-right: 26px;
}
.padding.r27 {
  padding-right: 27px;
}
.padding.r28 {
  padding-right: 28px;
}
.padding.r29 {
  padding-right: 29px;
}
.padding.r30 {
  padding-right: 30px;
}
.padding.r31 {
  padding-right: 31px;
}
.padding.r32 {
  padding-right: 32px;
}
.padding.r33 {
  padding-right: 33px;
}
.padding.r34 {
  padding-right: 34px;
}
.padding.r35 {
  padding-right: 35px;
}
.padding.r36 {
  padding-right: 36px;
}
.padding.r37 {
  padding-right: 37px;
}
.padding.r38 {
  padding-right: 38px;
}
.padding.r39 {
  padding-right: 39px;
}
.padding.r40 {
  padding-right: 40px;
}
.padding.tbp8 {
  padding: 8px 0;
}
.padding.tp25 {
  padding-top: 25px;
}
.padding.lp30 {
  padding-left: 30px;
}
.padding.bp70 {
  padding-bottom: 70px !important;
}

.input-right {
  text-align: right !important;
}

.wide-input-field {
  width: 400px;
}

.s18 {
  font-size: 18px;
  line-height: 24px;
  vertical-align: middle;
  text-align: center;
}

.large-checkmark {
  font-size: 72px;
  line-height: 24px;
  margin-right: 50px;
  margin-top: 25px;
}

.search-close-button {
  top: auto !important;
}

button.mat-mdc-icon-button svg {
  width: 26px;
}

.mat-toolbar button.mat-mdc-icon-button {
  line-height: 24px;
}

.mat-mdc-icon-button .mat-icon {
  vertical-align: middle;
}

.size-150 {
  max-width: 150px;
  max-height: 150px;
}

.size-90 {
  max-width: 90px;
}

.size-50 {
  max-height: 50px;
  max-width: 150px;
}

.acquirer-logo {
  max-width: 150px;
  max-height: 30px;
}

.acquirer-logo-small {
  max-height: 30px;
  max-width: 50px;
}

.header-logo img {
  height: 47px;
}

ol.no-indent,
ul.no-indent {
  padding-left: 15px;
}

.multiline-list-item {
  height: auto !important;
}

.nav-item-text {
  font-family: "Inter-SemiBold", "arial", sans-serif !important;
  font-size: 14px !important;
  line-height: 20px !important;
}

@media print {
  * {
    overflow: visible !important;
  }
  @page {
    margin: 0;
    margin-top: 25px;
    margin-bottom: 25px;
  }
  body {
    width: 100% !important;
    margin: 0 !important;
    padding: 0 !important;
    line-height: 1.45;
    color: #000;
    background: none;
    font-size: 12pt;
    padding-bottom: 50px;
  }
  mat-toolbar,
  .mat-fab,
  #main-topmenu,
  mat-sidenav,
  loading-spinner {
    display: none !important;
  }
  .page-container[_ngcontent-c0] {
    top: 0 !important;
  }
  mat-sidenav-content {
    margin-left: 0 !important;
  }
  mat-card-content,
  .mat-drawer-content,
  .mat-table {
    overflow: visible !important;
  }
  .main-content-layout {
    width: 100%;
    margin: 0 !important;
  }
  .no-print {
    display: none !important;
  }
  .print {
    display: block !important;
  }
  mat-card[class^=card02],
  mat-card[class*=" card02"] {
    box-shadow: none !important;
  }
  mat-card {
    margin: 0 !important;
  }
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    page-break-after: avoid;
  }
  h1 {
    font-size: 19pt;
  }
  h2 {
    font-size: 17pt;
  }
  h3 {
    font-size: 15pt;
  }
  h4,
  h5,
  h6 {
    font-size: 14pt;
  }
  p,
  h2,
  h3 {
    orphans: 3;
    widows: 3;
  }
  code {
    font: 12pt Courier, monospace;
  }
  blockquote {
    margin: 1.2em;
    padding: 1em;
    font-size: 12pt;
  }
  hr {
    background-color: #CCC;
  }
  img {
    float: left;
    margin: 1em 1.5em 1.5em 0;
    max-width: 100% !important;
    page-break-inside: avoid;
  }
  a img {
    border: none;
  }
  a:link,
  a:visited {
    background: transparent;
    font-weight: 700;
    text-decoration: underline;
    color: #333;
  }
  a:link[href^="http://"]::after,
  a[href^="http://"]:visited::after {
    content: " (" attr(href) ") ";
    font-size: 90%;
  }
  abbr[title]::after {
    content: " (" attr(title) ")";
  }
  a[href^="http://"] {
    color: #000;
  }
  a[href$=".jpg"]::after,
  a[href$=".jpeg"]::after,
  a[href$=".gif"]::after,
  a[href$=".png"]::after {
    content: " (" attr(href) ") ";
    display: none;
  }
  a[href^="#"]::after,
  a[href^="javascript:"]::after {
    content: "";
  }
  .avoid-page-break {
    page-break-inside: avoid;
  }
  table {
    margin: 1px;
    margin-bottom: 15px;
    margin-top: 10px;
    text-align: left;
  }
  table th {
    border-bottom: 1px solid #333;
    font-weight: bold;
    padding: 4px 10px 4px 0 !important;
  }
  table th.md-checkbox-column {
    display: none !important;
  }
  table td {
    border-bottom: 1px solid #333;
    padding: 4px 10px 4px 0 !important;
  }
  table td.md-checkbox-cell {
    display: none !important;
  }
  table tr {
    page-break-before: auto;
  }
  table caption {
    background: #FFF;
    margin-bottom: 2em;
    text-align: left;
  }
  table thead {
    display: table-row-group;
  }
  table tfoot {
    font-style: italic;
    display: table-footer-group;
  }
}
.truncate {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.truncate-100 {
  max-width: calc(100 * 1px) !important;
  max-width: calc(100 * 1px) !important;
  max-width: calc(100 * 1px) !important;
}

.truncate-130 {
  max-width: calc(130 * 1px) !important;
  max-width: calc(130 * 1px) !important;
  max-width: calc(130 * 1px) !important;
}

.truncate-150 {
  max-width: calc(150 * 1px) !important;
  max-width: calc(150 * 1px) !important;
  max-width: calc(150 * 1px) !important;
}

.truncate-200 {
  max-width: calc(200 * 1px) !important;
  max-width: calc(200 * 1px) !important;
  max-width: calc(200 * 1px) !important;
}

.truncate-250 {
  max-width: calc(250 * 1px) !important;
  max-width: calc(250 * 1px) !important;
  max-width: calc(250 * 1px) !important;
}

.truncate-300 {
  max-width: calc(300 * 1px) !important;
  max-width: calc(300 * 1px) !important;
  max-width: calc(300 * 1px) !important;
}

.truncate-350 {
  max-width: calc(350 * 1px) !important;
  max-width: calc(350 * 1px) !important;
  max-width: calc(350 * 1px) !important;
}

.truncate-400 {
  max-width: calc(400 * 1px) !important;
  max-width: calc(400 * 1px) !important;
  max-width: calc(400 * 1px) !important;
}

@media (min-width: 0) {
  .p-0 {
    padding: 0px !important;
  }
  .pt-0 {
    padding-top: 0px !important;
  }
  .pl-0 {
    padding-left: 0px !important;
  }
  .pr-0 {
    padding-right: 0px !important;
  }
  .pb-0 {
    padding-bottom: 0px !important;
  }
  .px-0 {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }
  .py-0 {
    padding-top: 0px !important;
    padding-bottom: 0px !important;
  }
  .m-0 {
    margin: 0px !important;
  }
  .mt-0 {
    margin-top: 0px !important;
  }
  .ml-0 {
    margin-left: 0px !important;
  }
  .mr-0 {
    margin-right: 0px !important;
  }
  .mb-0 {
    margin-bottom: 0px !important;
  }
  .mx-0 {
    margin-left: 0px !important;
    margin-right: 0px !important;
  }
  .my-0 {
    margin-top: 0px !important;
    margin-bottom: 0px !important;
  }
  .p-1 {
    padding: 8px !important;
  }
  .pt-1 {
    padding-top: 8px !important;
  }
  .pl-1 {
    padding-left: 8px !important;
  }
  .pr-1 {
    padding-right: 8px !important;
  }
  .pb-1 {
    padding-bottom: 8px !important;
  }
  .px-1 {
    padding-left: 8px !important;
    padding-right: 8px !important;
  }
  .py-1 {
    padding-top: 8px !important;
    padding-bottom: 8px !important;
  }
  .m-1 {
    margin: 8px !important;
  }
  .mt-1 {
    margin-top: 8px !important;
  }
  .ml-1 {
    margin-left: 8px !important;
  }
  .mr-1 {
    margin-right: 8px !important;
  }
  .mb-1 {
    margin-bottom: 8px !important;
  }
  .mx-1 {
    margin-left: 8px !important;
    margin-right: 8px !important;
  }
  .my-1 {
    margin-top: 8px !important;
    margin-bottom: 8px !important;
  }
  .p-2 {
    padding: 16px !important;
  }
  .pt-2 {
    padding-top: 16px !important;
  }
  .pl-2 {
    padding-left: 16px !important;
  }
  .pr-2 {
    padding-right: 16px !important;
  }
  .pb-2 {
    padding-bottom: 16px !important;
  }
  .px-2 {
    padding-left: 16px !important;
    padding-right: 16px !important;
  }
  .py-2 {
    padding-top: 16px !important;
    padding-bottom: 16px !important;
  }
  .m-2 {
    margin: 16px !important;
  }
  .mt-2 {
    margin-top: 16px !important;
  }
  .ml-2 {
    margin-left: 16px !important;
  }
  .mr-2 {
    margin-right: 16px !important;
  }
  .mb-2 {
    margin-bottom: 16px !important;
  }
  .mx-2 {
    margin-left: 16px !important;
    margin-right: 16px !important;
  }
  .my-2 {
    margin-top: 16px !important;
    margin-bottom: 16px !important;
  }
  .p-3 {
    padding: 24px !important;
  }
  .pt-3 {
    padding-top: 24px !important;
  }
  .pl-3 {
    padding-left: 24px !important;
  }
  .pr-3 {
    padding-right: 24px !important;
  }
  .pb-3 {
    padding-bottom: 24px !important;
  }
  .px-3 {
    padding-left: 24px !important;
    padding-right: 24px !important;
  }
  .py-3 {
    padding-top: 24px !important;
    padding-bottom: 24px !important;
  }
  .m-3 {
    margin: 24px !important;
  }
  .mt-3 {
    margin-top: 24px !important;
  }
  .ml-3 {
    margin-left: 24px !important;
  }
  .mr-3 {
    margin-right: 24px !important;
  }
  .mb-3 {
    margin-bottom: 24px !important;
  }
  .mx-3 {
    margin-left: 24px !important;
    margin-right: 24px !important;
  }
  .my-3 {
    margin-top: 24px !important;
    margin-bottom: 24px !important;
  }
  .p-4 {
    padding: 32px !important;
  }
  .pt-4 {
    padding-top: 32px !important;
  }
  .pl-4 {
    padding-left: 32px !important;
  }
  .pr-4 {
    padding-right: 32px !important;
  }
  .pb-4 {
    padding-bottom: 32px !important;
  }
  .px-4 {
    padding-left: 32px !important;
    padding-right: 32px !important;
  }
  .py-4 {
    padding-top: 32px !important;
    padding-bottom: 32px !important;
  }
  .m-4 {
    margin: 32px !important;
  }
  .mt-4 {
    margin-top: 32px !important;
  }
  .ml-4 {
    margin-left: 32px !important;
  }
  .mr-4 {
    margin-right: 32px !important;
  }
  .mb-4 {
    margin-bottom: 32px !important;
  }
  .mx-4 {
    margin-left: 32px !important;
    margin-right: 32px !important;
  }
  .my-4 {
    margin-top: 32px !important;
    margin-bottom: 32px !important;
  }
  .p-5 {
    padding: 40px !important;
  }
  .pt-5 {
    padding-top: 40px !important;
  }
  .pl-5 {
    padding-left: 40px !important;
  }
  .pr-5 {
    padding-right: 40px !important;
  }
  .pb-5 {
    padding-bottom: 40px !important;
  }
  .px-5 {
    padding-left: 40px !important;
    padding-right: 40px !important;
  }
  .py-5 {
    padding-top: 40px !important;
    padding-bottom: 40px !important;
  }
  .m-5 {
    margin: 40px !important;
  }
  .mt-5 {
    margin-top: 40px !important;
  }
  .ml-5 {
    margin-left: 40px !important;
  }
  .mr-5 {
    margin-right: 40px !important;
  }
  .mb-5 {
    margin-bottom: 40px !important;
  }
  .mx-5 {
    margin-left: 40px !important;
    margin-right: 40px !important;
  }
  .my-5 {
    margin-top: 40px !important;
    margin-bottom: 40px !important;
  }
  .p-6 {
    padding: 48px !important;
  }
  .pt-6 {
    padding-top: 48px !important;
  }
  .pl-6 {
    padding-left: 48px !important;
  }
  .pr-6 {
    padding-right: 48px !important;
  }
  .pb-6 {
    padding-bottom: 48px !important;
  }
  .px-6 {
    padding-left: 48px !important;
    padding-right: 48px !important;
  }
  .py-6 {
    padding-top: 48px !important;
    padding-bottom: 48px !important;
  }
  .m-6 {
    margin: 48px !important;
  }
  .mt-6 {
    margin-top: 48px !important;
  }
  .ml-6 {
    margin-left: 48px !important;
  }
  .mr-6 {
    margin-right: 48px !important;
  }
  .mb-6 {
    margin-bottom: 48px !important;
  }
  .mx-6 {
    margin-left: 48px !important;
    margin-right: 48px !important;
  }
  .my-6 {
    margin-top: 48px !important;
    margin-bottom: 48px !important;
  }
  .p-7 {
    padding: 56px !important;
  }
  .pt-7 {
    padding-top: 56px !important;
  }
  .pl-7 {
    padding-left: 56px !important;
  }
  .pr-7 {
    padding-right: 56px !important;
  }
  .pb-7 {
    padding-bottom: 56px !important;
  }
  .px-7 {
    padding-left: 56px !important;
    padding-right: 56px !important;
  }
  .py-7 {
    padding-top: 56px !important;
    padding-bottom: 56px !important;
  }
  .m-7 {
    margin: 56px !important;
  }
  .mt-7 {
    margin-top: 56px !important;
  }
  .ml-7 {
    margin-left: 56px !important;
  }
  .mr-7 {
    margin-right: 56px !important;
  }
  .mb-7 {
    margin-bottom: 56px !important;
  }
  .mx-7 {
    margin-left: 56px !important;
    margin-right: 56px !important;
  }
  .my-7 {
    margin-top: 56px !important;
    margin-bottom: 56px !important;
  }
  .p-8 {
    padding: 64px !important;
  }
  .pt-8 {
    padding-top: 64px !important;
  }
  .pl-8 {
    padding-left: 64px !important;
  }
  .pr-8 {
    padding-right: 64px !important;
  }
  .pb-8 {
    padding-bottom: 64px !important;
  }
  .px-8 {
    padding-left: 64px !important;
    padding-right: 64px !important;
  }
  .py-8 {
    padding-top: 64px !important;
    padding-bottom: 64px !important;
  }
  .m-8 {
    margin: 64px !important;
  }
  .mt-8 {
    margin-top: 64px !important;
  }
  .ml-8 {
    margin-left: 64px !important;
  }
  .mr-8 {
    margin-right: 64px !important;
  }
  .mb-8 {
    margin-bottom: 64px !important;
  }
  .mx-8 {
    margin-left: 64px !important;
    margin-right: 64px !important;
  }
  .my-8 {
    margin-top: 64px !important;
    margin-bottom: 64px !important;
  }
}
@media (min-width: 600px) {
  .sm-p-0 {
    padding: 0px !important;
  }
  .sm-pt-0 {
    padding-top: 0px !important;
  }
  .sm-pl-0 {
    padding-left: 0px !important;
  }
  .sm-pr-0 {
    padding-right: 0px !important;
  }
  .sm-pb-0 {
    padding-bottom: 0px !important;
  }
  .sm-px-0 {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }
  .sm-py-0 {
    padding-top: 0px !important;
    padding-bottom: 0px !important;
  }
  .sm-m-0 {
    margin: 0px !important;
  }
  .sm-mt-0 {
    margin-top: 0px !important;
  }
  .sm-ml-0 {
    margin-left: 0px !important;
  }
  .sm-mr-0 {
    margin-right: 0px !important;
  }
  .sm-mb-0 {
    margin-bottom: 0px !important;
  }
  .sm-mx-0 {
    margin-left: 0px !important;
    margin-right: 0px !important;
  }
  .sm-my-0 {
    margin-top: 0px !important;
    margin-bottom: 0px !important;
  }
  .sm-p-1 {
    padding: 8px !important;
  }
  .sm-pt-1 {
    padding-top: 8px !important;
  }
  .sm-pl-1 {
    padding-left: 8px !important;
  }
  .sm-pr-1 {
    padding-right: 8px !important;
  }
  .sm-pb-1 {
    padding-bottom: 8px !important;
  }
  .sm-px-1 {
    padding-left: 8px !important;
    padding-right: 8px !important;
  }
  .sm-py-1 {
    padding-top: 8px !important;
    padding-bottom: 8px !important;
  }
  .sm-m-1 {
    margin: 8px !important;
  }
  .sm-mt-1 {
    margin-top: 8px !important;
  }
  .sm-ml-1 {
    margin-left: 8px !important;
  }
  .sm-mr-1 {
    margin-right: 8px !important;
  }
  .sm-mb-1 {
    margin-bottom: 8px !important;
  }
  .sm-mx-1 {
    margin-left: 8px !important;
    margin-right: 8px !important;
  }
  .sm-my-1 {
    margin-top: 8px !important;
    margin-bottom: 8px !important;
  }
  .sm-p-2 {
    padding: 16px !important;
  }
  .sm-pt-2 {
    padding-top: 16px !important;
  }
  .sm-pl-2 {
    padding-left: 16px !important;
  }
  .sm-pr-2 {
    padding-right: 16px !important;
  }
  .sm-pb-2 {
    padding-bottom: 16px !important;
  }
  .sm-px-2 {
    padding-left: 16px !important;
    padding-right: 16px !important;
  }
  .sm-py-2 {
    padding-top: 16px !important;
    padding-bottom: 16px !important;
  }
  .sm-m-2 {
    margin: 16px !important;
  }
  .sm-mt-2 {
    margin-top: 16px !important;
  }
  .sm-ml-2 {
    margin-left: 16px !important;
  }
  .sm-mr-2 {
    margin-right: 16px !important;
  }
  .sm-mb-2 {
    margin-bottom: 16px !important;
  }
  .sm-mx-2 {
    margin-left: 16px !important;
    margin-right: 16px !important;
  }
  .sm-my-2 {
    margin-top: 16px !important;
    margin-bottom: 16px !important;
  }
  .sm-p-3 {
    padding: 24px !important;
  }
  .sm-pt-3 {
    padding-top: 24px !important;
  }
  .sm-pl-3 {
    padding-left: 24px !important;
  }
  .sm-pr-3 {
    padding-right: 24px !important;
  }
  .sm-pb-3 {
    padding-bottom: 24px !important;
  }
  .sm-px-3 {
    padding-left: 24px !important;
    padding-right: 24px !important;
  }
  .sm-py-3 {
    padding-top: 24px !important;
    padding-bottom: 24px !important;
  }
  .sm-m-3 {
    margin: 24px !important;
  }
  .sm-mt-3 {
    margin-top: 24px !important;
  }
  .sm-ml-3 {
    margin-left: 24px !important;
  }
  .sm-mr-3 {
    margin-right: 24px !important;
  }
  .sm-mb-3 {
    margin-bottom: 24px !important;
  }
  .sm-mx-3 {
    margin-left: 24px !important;
    margin-right: 24px !important;
  }
  .sm-my-3 {
    margin-top: 24px !important;
    margin-bottom: 24px !important;
  }
  .sm-p-4 {
    padding: 32px !important;
  }
  .sm-pt-4 {
    padding-top: 32px !important;
  }
  .sm-pl-4 {
    padding-left: 32px !important;
  }
  .sm-pr-4 {
    padding-right: 32px !important;
  }
  .sm-pb-4 {
    padding-bottom: 32px !important;
  }
  .sm-px-4 {
    padding-left: 32px !important;
    padding-right: 32px !important;
  }
  .sm-py-4 {
    padding-top: 32px !important;
    padding-bottom: 32px !important;
  }
  .sm-m-4 {
    margin: 32px !important;
  }
  .sm-mt-4 {
    margin-top: 32px !important;
  }
  .sm-ml-4 {
    margin-left: 32px !important;
  }
  .sm-mr-4 {
    margin-right: 32px !important;
  }
  .sm-mb-4 {
    margin-bottom: 32px !important;
  }
  .sm-mx-4 {
    margin-left: 32px !important;
    margin-right: 32px !important;
  }
  .sm-my-4 {
    margin-top: 32px !important;
    margin-bottom: 32px !important;
  }
  .sm-p-5 {
    padding: 40px !important;
  }
  .sm-pt-5 {
    padding-top: 40px !important;
  }
  .sm-pl-5 {
    padding-left: 40px !important;
  }
  .sm-pr-5 {
    padding-right: 40px !important;
  }
  .sm-pb-5 {
    padding-bottom: 40px !important;
  }
  .sm-px-5 {
    padding-left: 40px !important;
    padding-right: 40px !important;
  }
  .sm-py-5 {
    padding-top: 40px !important;
    padding-bottom: 40px !important;
  }
  .sm-m-5 {
    margin: 40px !important;
  }
  .sm-mt-5 {
    margin-top: 40px !important;
  }
  .sm-ml-5 {
    margin-left: 40px !important;
  }
  .sm-mr-5 {
    margin-right: 40px !important;
  }
  .sm-mb-5 {
    margin-bottom: 40px !important;
  }
  .sm-mx-5 {
    margin-left: 40px !important;
    margin-right: 40px !important;
  }
  .sm-my-5 {
    margin-top: 40px !important;
    margin-bottom: 40px !important;
  }
  .sm-p-6 {
    padding: 48px !important;
  }
  .sm-pt-6 {
    padding-top: 48px !important;
  }
  .sm-pl-6 {
    padding-left: 48px !important;
  }
  .sm-pr-6 {
    padding-right: 48px !important;
  }
  .sm-pb-6 {
    padding-bottom: 48px !important;
  }
  .sm-px-6 {
    padding-left: 48px !important;
    padding-right: 48px !important;
  }
  .sm-py-6 {
    padding-top: 48px !important;
    padding-bottom: 48px !important;
  }
  .sm-m-6 {
    margin: 48px !important;
  }
  .sm-mt-6 {
    margin-top: 48px !important;
  }
  .sm-ml-6 {
    margin-left: 48px !important;
  }
  .sm-mr-6 {
    margin-right: 48px !important;
  }
  .sm-mb-6 {
    margin-bottom: 48px !important;
  }
  .sm-mx-6 {
    margin-left: 48px !important;
    margin-right: 48px !important;
  }
  .sm-my-6 {
    margin-top: 48px !important;
    margin-bottom: 48px !important;
  }
  .sm-p-7 {
    padding: 56px !important;
  }
  .sm-pt-7 {
    padding-top: 56px !important;
  }
  .sm-pl-7 {
    padding-left: 56px !important;
  }
  .sm-pr-7 {
    padding-right: 56px !important;
  }
  .sm-pb-7 {
    padding-bottom: 56px !important;
  }
  .sm-px-7 {
    padding-left: 56px !important;
    padding-right: 56px !important;
  }
  .sm-py-7 {
    padding-top: 56px !important;
    padding-bottom: 56px !important;
  }
  .sm-m-7 {
    margin: 56px !important;
  }
  .sm-mt-7 {
    margin-top: 56px !important;
  }
  .sm-ml-7 {
    margin-left: 56px !important;
  }
  .sm-mr-7 {
    margin-right: 56px !important;
  }
  .sm-mb-7 {
    margin-bottom: 56px !important;
  }
  .sm-mx-7 {
    margin-left: 56px !important;
    margin-right: 56px !important;
  }
  .sm-my-7 {
    margin-top: 56px !important;
    margin-bottom: 56px !important;
  }
  .sm-p-8 {
    padding: 64px !important;
  }
  .sm-pt-8 {
    padding-top: 64px !important;
  }
  .sm-pl-8 {
    padding-left: 64px !important;
  }
  .sm-pr-8 {
    padding-right: 64px !important;
  }
  .sm-pb-8 {
    padding-bottom: 64px !important;
  }
  .sm-px-8 {
    padding-left: 64px !important;
    padding-right: 64px !important;
  }
  .sm-py-8 {
    padding-top: 64px !important;
    padding-bottom: 64px !important;
  }
  .sm-m-8 {
    margin: 64px !important;
  }
  .sm-mt-8 {
    margin-top: 64px !important;
  }
  .sm-ml-8 {
    margin-left: 64px !important;
  }
  .sm-mr-8 {
    margin-right: 64px !important;
  }
  .sm-mb-8 {
    margin-bottom: 64px !important;
  }
  .sm-mx-8 {
    margin-left: 64px !important;
    margin-right: 64px !important;
  }
  .sm-my-8 {
    margin-top: 64px !important;
    margin-bottom: 64px !important;
  }
}
@media (min-width: 960px) {
  .md-p-0 {
    padding: 0px !important;
  }
  .md-pt-0 {
    padding-top: 0px !important;
  }
  .md-pl-0 {
    padding-left: 0px !important;
  }
  .md-pr-0 {
    padding-right: 0px !important;
  }
  .md-pb-0 {
    padding-bottom: 0px !important;
  }
  .md-px-0 {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }
  .md-py-0 {
    padding-top: 0px !important;
    padding-bottom: 0px !important;
  }
  .md-m-0 {
    margin: 0px !important;
  }
  .md-mt-0 {
    margin-top: 0px !important;
  }
  .md-ml-0 {
    margin-left: 0px !important;
  }
  .md-mr-0 {
    margin-right: 0px !important;
  }
  .md-mb-0 {
    margin-bottom: 0px !important;
  }
  .md-mx-0 {
    margin-left: 0px !important;
    margin-right: 0px !important;
  }
  .md-my-0 {
    margin-top: 0px !important;
    margin-bottom: 0px !important;
  }
  .md-p-1 {
    padding: 8px !important;
  }
  .md-pt-1 {
    padding-top: 8px !important;
  }
  .md-pl-1 {
    padding-left: 8px !important;
  }
  .md-pr-1 {
    padding-right: 8px !important;
  }
  .md-pb-1 {
    padding-bottom: 8px !important;
  }
  .md-px-1 {
    padding-left: 8px !important;
    padding-right: 8px !important;
  }
  .md-py-1 {
    padding-top: 8px !important;
    padding-bottom: 8px !important;
  }
  .md-m-1 {
    margin: 8px !important;
  }
  .md-mt-1 {
    margin-top: 8px !important;
  }
  .md-ml-1 {
    margin-left: 8px !important;
  }
  .md-mr-1 {
    margin-right: 8px !important;
  }
  .md-mb-1 {
    margin-bottom: 8px !important;
  }
  .md-mx-1 {
    margin-left: 8px !important;
    margin-right: 8px !important;
  }
  .md-my-1 {
    margin-top: 8px !important;
    margin-bottom: 8px !important;
  }
  .md-p-2 {
    padding: 16px !important;
  }
  .md-pt-2 {
    padding-top: 16px !important;
  }
  .md-pl-2 {
    padding-left: 16px !important;
  }
  .md-pr-2 {
    padding-right: 16px !important;
  }
  .md-pb-2 {
    padding-bottom: 16px !important;
  }
  .md-px-2 {
    padding-left: 16px !important;
    padding-right: 16px !important;
  }
  .md-py-2 {
    padding-top: 16px !important;
    padding-bottom: 16px !important;
  }
  .md-m-2 {
    margin: 16px !important;
  }
  .md-mt-2 {
    margin-top: 16px !important;
  }
  .md-ml-2 {
    margin-left: 16px !important;
  }
  .md-mr-2 {
    margin-right: 16px !important;
  }
  .md-mb-2 {
    margin-bottom: 16px !important;
  }
  .md-mx-2 {
    margin-left: 16px !important;
    margin-right: 16px !important;
  }
  .md-my-2 {
    margin-top: 16px !important;
    margin-bottom: 16px !important;
  }
  .md-p-3 {
    padding: 24px !important;
  }
  .md-pt-3 {
    padding-top: 24px !important;
  }
  .md-pl-3 {
    padding-left: 24px !important;
  }
  .md-pr-3 {
    padding-right: 24px !important;
  }
  .md-pb-3 {
    padding-bottom: 24px !important;
  }
  .md-px-3 {
    padding-left: 24px !important;
    padding-right: 24px !important;
  }
  .md-py-3 {
    padding-top: 24px !important;
    padding-bottom: 24px !important;
  }
  .md-m-3 {
    margin: 24px !important;
  }
  .md-mt-3 {
    margin-top: 24px !important;
  }
  .md-ml-3 {
    margin-left: 24px !important;
  }
  .md-mr-3 {
    margin-right: 24px !important;
  }
  .md-mb-3 {
    margin-bottom: 24px !important;
  }
  .md-mx-3 {
    margin-left: 24px !important;
    margin-right: 24px !important;
  }
  .md-my-3 {
    margin-top: 24px !important;
    margin-bottom: 24px !important;
  }
  .md-p-4 {
    padding: 32px !important;
  }
  .md-pt-4 {
    padding-top: 32px !important;
  }
  .md-pl-4 {
    padding-left: 32px !important;
  }
  .md-pr-4 {
    padding-right: 32px !important;
  }
  .md-pb-4 {
    padding-bottom: 32px !important;
  }
  .md-px-4 {
    padding-left: 32px !important;
    padding-right: 32px !important;
  }
  .md-py-4 {
    padding-top: 32px !important;
    padding-bottom: 32px !important;
  }
  .md-m-4 {
    margin: 32px !important;
  }
  .md-mt-4 {
    margin-top: 32px !important;
  }
  .md-ml-4 {
    margin-left: 32px !important;
  }
  .md-mr-4 {
    margin-right: 32px !important;
  }
  .md-mb-4 {
    margin-bottom: 32px !important;
  }
  .md-mx-4 {
    margin-left: 32px !important;
    margin-right: 32px !important;
  }
  .md-my-4 {
    margin-top: 32px !important;
    margin-bottom: 32px !important;
  }
  .md-p-5 {
    padding: 40px !important;
  }
  .md-pt-5 {
    padding-top: 40px !important;
  }
  .md-pl-5 {
    padding-left: 40px !important;
  }
  .md-pr-5 {
    padding-right: 40px !important;
  }
  .md-pb-5 {
    padding-bottom: 40px !important;
  }
  .md-px-5 {
    padding-left: 40px !important;
    padding-right: 40px !important;
  }
  .md-py-5 {
    padding-top: 40px !important;
    padding-bottom: 40px !important;
  }
  .md-m-5 {
    margin: 40px !important;
  }
  .md-mt-5 {
    margin-top: 40px !important;
  }
  .md-ml-5 {
    margin-left: 40px !important;
  }
  .md-mr-5 {
    margin-right: 40px !important;
  }
  .md-mb-5 {
    margin-bottom: 40px !important;
  }
  .md-mx-5 {
    margin-left: 40px !important;
    margin-right: 40px !important;
  }
  .md-my-5 {
    margin-top: 40px !important;
    margin-bottom: 40px !important;
  }
  .md-p-6 {
    padding: 48px !important;
  }
  .md-pt-6 {
    padding-top: 48px !important;
  }
  .md-pl-6 {
    padding-left: 48px !important;
  }
  .md-pr-6 {
    padding-right: 48px !important;
  }
  .md-pb-6 {
    padding-bottom: 48px !important;
  }
  .md-px-6 {
    padding-left: 48px !important;
    padding-right: 48px !important;
  }
  .md-py-6 {
    padding-top: 48px !important;
    padding-bottom: 48px !important;
  }
  .md-m-6 {
    margin: 48px !important;
  }
  .md-mt-6 {
    margin-top: 48px !important;
  }
  .md-ml-6 {
    margin-left: 48px !important;
  }
  .md-mr-6 {
    margin-right: 48px !important;
  }
  .md-mb-6 {
    margin-bottom: 48px !important;
  }
  .md-mx-6 {
    margin-left: 48px !important;
    margin-right: 48px !important;
  }
  .md-my-6 {
    margin-top: 48px !important;
    margin-bottom: 48px !important;
  }
  .md-p-7 {
    padding: 56px !important;
  }
  .md-pt-7 {
    padding-top: 56px !important;
  }
  .md-pl-7 {
    padding-left: 56px !important;
  }
  .md-pr-7 {
    padding-right: 56px !important;
  }
  .md-pb-7 {
    padding-bottom: 56px !important;
  }
  .md-px-7 {
    padding-left: 56px !important;
    padding-right: 56px !important;
  }
  .md-py-7 {
    padding-top: 56px !important;
    padding-bottom: 56px !important;
  }
  .md-m-7 {
    margin: 56px !important;
  }
  .md-mt-7 {
    margin-top: 56px !important;
  }
  .md-ml-7 {
    margin-left: 56px !important;
  }
  .md-mr-7 {
    margin-right: 56px !important;
  }
  .md-mb-7 {
    margin-bottom: 56px !important;
  }
  .md-mx-7 {
    margin-left: 56px !important;
    margin-right: 56px !important;
  }
  .md-my-7 {
    margin-top: 56px !important;
    margin-bottom: 56px !important;
  }
  .md-p-8 {
    padding: 64px !important;
  }
  .md-pt-8 {
    padding-top: 64px !important;
  }
  .md-pl-8 {
    padding-left: 64px !important;
  }
  .md-pr-8 {
    padding-right: 64px !important;
  }
  .md-pb-8 {
    padding-bottom: 64px !important;
  }
  .md-px-8 {
    padding-left: 64px !important;
    padding-right: 64px !important;
  }
  .md-py-8 {
    padding-top: 64px !important;
    padding-bottom: 64px !important;
  }
  .md-m-8 {
    margin: 64px !important;
  }
  .md-mt-8 {
    margin-top: 64px !important;
  }
  .md-ml-8 {
    margin-left: 64px !important;
  }
  .md-mr-8 {
    margin-right: 64px !important;
  }
  .md-mb-8 {
    margin-bottom: 64px !important;
  }
  .md-mx-8 {
    margin-left: 64px !important;
    margin-right: 64px !important;
  }
  .md-my-8 {
    margin-top: 64px !important;
    margin-bottom: 64px !important;
  }
}
@media (min-width: 1280px) {
  .lg-p-0 {
    padding: 0px !important;
  }
  .lg-pt-0 {
    padding-top: 0px !important;
  }
  .lg-pl-0 {
    padding-left: 0px !important;
  }
  .lg-pr-0 {
    padding-right: 0px !important;
  }
  .lg-pb-0 {
    padding-bottom: 0px !important;
  }
  .lg-px-0 {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }
  .lg-py-0 {
    padding-top: 0px !important;
    padding-bottom: 0px !important;
  }
  .lg-m-0 {
    margin: 0px !important;
  }
  .lg-mt-0 {
    margin-top: 0px !important;
  }
  .lg-ml-0 {
    margin-left: 0px !important;
  }
  .lg-mr-0 {
    margin-right: 0px !important;
  }
  .lg-mb-0 {
    margin-bottom: 0px !important;
  }
  .lg-mx-0 {
    margin-left: 0px !important;
    margin-right: 0px !important;
  }
  .lg-my-0 {
    margin-top: 0px !important;
    margin-bottom: 0px !important;
  }
  .lg-p-1 {
    padding: 8px !important;
  }
  .lg-pt-1 {
    padding-top: 8px !important;
  }
  .lg-pl-1 {
    padding-left: 8px !important;
  }
  .lg-pr-1 {
    padding-right: 8px !important;
  }
  .lg-pb-1 {
    padding-bottom: 8px !important;
  }
  .lg-px-1 {
    padding-left: 8px !important;
    padding-right: 8px !important;
  }
  .lg-py-1 {
    padding-top: 8px !important;
    padding-bottom: 8px !important;
  }
  .lg-m-1 {
    margin: 8px !important;
  }
  .lg-mt-1 {
    margin-top: 8px !important;
  }
  .lg-ml-1 {
    margin-left: 8px !important;
  }
  .lg-mr-1 {
    margin-right: 8px !important;
  }
  .lg-mb-1 {
    margin-bottom: 8px !important;
  }
  .lg-mx-1 {
    margin-left: 8px !important;
    margin-right: 8px !important;
  }
  .lg-my-1 {
    margin-top: 8px !important;
    margin-bottom: 8px !important;
  }
  .lg-p-2 {
    padding: 16px !important;
  }
  .lg-pt-2 {
    padding-top: 16px !important;
  }
  .lg-pl-2 {
    padding-left: 16px !important;
  }
  .lg-pr-2 {
    padding-right: 16px !important;
  }
  .lg-pb-2 {
    padding-bottom: 16px !important;
  }
  .lg-px-2 {
    padding-left: 16px !important;
    padding-right: 16px !important;
  }
  .lg-py-2 {
    padding-top: 16px !important;
    padding-bottom: 16px !important;
  }
  .lg-m-2 {
    margin: 16px !important;
  }
  .lg-mt-2 {
    margin-top: 16px !important;
  }
  .lg-ml-2 {
    margin-left: 16px !important;
  }
  .lg-mr-2 {
    margin-right: 16px !important;
  }
  .lg-mb-2 {
    margin-bottom: 16px !important;
  }
  .lg-mx-2 {
    margin-left: 16px !important;
    margin-right: 16px !important;
  }
  .lg-my-2 {
    margin-top: 16px !important;
    margin-bottom: 16px !important;
  }
  .lg-p-3 {
    padding: 24px !important;
  }
  .lg-pt-3 {
    padding-top: 24px !important;
  }
  .lg-pl-3 {
    padding-left: 24px !important;
  }
  .lg-pr-3 {
    padding-right: 24px !important;
  }
  .lg-pb-3 {
    padding-bottom: 24px !important;
  }
  .lg-px-3 {
    padding-left: 24px !important;
    padding-right: 24px !important;
  }
  .lg-py-3 {
    padding-top: 24px !important;
    padding-bottom: 24px !important;
  }
  .lg-m-3 {
    margin: 24px !important;
  }
  .lg-mt-3 {
    margin-top: 24px !important;
  }
  .lg-ml-3 {
    margin-left: 24px !important;
  }
  .lg-mr-3 {
    margin-right: 24px !important;
  }
  .lg-mb-3 {
    margin-bottom: 24px !important;
  }
  .lg-mx-3 {
    margin-left: 24px !important;
    margin-right: 24px !important;
  }
  .lg-my-3 {
    margin-top: 24px !important;
    margin-bottom: 24px !important;
  }
  .lg-p-4 {
    padding: 32px !important;
  }
  .lg-pt-4 {
    padding-top: 32px !important;
  }
  .lg-pl-4 {
    padding-left: 32px !important;
  }
  .lg-pr-4 {
    padding-right: 32px !important;
  }
  .lg-pb-4 {
    padding-bottom: 32px !important;
  }
  .lg-px-4 {
    padding-left: 32px !important;
    padding-right: 32px !important;
  }
  .lg-py-4 {
    padding-top: 32px !important;
    padding-bottom: 32px !important;
  }
  .lg-m-4 {
    margin: 32px !important;
  }
  .lg-mt-4 {
    margin-top: 32px !important;
  }
  .lg-ml-4 {
    margin-left: 32px !important;
  }
  .lg-mr-4 {
    margin-right: 32px !important;
  }
  .lg-mb-4 {
    margin-bottom: 32px !important;
  }
  .lg-mx-4 {
    margin-left: 32px !important;
    margin-right: 32px !important;
  }
  .lg-my-4 {
    margin-top: 32px !important;
    margin-bottom: 32px !important;
  }
  .lg-p-5 {
    padding: 40px !important;
  }
  .lg-pt-5 {
    padding-top: 40px !important;
  }
  .lg-pl-5 {
    padding-left: 40px !important;
  }
  .lg-pr-5 {
    padding-right: 40px !important;
  }
  .lg-pb-5 {
    padding-bottom: 40px !important;
  }
  .lg-px-5 {
    padding-left: 40px !important;
    padding-right: 40px !important;
  }
  .lg-py-5 {
    padding-top: 40px !important;
    padding-bottom: 40px !important;
  }
  .lg-m-5 {
    margin: 40px !important;
  }
  .lg-mt-5 {
    margin-top: 40px !important;
  }
  .lg-ml-5 {
    margin-left: 40px !important;
  }
  .lg-mr-5 {
    margin-right: 40px !important;
  }
  .lg-mb-5 {
    margin-bottom: 40px !important;
  }
  .lg-mx-5 {
    margin-left: 40px !important;
    margin-right: 40px !important;
  }
  .lg-my-5 {
    margin-top: 40px !important;
    margin-bottom: 40px !important;
  }
  .lg-p-6 {
    padding: 48px !important;
  }
  .lg-pt-6 {
    padding-top: 48px !important;
  }
  .lg-pl-6 {
    padding-left: 48px !important;
  }
  .lg-pr-6 {
    padding-right: 48px !important;
  }
  .lg-pb-6 {
    padding-bottom: 48px !important;
  }
  .lg-px-6 {
    padding-left: 48px !important;
    padding-right: 48px !important;
  }
  .lg-py-6 {
    padding-top: 48px !important;
    padding-bottom: 48px !important;
  }
  .lg-m-6 {
    margin: 48px !important;
  }
  .lg-mt-6 {
    margin-top: 48px !important;
  }
  .lg-ml-6 {
    margin-left: 48px !important;
  }
  .lg-mr-6 {
    margin-right: 48px !important;
  }
  .lg-mb-6 {
    margin-bottom: 48px !important;
  }
  .lg-mx-6 {
    margin-left: 48px !important;
    margin-right: 48px !important;
  }
  .lg-my-6 {
    margin-top: 48px !important;
    margin-bottom: 48px !important;
  }
  .lg-p-7 {
    padding: 56px !important;
  }
  .lg-pt-7 {
    padding-top: 56px !important;
  }
  .lg-pl-7 {
    padding-left: 56px !important;
  }
  .lg-pr-7 {
    padding-right: 56px !important;
  }
  .lg-pb-7 {
    padding-bottom: 56px !important;
  }
  .lg-px-7 {
    padding-left: 56px !important;
    padding-right: 56px !important;
  }
  .lg-py-7 {
    padding-top: 56px !important;
    padding-bottom: 56px !important;
  }
  .lg-m-7 {
    margin: 56px !important;
  }
  .lg-mt-7 {
    margin-top: 56px !important;
  }
  .lg-ml-7 {
    margin-left: 56px !important;
  }
  .lg-mr-7 {
    margin-right: 56px !important;
  }
  .lg-mb-7 {
    margin-bottom: 56px !important;
  }
  .lg-mx-7 {
    margin-left: 56px !important;
    margin-right: 56px !important;
  }
  .lg-my-7 {
    margin-top: 56px !important;
    margin-bottom: 56px !important;
  }
  .lg-p-8 {
    padding: 64px !important;
  }
  .lg-pt-8 {
    padding-top: 64px !important;
  }
  .lg-pl-8 {
    padding-left: 64px !important;
  }
  .lg-pr-8 {
    padding-right: 64px !important;
  }
  .lg-pb-8 {
    padding-bottom: 64px !important;
  }
  .lg-px-8 {
    padding-left: 64px !important;
    padding-right: 64px !important;
  }
  .lg-py-8 {
    padding-top: 64px !important;
    padding-bottom: 64px !important;
  }
  .lg-m-8 {
    margin: 64px !important;
  }
  .lg-mt-8 {
    margin-top: 64px !important;
  }
  .lg-ml-8 {
    margin-left: 64px !important;
  }
  .lg-mr-8 {
    margin-right: 64px !important;
  }
  .lg-mb-8 {
    margin-bottom: 64px !important;
  }
  .lg-mx-8 {
    margin-left: 64px !important;
    margin-right: 64px !important;
  }
  .lg-my-8 {
    margin-top: 64px !important;
    margin-bottom: 64px !important;
  }
}
@media (min-width: 1920px) {
  .xl-p-0 {
    padding: 0px !important;
  }
  .xl-pt-0 {
    padding-top: 0px !important;
  }
  .xl-pl-0 {
    padding-left: 0px !important;
  }
  .xl-pr-0 {
    padding-right: 0px !important;
  }
  .xl-pb-0 {
    padding-bottom: 0px !important;
  }
  .xl-px-0 {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }
  .xl-py-0 {
    padding-top: 0px !important;
    padding-bottom: 0px !important;
  }
  .xl-m-0 {
    margin: 0px !important;
  }
  .xl-mt-0 {
    margin-top: 0px !important;
  }
  .xl-ml-0 {
    margin-left: 0px !important;
  }
  .xl-mr-0 {
    margin-right: 0px !important;
  }
  .xl-mb-0 {
    margin-bottom: 0px !important;
  }
  .xl-mx-0 {
    margin-left: 0px !important;
    margin-right: 0px !important;
  }
  .xl-my-0 {
    margin-top: 0px !important;
    margin-bottom: 0px !important;
  }
  .xl-p-1 {
    padding: 8px !important;
  }
  .xl-pt-1 {
    padding-top: 8px !important;
  }
  .xl-pl-1 {
    padding-left: 8px !important;
  }
  .xl-pr-1 {
    padding-right: 8px !important;
  }
  .xl-pb-1 {
    padding-bottom: 8px !important;
  }
  .xl-px-1 {
    padding-left: 8px !important;
    padding-right: 8px !important;
  }
  .xl-py-1 {
    padding-top: 8px !important;
    padding-bottom: 8px !important;
  }
  .xl-m-1 {
    margin: 8px !important;
  }
  .xl-mt-1 {
    margin-top: 8px !important;
  }
  .xl-ml-1 {
    margin-left: 8px !important;
  }
  .xl-mr-1 {
    margin-right: 8px !important;
  }
  .xl-mb-1 {
    margin-bottom: 8px !important;
  }
  .xl-mx-1 {
    margin-left: 8px !important;
    margin-right: 8px !important;
  }
  .xl-my-1 {
    margin-top: 8px !important;
    margin-bottom: 8px !important;
  }
  .xl-p-2 {
    padding: 16px !important;
  }
  .xl-pt-2 {
    padding-top: 16px !important;
  }
  .xl-pl-2 {
    padding-left: 16px !important;
  }
  .xl-pr-2 {
    padding-right: 16px !important;
  }
  .xl-pb-2 {
    padding-bottom: 16px !important;
  }
  .xl-px-2 {
    padding-left: 16px !important;
    padding-right: 16px !important;
  }
  .xl-py-2 {
    padding-top: 16px !important;
    padding-bottom: 16px !important;
  }
  .xl-m-2 {
    margin: 16px !important;
  }
  .xl-mt-2 {
    margin-top: 16px !important;
  }
  .xl-ml-2 {
    margin-left: 16px !important;
  }
  .xl-mr-2 {
    margin-right: 16px !important;
  }
  .xl-mb-2 {
    margin-bottom: 16px !important;
  }
  .xl-mx-2 {
    margin-left: 16px !important;
    margin-right: 16px !important;
  }
  .xl-my-2 {
    margin-top: 16px !important;
    margin-bottom: 16px !important;
  }
  .xl-p-3 {
    padding: 24px !important;
  }
  .xl-pt-3 {
    padding-top: 24px !important;
  }
  .xl-pl-3 {
    padding-left: 24px !important;
  }
  .xl-pr-3 {
    padding-right: 24px !important;
  }
  .xl-pb-3 {
    padding-bottom: 24px !important;
  }
  .xl-px-3 {
    padding-left: 24px !important;
    padding-right: 24px !important;
  }
  .xl-py-3 {
    padding-top: 24px !important;
    padding-bottom: 24px !important;
  }
  .xl-m-3 {
    margin: 24px !important;
  }
  .xl-mt-3 {
    margin-top: 24px !important;
  }
  .xl-ml-3 {
    margin-left: 24px !important;
  }
  .xl-mr-3 {
    margin-right: 24px !important;
  }
  .xl-mb-3 {
    margin-bottom: 24px !important;
  }
  .xl-mx-3 {
    margin-left: 24px !important;
    margin-right: 24px !important;
  }
  .xl-my-3 {
    margin-top: 24px !important;
    margin-bottom: 24px !important;
  }
  .xl-p-4 {
    padding: 32px !important;
  }
  .xl-pt-4 {
    padding-top: 32px !important;
  }
  .xl-pl-4 {
    padding-left: 32px !important;
  }
  .xl-pr-4 {
    padding-right: 32px !important;
  }
  .xl-pb-4 {
    padding-bottom: 32px !important;
  }
  .xl-px-4 {
    padding-left: 32px !important;
    padding-right: 32px !important;
  }
  .xl-py-4 {
    padding-top: 32px !important;
    padding-bottom: 32px !important;
  }
  .xl-m-4 {
    margin: 32px !important;
  }
  .xl-mt-4 {
    margin-top: 32px !important;
  }
  .xl-ml-4 {
    margin-left: 32px !important;
  }
  .xl-mr-4 {
    margin-right: 32px !important;
  }
  .xl-mb-4 {
    margin-bottom: 32px !important;
  }
  .xl-mx-4 {
    margin-left: 32px !important;
    margin-right: 32px !important;
  }
  .xl-my-4 {
    margin-top: 32px !important;
    margin-bottom: 32px !important;
  }
  .xl-p-5 {
    padding: 40px !important;
  }
  .xl-pt-5 {
    padding-top: 40px !important;
  }
  .xl-pl-5 {
    padding-left: 40px !important;
  }
  .xl-pr-5 {
    padding-right: 40px !important;
  }
  .xl-pb-5 {
    padding-bottom: 40px !important;
  }
  .xl-px-5 {
    padding-left: 40px !important;
    padding-right: 40px !important;
  }
  .xl-py-5 {
    padding-top: 40px !important;
    padding-bottom: 40px !important;
  }
  .xl-m-5 {
    margin: 40px !important;
  }
  .xl-mt-5 {
    margin-top: 40px !important;
  }
  .xl-ml-5 {
    margin-left: 40px !important;
  }
  .xl-mr-5 {
    margin-right: 40px !important;
  }
  .xl-mb-5 {
    margin-bottom: 40px !important;
  }
  .xl-mx-5 {
    margin-left: 40px !important;
    margin-right: 40px !important;
  }
  .xl-my-5 {
    margin-top: 40px !important;
    margin-bottom: 40px !important;
  }
  .xl-p-6 {
    padding: 48px !important;
  }
  .xl-pt-6 {
    padding-top: 48px !important;
  }
  .xl-pl-6 {
    padding-left: 48px !important;
  }
  .xl-pr-6 {
    padding-right: 48px !important;
  }
  .xl-pb-6 {
    padding-bottom: 48px !important;
  }
  .xl-px-6 {
    padding-left: 48px !important;
    padding-right: 48px !important;
  }
  .xl-py-6 {
    padding-top: 48px !important;
    padding-bottom: 48px !important;
  }
  .xl-m-6 {
    margin: 48px !important;
  }
  .xl-mt-6 {
    margin-top: 48px !important;
  }
  .xl-ml-6 {
    margin-left: 48px !important;
  }
  .xl-mr-6 {
    margin-right: 48px !important;
  }
  .xl-mb-6 {
    margin-bottom: 48px !important;
  }
  .xl-mx-6 {
    margin-left: 48px !important;
    margin-right: 48px !important;
  }
  .xl-my-6 {
    margin-top: 48px !important;
    margin-bottom: 48px !important;
  }
  .xl-p-7 {
    padding: 56px !important;
  }
  .xl-pt-7 {
    padding-top: 56px !important;
  }
  .xl-pl-7 {
    padding-left: 56px !important;
  }
  .xl-pr-7 {
    padding-right: 56px !important;
  }
  .xl-pb-7 {
    padding-bottom: 56px !important;
  }
  .xl-px-7 {
    padding-left: 56px !important;
    padding-right: 56px !important;
  }
  .xl-py-7 {
    padding-top: 56px !important;
    padding-bottom: 56px !important;
  }
  .xl-m-7 {
    margin: 56px !important;
  }
  .xl-mt-7 {
    margin-top: 56px !important;
  }
  .xl-ml-7 {
    margin-left: 56px !important;
  }
  .xl-mr-7 {
    margin-right: 56px !important;
  }
  .xl-mb-7 {
    margin-bottom: 56px !important;
  }
  .xl-mx-7 {
    margin-left: 56px !important;
    margin-right: 56px !important;
  }
  .xl-my-7 {
    margin-top: 56px !important;
    margin-bottom: 56px !important;
  }
  .xl-p-8 {
    padding: 64px !important;
  }
  .xl-pt-8 {
    padding-top: 64px !important;
  }
  .xl-pl-8 {
    padding-left: 64px !important;
  }
  .xl-pr-8 {
    padding-right: 64px !important;
  }
  .xl-pb-8 {
    padding-bottom: 64px !important;
  }
  .xl-px-8 {
    padding-left: 64px !important;
    padding-right: 64px !important;
  }
  .xl-py-8 {
    padding-top: 64px !important;
    padding-bottom: 64px !important;
  }
  .xl-m-8 {
    margin: 64px !important;
  }
  .xl-mt-8 {
    margin-top: 64px !important;
  }
  .xl-ml-8 {
    margin-left: 64px !important;
  }
  .xl-mr-8 {
    margin-right: 64px !important;
  }
  .xl-mb-8 {
    margin-bottom: 64px !important;
  }
  .xl-mx-8 {
    margin-left: 64px !important;
    margin-right: 64px !important;
  }
  .xl-my-8 {
    margin-top: 64px !important;
    margin-bottom: 64px !important;
  }
}
@media screen and (width >= 1470px) {
  .wide-content {
    min-width: 1200px;
  }
}