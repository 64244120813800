@use '../../abstracts/fonts' as fonts;
@use '../../abstracts/text-mixins' as text;

mat-card-title {
    @include text.text(20px, 24px);
}

mat-card-subtitle {
    @include text.text(16px, 24px, fonts.$font-regular);
}

.mat-mdc-card {
    margin: 8px;
    padding: 16px;
    border-radius: 6px !important;
    font-family: fonts.$font-regular;
}

.mat-mdc-card-header {
    padding: 0 !important;
}

.mat-mdc-card-avatar {
    border-radius: 0 !important;
}

.mat-mdc-card-content {
    margin-bottom: 0 !important;
    padding: 0 !important;
    font-size: 14px;
}

.mat-mdc-card-image {
    margin-bottom: 0 !important;
}