@use '../../abstracts/fonts' as fonts;
@use '../../abstracts/colors' as colors;
@use '../../abstracts/text-mixins' as text;

/* mat-form-field / matInput - readonly support */
.mat-mdc-form-field .mat-mdc-form-field-wrapper .mat-mdc-form-field-flex .mat-mdc-form-field-infix input[readonly="true"] {
    opacity: 0.5;
}

.hide-errors .md-errors-spacer {
    display: none;
}

.mat-mdc-form-field[hidden] {
    display: none;
}

.mat-mdc-form-field-infix {
    padding-bottom: 3px !important;
    min-height: 40px !important;
}

.mat-mdc-form-field-wrapper {
    padding-bottom: 0;
}

.mat-mdc-form-field-focus-overlay {
    background-color: transparent !important;
}

.mat-mdc-form-field-error-wrapper,
.mat-mdc-form-field-hint-wrapper {
    padding: 0 !important;
}

.mdc-text-field {
    padding: 0 !important;
}

.mat-mdc-error {
    @include text.text(12px, 16px, fonts.$font-regular);

    .error1 {
        font-size: 12px;
        margin-top: -15px;
    }
}

.mat-mdc-form-field-appearance-legacy .mat-mdc-form-field-underline {
    background-color: colors.$grey_4;
}

.mdc-text-field--filled:not(.mdc-text-field--disabled) .mdc-line-ripple::before {
    border-bottom-color: colors.$grey_4;
}

.mdc-text-field--filled:not(.mdc-text-field--disabled) {
    background-color: transparent !important;
}

.mat-mdc-floating-label {
    font-size: 16px;
}

.mat-mdc-slide-toggle {
    label {
        margin-left: 5px !important;
    }
}