@use '../abstracts/fonts' as fonts;
@use '../abstracts/colors' as colors;
@use '../abstracts/text-mixins' as text;

th,
thead {
    @include text.text(12px, 16px, fonts.$font-regular);
}

#operationTable {
    td {
        padding-top: 8px;
        padding-bottom: 8px;
    }

    tr.no-print td {
        padding-top: 0;
        padding-bottom: 0;
    }

}

table {
    @include text.text(14px, 20px, fonts.$font-sf-mono-regular);

    border-collapse: collapse;

    thead {
        color: colors.$grey_9;
    }

    tbody td {
        padding-left: 0;
        padding-right: 0;
    }

    tbody tr:nth-child(odd) {
        background: rgb(62 164 244 / 5%);
    }

    th {
        &.qp-width-25 {
            width: 25%;
        }

        &.qp-width-20 {
            width: 20%;
        }

        &.qp-width-15 {
            width: 15%;
        }

        &.qp-width-5 {
            width: 5%;
        }
    }

    tr {
        &.border-bottom {
            td {
                border-bottom: 1px solid colors.$grey_8;
                padding-top: 8px;
                padding-bottom: 8px;
            }
        }

        &:last-child {
            td {
                border-bottom: none;
            }
        }

        &.md-qp {
            tr {
                &:hover {
                    background-color: fade(colors.$grey_7, 20%);
                }
            }
        }
    }

    .mat-cell,
    .mat-footer-cell {
        font-size: 14px;
    }

    &.card-table {
        margin: 0 -16px;

        thead tr,
        tbody tr:not(:last-child) {
            border-bottom: 1px solid colors.$grey_8;
        }

        th,
        td {
            padding: 0 16px;
        }

        thead {
            color: rgb(0 0 0 / 54.1%);
            height: 56px;
        }

        th {
            font-weight: 200;
            text-align: left;
        }
    }

    &.info-table {
        border-spacing: 0;
        width: calc(100% + 32px);
        margin: 8px -16px 16px;

        tr {
            td {
                border-bottom: 1px solid colors.$grey_8;
                padding: 8px;
                padding-left: 16px;
                padding-right: 32px;
            }

            td:first-child {
                font-family: fonts.$font-semibold;
            }

            td:last-child {
                text-align: right;
                font-weight: 600;
                color: colors.$blue_4;
            }
        }
    }

    &.table1,
    &.table2 {
        font-family: fonts.$font-regular;
        font-size: 16px;
        border-spacing: 6px;
        margin-left: -6px;

        tr {
            td:nth-child(2) {
                width: 15%;
                text-align: right;
            }
        }
    }

    &.table1 {
        tr {
            td:nth-child(1) {
                width: 52%;
            }

            td:nth-child(3) {
                padding-left: 40px;
            }
        }
    }

    &.table2 {
        tr {
            td:nth-child(3) {
                padding-left: 25px;
            }
        }
    }
}