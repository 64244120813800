@use '../abstracts/fonts' as fonts;
@use '../abstracts/colors' as colors;
@use '../abstracts/text-mixins' as text;

.input-left-label {
    position: absolute;
    top: 35%;
}

.caption {
    @include text.text(12px, 16px, fonts.$font-regular);
}

.qp-label {
    @include text.text(12px, 22px, fonts.$font-regular);

    background-color: #F1F1F3;
    text-align: center;
    padding: 0 6px;
    margin: 0 8px;
    display: inline-block;
    line-height: 22px !important;
    height: 22px;
    width: 60px;

    &.success {
        background-color: #F2FBF7;
        color: colors.$green_1;
    }

    &.warning {
        background-color: #FBF1E6;
        color: colors.$yellow_1;
    }
}