@use '../abstracts/fonts' as fonts;
@use '../abstracts/text-mixins' as text;

.overline {
    @include text.text(11px, 16px, fonts.$font-regular);
}

.code {
    @include text.text(14px, 20px, fonts.$font-sf-mono-regular);
}

.white-space-normal {
    white-space: normal;
}

.white-space-pre {
    white-space: pre;
}

.pre-wrap {
    white-space: pre-wrap;
}

.breakword {
    word-break: break-all;
}

.uppercase {
    text-transform: uppercase;
}
