$max_widths_px: 8, 16, 75, 400, 600, 500, 800;
$max_widths_pct: 20, 25;
$widths_px: 780, 700, 400, 600, 250, 150, 60, 40;
$widths_pct: 60, 100;

//---- Custom classes for layout ----//
.height-100px {
    height: 100px;
}

.max-height-5p {
    max-height: 5%;
}

.max-size {
    max-width: 100%;
    max-height: 100%;
}

// widths
@each $value in $widths_px {
    .width-#{$value}px {
        width: #{$value}#{'px'};
    }
}

@each $value in $widths_pct {
    .width-#{$value}p {
        width: #{$value}#{'%'};
    }
}

// max widths
@each $value in $max_widths_px {
    .max-width-#{$value}px {
        max-width: #{$value}#{'px'};
    }
}

@each $value in $max_widths_pct {
    .max-width-#{$value}p {
        max-width: #{$value}#{'pct'};
    }
}

// min widths
.min-width-400px {
    min-width: 400px;
}

.min-width-650px {
    min-width: 650px;
}

.hide {
    display: none;
}

@for $i from 1 through 40 {
    .line-height-#{$i} {
        line-height: #{$i}#{"px"};
    }
}

.margin-negative {
    margin: 0 -16px;
}

.margin-0 {
    margin: 0 !important;
}

.margin {
    @for $i from 0 through 40 {
        &.t#{$i} {
            margin-top: #{$i}#{"px"} !important;
        }
    }

    @for $i from 0 through 40 {
        &.b#{$i} {
            margin-bottom: #{$i}#{"px"};
        }
    }

    @for $i from 0 through 40 {
        &.l#{$i} {
            margin-left: #{$i}#{"px"};
        }
    }

    @for $i from 0 through 40 {
        &.r#{$i} {
            margin-right: #{$i}#{"px"};
        }
    }

    &.negative {
        @for $i from 1 through 40 {
            &.v#{$i} {
                margin-top: -#{$i}#{"px"};
                margin-bottom: -#{$i}#{"px"};
            }
        }

        @for $i from 1 through 40 {
            &.h#{$i} {
                margin-right: -#{$i}#{"px"};
                margin-left: -#{$i}#{"px"};
            }
        }
    }

    &.tm8 {
        margin-top: -8px;
    }

    &.t2p {
        margin-top: 2%;
    }

    &.rm20 {
        margin-right: -20px;
    }

    &.l2p {
        margin-left: 2%;
    }

    &.l4p {
        margin-left: 4%;
    }

    &.l5p {
        margin-left: 5%;
    }

    &.l10p {
        margin-left: 10%;
    }

    &.l15p {
        margin-left: 15%;
    }
}

.padding {
    @for $i from 0 through 40 {
        &.t#{$i} {
            padding-top: #{$i}#{"px"};
        }
    }

    @for $i from 0 through 40 {
        &.b#{$i} {
            padding-bottom: #{$i}#{"px"};
        }
    }

    @for $i from 0 through 40 {
        &.l#{$i} {
            padding-left: #{$i}#{"px"};
        }
    }

    @for $i from 0 through 40 {
        &.r#{$i} {
            padding-right: #{$i}#{"px"};
        }
    }

    &.tbp8 {
        padding: 8px 0;
    }

    &.tp25 {
        padding-top: 25px;
    }

    &.lp30 {
        padding-left: 30px;
    }

    &.bp70 {
        padding-bottom: 70px !important;
    }
}