.text-center {
    text-align: center;
}

.text-right {
    text-align: right;
}

.text-left {
    text-align: left;
}

.centered-text {
    vertical-align: middle;
    text-align: center;
}

.footnote {
    text-align: right;
    display: inline-block;
    width: 15px;
}

.align-right {
    text-align: right !important;
    justify-content: flex-end !important;
}

.expandable-element {
    overflow: hidden;
    display: flex;
}