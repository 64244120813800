$grey_1: #9295A3;
$grey_2: #6D7184;
$grey_3: #e0e0e0;
$grey_4: #DBDCE0;
$grey_5: #CCC;
$grey_6: #333;
$grey_7: #9e9e9e;
$grey_8: #DDD;
$grey_9: #8b8b8b;
$grey_10: #E6E7EA;
$grey_11: #0000008a;
$blue_1: #3EA4F4;
$blue_2: #298AE1;
$blue_3: #1B6AD7;
$blue_4: #6D7184;
$blue_5: #1338ff;
$red_1: #fc1154;
$red_2: #B80000;
$green_1: #00A862;
$yellow_1: #D97000;
$black_1: #000;
$black_2: #0C1332;
$white_1: #FFF;
$white_2: #eee;