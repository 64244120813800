/* stylelint-disable selector-class-pattern */
$dt-darkgrey: #424242;
$dt-darkergrey: #303030;
$dt-grey: #535353;
$dt-lightgrey: #CDCDCD;
$dt-lightergrey: #F1F1F1;
$dt-linkblue: #8CB4FF;
$white: #FFF;

.dark-theme {
    background-color: $dt-darkergrey; // Figure out why the theming value isn't being read and remove this override once fixed

    /* Buttons */
    button.mat-mdc-raised-button.mat-accent,
    .mat-mdc-raised-button,
    .mat-toolbar-row .mat-mdc-button {
        background: $dt-grey;
        border: none !important;
    }

    /* Cards */
    .notification {
        background-color: $dt-darkgrey !important;
    }

    .warning {
        background: $dt-darkergrey !important;
    }

    .error {
        background-color: $dt-darkergrey !important;
    }

    /* Labels */
    .qp-label,
    .qp-label.success {
        background-color: $dt-grey;
    }

    /* Slide toggles */
    .mat-mdc-slide-toggle.mat-mdc-slide-toggle-checked:not(.mat-disabled) .mdc-switch__shadow,
    .mat-mdc-slide-toggle.mat-mdc-slide-toggle-checked:not(.mat-disabled) .mdc-switch__track::after {
        background-color: $dt-lightergrey;
    }

    /* Tables */
    .mat-table .mat-row[qpsref]:hover:not([disabled]),
    .mat-table .mat-rowqpsref:hover:not([disabled]) {
        background-color: $dt-darkergrey !important;
    }

    .mdc-data-table__header-cell {
        color: $white;
    }

    table {
        tbody {
            tr {
                &:nth-child(odd) {
                    background: $dt-grey;
                }
            }
        }

        &.card-table {
            th {
                color: $white;
            }
        }
    }

    /* Menus */
    .nav-item-text {
        color: $dt-lightgrey;

        &.text-black {
            color: $white;
        }
    }

    /* Text */
    .info-text,
    .text-grey {
        color: $dt-lightgrey;
    }

    a {
        &.mat-mdc-list-item-title {
            color: $white;
        }

        &.mat-mdc-list-item-line.mdc-list-item__secondary-text {
            color: $white !important;
        }
    }

    .mat-expansion-panel-header-title {
        color: $white !important;
    }

    /* Icons */
    .mat-icon {
        &:not(.text-grey-icon, .text-blue, .text-light-blue, .text-darkred, .text-green, .text-yellow, .text-black, .text-red) {
            color: $white !important;
        }
    }

    /* Input */
    .search-widget-mat input,
    .search-widget input,
    .search-option {
        color: $white;
    }

    .search-option,
    input::placeholder {
        color: $white !important;
    }

    .mat-mdc-select-arrow {
        color: $white !important;
    }

    /* Statistics */
    ngx-charts-chart {
        text {
            fill: $white;
        }
    }

    /* Scrollbar */
    ::-webkit-scrollbar {
        width: 10px;
    }

    ::-webkit-scrollbar-thumb {
        border-radius: 2px;
        background-color: $dt-grey;
        box-shadow: inset 0 0 1px rgb(0 0 0 / 50%);
    }

    /* Mobile */
    @media screen and (width <= 1279px) {
        .side-nav {
            background: $dt-darkgrey !important;
        }
    }
}
