@use '../abstracts/colors' as colors;

.error-border {
    border-left: 3px solid colors.$red_2;
    position: relative;
    left: -16px;
    padding-left: 16px;
}

.warning-border {
    border-left: 3px solid colors.$yellow_1;
    position: relative;
    left: -16px;
    padding-left: 16px;
}

.success-border {
    border-left: 3px solid colors.$green_1;
    position: relative;
    left: -16px;
    padding-left: 16px;
}

.grey-underline {
    border-bottom: 1px solid colors.$grey_3;
}

.grey-border-bottom {
    border-bottom: 1px solid colors.$grey_3;
    margin-bottom: 1px !important;
    padding-bottom: 16px !important;
}

.bottom-border {
    border-bottom: 1px solid colors.$grey_3;
}

.tap-group-border {
    border-bottom: 1px solid colors.$grey_4;
}